export const logoNegative = ['608 134', `
 
  <svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400" height="66.76691729323309" 
  viewBox="0, 0, 400,66.76691729323309"><g id="svgg"><path id="path0" d="M177.750 2.667 C
   177.383 2.748,176.596 2.922,176.000 3.053 C
   175.404 3.185,
  174.692 3.413,174.417 3.562 C
   174.142 3.710,173.785 3.832,173.625 3.832 C
   173.465 3.833,173.333 3.893,173.333 3.965 C
   173.333 4.038,172.912 4.310,
  172.396 4.571 C
   171.323 5.115,168.500 7.835,168.500 8.325 C
   168.500 8.412,168.283 8.806,168.018 9.200 C
   167.753 9.594,167.455 10.179,167.356 
  10.500 C
   167.257 10.821,166.949 11.756,166.671 12.577 C
   166.394 13.399,166.167 14.342,166.167 14.672 C
   166.167 15.002,166.039 15.680,165.882 16.178 
  C
   165.481 17.454,165.474 23.131,165.872 25.167 C
   166.024 25.946,166.184 26.771,166.227 27.000 C
   166.345 27.621,166.784 28.769,167.427 30.131 C
  
   167.742 30.799,168.000 31.381,168.000 31.425 C
   168.000 31.804,170.600 35.062,171.857 36.257 C
   173.440 37.761,176.206 39.770,177.233 40.160 C
  
   177.472 40.251,177.667 40.396,177.667 40.483 C
   177.667 40.569,177.891 40.689,178.165 40.750 C
   178.439 40.810,178.703 40.923,178.751 41.002 C
  
   178.800 41.081,179.045 41.250,179.295 41.379 C
   180.096 41.789,182.187 42.911,182.473 43.084 C
   182.625 43.176,183.313 43.574,184.000 43.967 C
  
   184.688 44.361,185.450 44.811,185.694 44.967 C
   185.938 45.123,186.500 45.465,186.944 45.729 C
   189.443 47.212,190.730 49.624,190.743 52.849 C
  
   190.747 53.931,190.677 54.706,190.561 54.849 C
   190.456 54.978,190.319 55.402,190.254 55.791 C
   190.190 56.180,190.068 56.541,189.983 56.594 C
   
   189.898 56.646,189.729 56.928,189.607 57.220 C
   189.003 58.666,186.952 60.194,184.547 60.990 C
   181.674 61.941,176.139 60.988,174.203 59.208 C
  
   174.078 59.094,173.917 59.000,173.846 59.000 C
   173.617 59.000,171.361 56.683,171.250 56.335 C
   171.192 56.151,171.074 56.000,170.989 56.000 C
  
   170.903 56.000,170.833 55.897,170.833 55.770 C
   170.833 55.644,170.723 55.449,170.587 55.337 C
   170.337 55.129,169.500 53.482,169.500 53.197 C
   
   169.500 53.111,169.391 52.950,169.258 52.840 C
   169.125 52.730,168.967 52.308,168.906 51.903 C
   168.845 51.498,168.732 51.167,168.655 51.167 C
   
   168.578 51.167,168.469 50.885,168.414 50.542 C
   168.358 50.198,168.203 49.611,168.070 49.236 C
   167.936 48.862,167.758 48.075,167.674 47.486 C
   167.590 46.898,167.449 46.079,167.360 45.667 C
   167.271 45.254,167.145 44.188,167.080 43.297 C
   166.960 41.661,166.733 41.234,166.168 41.582 C
   166.058 41.651,166.000 45.250,166.000 52.021 L 166.000 62.355 166.454 62.303 C
   166.879 62.253,166.921 62.174,167.106 61.061 C
   167.542 58.450,168.644 57.919,170.874 59.248 C
   171.415 59.570,171.917 59.833,171.989 59.833 C
   172.062 59.833,172.301 59.966,172.519 60.129 C
   172.738 60.292,173.404 60.648,174.000 60.921 C
   174.596 61.193,175.121 61.454,175.167 61.500 C
   175.323 61.656,176.522 62.087,178.417 62.669 C
   179.249 62.925,182.019 63.036,183.083 62.857 C
   184.585 62.604,186.042 62.247,186.417 62.039 C
   186.600 61.937,187.125 61.682,187.583 61.472 C
   189.042 60.802,190.546 59.568,191.734 58.065 C
   192.161 57.525,192.578 56.998,192.662 56.893 C
   192.946 56.535,194.131 54.086,194.464 53.167 C
   196.050 48.794,195.947 43.141,194.203 38.750 C
   193.595 37.218,193.060 36.194,192.610 35.696 C
   192.458 35.528,192.333 35.330,192.333 35.255 C
   192.333 34.616,189.427 31.726,186.917 29.871 C
   185.801 29.046,181.376 26.755,179.000 25.772 C
   178.862 25.715,178.637 25.602,178.500 25.520 C
   178.362 25.439,177.537 25.024,176.667 24.599 C
   173.748 23.173,172.184 21.921,170.771 19.877 C
   170.438 19.396,170.164 18.946,170.161 18.877 C
   170.158 18.807,170.010 18.450,169.833 18.083 C
   169.656 17.717,169.509 17.215,169.506 16.969 C
   169.503 16.723,169.425 16.325,169.334 16.085 C
   168.789 14.651,169.310 10.677,170.285 8.840 C
   171.471 6.603,172.982 5.487,176.250 4.434 C
   178.600 3.676,183.620 4.453,184.970 5.783 C
   185.091 5.902,185.250 6.000,185.322 6.000 C
   186.327 6.000,189.780 11.138,190.251 13.333 C
   190.320 13.654,190.479 14.065,190.605 14.245 C
   190.730 14.426,190.833 14.824,190.833 15.129 C
   190.833 15.434,190.938 15.886,191.065 16.133 C
   191.192 16.381,191.376 17.108,191.474 17.750 C
   191.571 18.392,191.737 19.329,191.843 19.833 C
   191.948 20.337,192.066 20.919,192.104 21.125 C
   192.186 21.571,192.905 21.654,193.066 21.236 C
   193.121 21.091,193.167 17.116,193.167 12.403 L 193.167 3.833 192.686 3.833 C
   192.133 3.833,192.006 4.120,192.002 5.374 C
   191.997 7.277,190.134 7.675,188.497 6.123 C
   187.946 5.601,185.766 4.167,185.523 4.167 C
   185.419 4.167,185.333 4.109,185.333 4.039 C
   185.333 3.876,184.006 3.277,182.873 2.928 C
   181.847 2.613,178.712 2.453,177.750 2.667 M80.560 3.417 C
   80.261 4.196,80.793 4.500,82.456 4.500 C
   83.317 4.500,84.167 4.578,84.344 4.673 C
   84.836 4.936,84.854 59.779,84.362 60.224 C
   84.127 60.437,83.722 60.500,82.601 60.500 C
   80.970 60.500,80.500 60.664,80.500 61.235 C
   80.500 61.583,80.594 61.634,81.375 61.711 C
   83.075 61.880,103.613 61.707,104.194 61.519 C
   104.500 61.420,105.384 61.262,106.159 61.168 C
   106.935 61.075,107.643 60.923,107.734 60.832 C
   107.825 60.741,108.054 60.664,108.242 60.661 C
   108.430 60.659,108.958 60.472,109.417 60.246 C
   109.875 60.021,110.344 59.836,110.458 59.835 C
   110.573 59.834,110.667 59.763,110.667 59.678 C
   110.667 59.592,110.835 59.469,111.042 59.404 C
   111.782 59.170,113.254 57.855,114.287 56.503 C
   114.674 55.997,115.049 55.508,115.120 55.417 C
   115.262 55.235,116.124 53.453,116.330 52.917 C
   117.258 50.504,117.669 43.283,116.971 41.667 C
   116.832 41.346,116.663 40.783,116.595 40.417 C
   116.526 40.050,116.413 39.675,116.343 39.583 C
   116.272 39.492,116.130 39.163,116.025 38.854 C
   115.466 37.192,113.060 34.364,111.382 33.394 C
   110.943 33.140,110.546 32.892,110.500 32.841 C
   110.399 32.730,109.189 32.142,108.167 31.707 C
   107.083 31.245,106.915 30.987,107.345 30.440 C
   107.535 30.198,107.794 30.000,107.920 30.000 C
   108.262 30.000,110.193 28.931,110.775 28.419 C
   111.538 27.748,113.000 26.259,113.000 26.153 C
   113.000 26.082,113.094 25.922,113.208 25.797 C
   113.701 25.261,114.562 23.511,114.835 22.490 C
   114.943 22.083,115.119 21.645,115.225 21.516 C
   115.572 21.093,115.484 13.208,115.124 12.550 C
   114.964 12.257,114.832 11.882,114.831 11.717 C
   114.829 11.552,114.607 11.004,114.337 10.500 C
   114.066 9.996,113.749 9.400,113.632 9.177 C
   113.163 8.287,110.365 5.667,109.883 5.667 C
   109.805 5.667,109.555 5.536,109.329 5.376 C
   108.847 5.036,107.185 4.335,106.859 4.334 C
   106.735 4.334,106.397 4.230,106.109 4.103 C
   104.190 3.260,80.861 2.634,80.560 3.417 M122.786 3.270 C
   122.107 3.948,122.748 4.358,124.640 4.454 C
   125.761 4.511,126.339 4.614,126.542 4.794 C
   127.006 5.204,126.993 59.804,126.529 60.224 C
   126.290 60.440,125.880 60.500,124.638 60.500 C
   122.275 60.500,121.519 61.515,123.719 61.732 C
   125.423 61.900,145.933 61.714,146.282 61.527 C
   146.454 61.436,147.154 61.308,147.838 61.243 C
   148.523 61.178,149.336 61.022,149.645 60.896 C
   149.954 60.770,150.322 60.667,150.462 60.667 C
   152.054 60.667,157.500 56.211,157.500 54.909 C
   157.500 54.836,157.597 54.658,157.716 54.513 C
   158.174 53.956,158.742 52.190,159.168 50.000 C
   159.360 49.011,159.361 43.102,159.169 42.250 C
   158.814 40.679,158.515 39.635,158.366 39.449 C
   158.277 39.340,158.153 39.006,158.089 38.708 C
   158.026 38.410,157.910 38.167,157.831 38.167 C
   157.753 38.167,157.636 37.998,157.572 37.792 C
   157.052 36.131,154.549 33.807,151.882 32.509 C
   149.520 31.359,149.253 31.201,149.194 30.914 C
   149.110 30.511,149.610 30.048,150.261 29.926 C
   150.576 29.867,150.833 29.755,150.833 29.677 C
   150.833 29.600,151.007 29.493,151.219 29.439 C
   153.023 28.986,156.416 24.908,156.923 22.583 C
   156.983 22.308,157.156 21.746,157.307 21.333 C
   157.707 20.247,157.687 13.939,157.281 12.833 C
   157.129 12.421,157.004 11.985,157.002 11.865 C
   156.986 10.379,152.916 5.500,151.694 5.500 C
   151.610 5.500,151.447 5.387,151.333 5.250 C
   151.219 5.112,151.003 5.000,150.852 5.000 C
   150.702 5.000,150.317 4.861,149.998 4.691 C
   149.678 4.521,149.126 4.329,148.771 4.263 C
   148.416 4.197,148.004 4.076,147.854 3.994 C
   146.750 3.383,123.348 2.707,122.786 3.270 M98.886 4.524 C
   100.345 5.006,101.934 5.941,102.890 6.881 C
   103.685 7.662,104.833 9.100,104.833 9.315 C
   104.833 9.417,104.899 9.500,104.980 9.500 C
   105.235 9.500,106.664 12.699,106.669 13.281 C
   106.671 13.447,106.780 13.883,106.912 14.250 C
   107.201 15.052,107.256 20.042,106.987 21.083 C
   106.892 21.450,106.743 22.050,106.657 22.417 C
   105.980 25.278,103.526 27.910,100.417 29.110 C
   98.985 29.662,93.562 30.200,93.318 29.814 C
   93.013 29.333,93.134 4.735,93.442 4.479 C
   94.082 3.947,97.219 3.973,98.886 4.524 M140.964 4.492 C
   147.419 6.695,150.805 14.433,148.788 22.373 C
   148.654 22.898,148.469 23.423,148.376 23.539 C
   148.283 23.655,148.150 23.938,148.081 24.167 C
   148.011 24.396,147.833 24.743,147.685 24.939 C
   147.538 25.135,147.304 25.467,147.167 25.678 C
   145.431 28.340,141.390 29.955,136.371 29.993 C
   135.103 30.003,135.167 30.690,135.167 17.065 L 135.167 4.985 135.576 4.576 C
   136.134 4.017,139.423 3.966,140.964 4.492 M261.841 5.622 C
   261.569 5.729,261.501 5.779,260.637 6.501 C
   260.297 6.785,259.975 7.233,259.916 7.505 C
   259.856 7.775,259.738 8.039,259.654 8.091 C
   259.409 8.243,259.474 10.256,259.741 10.778 C
   259.874 11.038,260.018 11.371,260.061 11.519 C
   260.434 12.807,262.866 13.493,264.197 12.686 C
   267.483 10.693,265.333 4.247,261.841 5.622 M284.458 5.615 C
   284.282 5.661,284.167 5.851,284.167 6.096 C
   284.167 6.490,284.199 6.500,285.511 6.500 C
   287.728 6.500,287.500 5.381,287.500 16.246 C
   287.500 25.549,287.448 26.462,286.970 25.607 C
   286.886 25.456,286.727 25.332,286.617 25.330 C
   286.507 25.329,286.233 25.134,286.008 24.897 C
   283.544 22.300,279.067 23.486,276.417 27.436 C
   274.655 30.063,273.853 32.382,272.927 37.530 C
   272.569 39.518,272.566 46.076,272.922 48.313 C
   273.458 51.688,274.127 54.230,274.799 55.447 C
   274.910 55.648,275.000 55.882,275.000 55.967 C
   275.000 56.535,276.717 59.062,277.911 60.250 C
   280.381 62.709,283.782 63.081,285.917 61.126 C
   286.192 60.874,286.468 60.668,286.530 60.667 C
   286.592 60.667,286.760 60.549,286.903 60.406 C
   287.279 60.031,287.500 60.255,287.500 61.010 C
   287.500 61.371,287.556 61.675,287.625 61.685 C
   288.130 61.757,295.682 61.792,296.051 61.723 C
   297.202 61.509,296.543 60.921,295.091 60.867 C
   294.362 60.840,293.668 60.737,293.550 60.639 C
   293.374 60.493,293.333 55.314,293.333 33.082 L 293.333 5.706 292.958 5.605 C
   292.530 5.490,284.902 5.499,284.458 5.615 M15.333 6.223 C
   14.780 6.407,14.337 7.333,14.802 7.333 C
   15.080 7.333,15.333 8.765,15.339 10.365 C
   15.343 11.395,15.425 12.057,15.583 12.333 C
   15.719 12.571,15.824 13.209,15.827 13.820 C
   15.832 14.568,15.915 14.981,16.104 15.190 C
   16.262 15.364,16.423 15.949,16.489 16.590 C
   16.552 17.196,16.688 17.743,16.791 17.807 C
   16.894 17.871,17.030 18.314,17.094 18.792 C
   17.157 19.270,17.312 19.746,17.438 19.851 C
   17.573 19.963,17.667 20.365,17.667 20.836 C
   17.667 21.385,17.743 21.660,17.914 21.726 C
   18.050 21.778,18.209 22.122,18.268 22.490 C
   18.327 22.859,18.478 23.246,18.604 23.351 C
   18.730 23.455,18.833 23.745,18.833 23.995 C
   18.833 24.244,18.983 24.584,19.167 24.750 C
   19.350 24.916,19.500 25.257,19.500 25.508 C
   19.500 25.760,19.612 26.007,19.750 26.060 C
   19.887 26.113,20.000 26.267,20.000 26.404 C
   20.000 26.690,21.251 29.123,21.599 29.513 C
   21.728 29.658,21.833 29.898,21.833 30.047 C
   21.833 30.195,21.929 30.377,22.045 30.450 C
   22.162 30.523,22.348 30.790,22.458 31.042 C
   22.569 31.294,22.736 31.500,22.830 31.500 C
   22.923 31.500,23.000 31.647,23.000 31.827 C
   23.000 32.008,23.150 32.235,23.333 32.333 C
   23.517 32.431,23.667 32.587,23.667 32.679 C
   23.667 32.887,24.254 33.713,24.917 34.438 C
   25.192 34.739,25.679 35.291,26.000 35.664 C
   26.754 36.542,28.379 38.156,28.939 38.583 C
   29.180 38.767,29.768 39.310,30.246 39.792 C
   30.725 40.273,31.191 40.667,31.282 40.667 C
   31.374 40.667,31.584 40.817,31.750 41.000 C
   31.916 41.183,32.127 41.333,32.220 41.333 C
   32.313 41.333,32.507 41.450,32.653 41.593 C
   32.982 41.918,34.651 43.000,34.822 43.000 C
   34.892 43.000,35.084 43.150,35.250 43.333 C
   35.416 43.517,35.681 43.667,35.839 43.667 C
   35.997 43.667,36.215 43.774,36.324 43.906 C
   36.434 44.038,36.737 44.192,36.998 44.249 C
   37.259 44.307,37.514 44.462,37.564 44.594 C
   37.615 44.725,37.867 44.833,38.124 44.833 C
   38.386 44.833,38.718 44.980,38.878 45.167 C
   39.035 45.350,39.346 45.500,39.570 45.500 C
   39.793 45.500,40.089 45.613,40.226 45.750 C
   40.364 45.888,40.659 46.000,40.882 46.000 C
   41.106 46.000,41.479 46.150,41.712 46.333 C
   41.979 46.543,42.388 46.667,42.818 46.667 C
   43.282 46.667,43.500 46.739,43.500 46.894 C
   43.500 47.050,43.819 47.149,44.535 47.214 C
   45.197 47.274,45.638 47.403,45.761 47.571 C
   45.900 47.760,46.237 47.833,46.975 47.833 C
   47.817 47.833,48.034 47.892,48.205 48.167 C
   48.389 48.460,48.594 48.500,49.936 48.500 C
   51.105 48.500,51.509 48.560,51.672 48.757 C
   52.086 49.255,54.196 49.022,54.471 48.448 C
   54.594 48.190,54.801 47.938,54.931 47.888 C
   55.061 47.838,55.167 47.703,55.167 47.588 C
   55.167 47.473,55.317 47.188,55.500 46.955 C
   55.683 46.721,55.833 46.419,55.833 46.283 C
   55.833 46.147,55.946 45.992,56.083 45.940 C
   56.221 45.887,56.333 45.697,56.333 45.517 C
   56.333 45.337,56.447 45.077,56.586 44.938 C
   56.724 44.800,56.885 44.450,56.943 44.162 C
   57.000 43.874,57.140 43.602,57.254 43.559 C
   57.368 43.515,57.509 43.175,57.569 42.802 C
   57.629 42.429,57.787 41.967,57.922 41.775 C
   58.057 41.583,58.167 41.223,58.167 40.975 C
   58.167 40.727,58.267 40.424,58.389 40.301 C
   58.512 40.179,58.657 39.643,58.712 39.110 C
   58.767 38.577,58.929 37.962,59.073 37.743 C
   59.554 37.008,59.449 32.500,58.950 32.500 C
   58.739 32.500,58.480 32.413,58.373 32.306 C
   58.250 32.183,57.235 32.084,55.592 32.034 C
   53.684 31.976,52.954 31.896,52.815 31.728 C
   52.685 31.572,52.225 31.500,51.355 31.499 C
   50.378 31.499,49.957 31.422,49.538 31.166 C
   49.218 30.971,48.698 30.833,48.283 30.833 C
   47.893 30.833,47.426 30.731,47.245 30.607 C
   47.065 30.483,46.546 30.340,46.093 30.291 C
   45.618 30.239,45.188 30.088,45.075 29.934 C
   44.956 29.771,44.636 29.667,44.258 29.667 C
   43.888 29.667,43.464 29.531,43.212 29.333 C
   42.979 29.150,42.628 29.000,42.431 29.000 C
   42.235 29.000,41.926 28.897,41.745 28.771 C
   41.565 28.646,41.178 28.491,40.887 28.427 C
   40.595 28.364,40.267 28.204,40.158 28.073 C
   40.049 27.941,39.786 27.833,39.575 27.833 C
   39.363 27.833,39.078 27.721,38.940 27.583 C
   38.803 27.446,38.572 27.333,38.427 27.333 C
   38.282 27.333,38.035 27.183,37.878 27.000 C
   37.721 26.817,37.433 26.667,37.239 26.667 C
   37.044 26.667,36.749 26.517,36.583 26.333 C
   36.417 26.150,36.103 26.000,35.886 26.000 C
   35.668 26.000,35.446 25.888,35.394 25.750 C
   35.341 25.613,35.165 25.500,35.003 25.500 C
   34.840 25.500,34.608 25.380,34.486 25.233 C
   34.364 25.086,34.092 24.900,33.882 24.820 C
   33.672 24.740,33.500 24.561,33.500 24.421 C
   33.500 24.260,33.345 24.167,33.078 24.167 C
   32.846 24.167,32.613 24.054,32.560 23.917 C
   32.508 23.779,32.360 23.667,32.234 23.667 C
   32.107 23.667,31.909 23.517,31.795 23.333 C
   31.680 23.150,31.452 23.000,31.288 23.000 C
   31.124 23.000,30.947 22.890,30.896 22.756 C
   30.845 22.622,30.654 22.465,30.472 22.408 C
   30.290 22.350,29.889 22.066,29.581 21.776 C
   29.037 21.265,28.644 20.940,27.736 20.250 C
   27.495 20.067,26.895 19.523,26.402 19.042 C
   25.910 18.560,25.472 18.167,25.428 18.167 C
   25.344 18.167,24.500 17.352,24.500 17.272 C
   24.500 17.246,23.994 16.704,23.375 16.067 C
   22.756 15.431,22.100 14.692,21.917 14.426 C
   21.733 14.160,21.415 13.778,21.208 13.577 C
   21.002 13.376,20.833 13.127,20.833 13.023 C
   20.833 12.918,20.721 12.833,20.583 12.833 C
   20.446 12.833,20.333 12.723,20.333 12.589 C
   20.333 12.455,20.183 12.265,20.000 12.167 C
   19.817 12.069,19.667 11.878,19.667 11.744 C
   19.667 11.610,19.517 11.331,19.333 11.125 C
   18.618 10.320,18.500 10.161,18.500 10.000 C
   18.500 9.908,18.426 9.833,18.336 9.833 C
   18.245 9.833,18.061 9.571,17.927 9.250 C
   17.793 8.929,17.605 8.667,17.509 8.667 C
   17.412 8.667,17.333 8.519,17.333 8.339 C
   17.333 8.159,17.183 7.931,17.000 7.833 C
   16.805 7.729,16.667 7.484,16.667 7.244 C
   16.667 7.017,16.569 6.833,16.449 6.833 C
   16.329 6.833,16.160 6.652,16.074 6.431 C
   15.927 6.055,15.879 6.041,15.333 6.223 M13.307 7.222 C
   13.156 7.329,12.868 7.529,12.667 7.667 C
   10.507 9.144,6.167 13.470,6.167 14.145 C
   6.167 14.239,6.070 14.377,5.952 14.450 C
   5.834 14.523,5.447 15.027,5.092 15.569 C
   4.505 16.464,4.212 16.980,3.095 19.083 C
   2.900 19.450,2.646 20.069,2.531 20.458 C
   2.415 20.848,2.248 21.167,2.160 21.167 C
   2.072 21.167,2.000 21.382,2.000 21.645 C
   2.000 21.909,1.858 22.453,1.685 22.854 C
   1.512 23.255,1.320 23.938,1.259 24.371 C
   1.198 24.804,1.077 25.203,0.990 25.256 C
   0.904 25.309,0.833 25.661,0.833 26.038 C
   0.833 27.154,0.482 28.851,0.231 28.947 C
   -0.071 29.063,-0.112 34.435,0.188 34.535 C
   0.429 34.615,0.825 36.501,0.830 37.592 C
   0.832 38.055,0.921 38.521,1.027 38.627 C
   1.134 38.734,1.279 39.253,1.351 39.782 C
   1.423 40.311,1.558 40.769,1.652 40.801 C
   1.746 40.832,1.868 41.133,1.924 41.470 C
   1.980 41.808,2.143 42.346,2.286 42.667 C
   3.313 44.976,3.277 44.901,4.051 46.333 C
   4.324 46.837,4.649 47.368,4.773 47.513 C
   4.898 47.658,5.000 47.830,5.000 47.895 C
   5.000 48.044,5.627 48.918,6.142 49.485 C
   6.357 49.722,6.640 50.123,6.771 50.375 C
   6.902 50.627,7.082 50.833,7.171 50.833 C
   7.260 50.833,7.333 50.915,7.333 51.015 C
   7.333 51.244,10.356 54.258,11.250 54.921 C
   11.617 55.192,11.954 55.453,12.000 55.499 C
   12.046 55.545,12.308 55.739,12.583 55.930 C
   12.858 56.121,13.476 56.552,13.957 56.888 C
   14.437 57.225,14.913 57.500,15.014 57.500 C
   15.115 57.500,15.388 57.650,15.621 57.833 C
   15.854 58.017,16.138 58.167,16.252 58.167 C
   16.366 58.167,16.553 58.279,16.667 58.417 C
   16.781 58.554,17.005 58.667,17.165 58.667 C
   17.324 58.667,17.646 58.817,17.879 59.000 C
   18.112 59.183,18.443 59.333,18.614 59.333 C
   18.786 59.333,19.074 59.436,19.255 59.562 C
   19.435 59.688,19.895 59.844,20.276 59.908 C
   20.657 59.972,21.107 60.129,21.276 60.257 C
   21.445 60.385,21.789 60.492,22.041 60.495 C
   22.293 60.498,22.930 60.643,23.458 60.818 C
   23.985 60.993,25.099 61.179,25.933 61.231 C
   26.768 61.283,27.834 61.409,28.304 61.509 C
   29.483 61.762,33.852 61.460,36.000 60.978 C
   36.412 60.885,37.125 60.731,37.583 60.636 C
   38.042 60.541,38.604 60.368,38.833 60.251 C
   39.063 60.134,39.499 59.985,39.803 59.920 C
   40.107 59.855,40.557 59.697,40.803 59.569 C
   41.049 59.440,41.362 59.334,41.500 59.333 C
   41.637 59.332,41.975 59.182,42.250 59.000 C
   42.525 58.818,42.873 58.668,43.024 58.668 C
   43.174 58.667,43.341 58.554,43.394 58.417 C
   43.446 58.279,43.645 58.167,43.835 58.167 C
   44.025 58.167,44.383 58.032,44.632 57.868 C
   44.880 57.704,45.210 57.498,45.364 57.410 C
   45.880 57.116,48.081 55.588,48.250 55.407 C
   48.342 55.308,48.828 54.895,49.330 54.489 C
   50.749 53.342,53.500 50.498,53.500 50.178 C
   53.500 50.107,52.994 50.005,52.375 49.952 C
   51.756 49.899,50.971 49.738,50.630 49.595 C
   50.290 49.451,49.573 49.333,49.037 49.333 C
   48.439 49.333,47.939 49.239,47.740 49.089 C
   47.562 48.954,47.076 48.842,46.660 48.839 C
   46.243 48.836,45.611 48.684,45.250 48.500 C
   44.890 48.317,44.366 48.167,44.085 48.167 C
   43.804 48.167,43.417 48.057,43.225 47.922 C
   43.033 47.787,42.567 47.628,42.191 47.568 C
   41.814 47.508,41.421 47.355,41.316 47.229 C
   41.211 47.103,40.920 47.000,40.669 47.000 C
   40.417 47.000,40.021 46.850,39.788 46.667 C
   39.555 46.483,39.216 46.333,39.036 46.333 C
   38.855 46.333,38.614 46.221,38.500 46.083 C
   38.386 45.946,38.119 45.833,37.906 45.833 C
   37.694 45.833,37.480 45.769,37.431 45.690 C
   37.382 45.611,36.769 45.255,36.068 44.898 C
   34.091 43.893,33.956 43.815,33.309 43.309 C
   32.975 43.047,32.630 42.833,32.542 42.833 C
   32.454 42.833,32.194 42.665,31.965 42.458 C
   31.735 42.252,31.377 41.971,31.168 41.833 C
   29.715 40.876,24.000 35.442,24.000 35.017 C
   24.000 34.944,23.869 34.722,23.708 34.524 C
   23.007 33.658,22.779 33.340,21.915 32.023 C
   21.412 31.257,21.000 30.574,21.000 30.507 C
   21.000 30.440,20.850 30.211,20.667 30.000 C
   20.483 29.789,20.333 29.524,20.333 29.412 C
   20.333 29.299,20.228 29.120,20.100 29.014 C
   19.971 28.907,19.817 28.626,19.758 28.390 C
   19.699 28.153,19.541 27.851,19.408 27.718 C
   19.275 27.585,19.167 27.341,19.167 27.175 C
   19.167 27.010,19.059 26.785,18.927 26.676 C
   18.796 26.566,18.637 26.243,18.574 25.956 C
   18.511 25.670,18.356 25.332,18.230 25.206 C
   18.103 25.080,18.000 24.869,18.000 24.737 C
   18.000 24.606,17.850 24.219,17.667 23.877 C
   17.483 23.535,17.333 23.057,17.333 22.815 C
   17.333 22.572,17.227 22.286,17.096 22.177 C
   16.966 22.069,16.807 21.666,16.742 21.282 C
   16.678 20.898,16.522 20.435,16.396 20.255 C
   16.270 20.074,16.167 19.677,16.167 19.374 C
   16.167 19.070,16.052 18.646,15.911 18.432 C
   15.771 18.218,15.610 17.639,15.553 17.146 C
   15.497 16.653,15.349 16.102,15.225 15.921 C
   15.101 15.740,15.000 15.168,15.000 14.648 C
   15.000 14.063,14.875 13.427,14.670 12.977 C
   14.439 12.467,14.338 11.876,14.333 11.000 C
   14.329 10.313,14.247 9.450,14.152 9.083 C
   14.056 8.717,13.917 8.132,13.843 7.785 C
   13.678 7.008,13.651 6.979,13.307 7.222 M383.708 13.442 C
   383.548 13.508,383.500 14.709,383.500 18.647 C
   383.500 24.665,383.692 24.167,381.372 24.167 L 379.645 24.167 379.697 24.625 L 379.750 25.083 381.443 25.131 C
   382.582 25.163,383.194 25.249,383.316 25.396 C
   383.442 25.548,383.513 30.479,383.551 41.765 C
   383.585 51.684,383.670 58.206,383.772 58.667 C
   384.616 62.472,389.033 63.600,391.875 60.736 C
   392.310 60.298,392.667 59.849,392.667 59.740 C
   392.667 59.630,392.774 59.451,392.906 59.342 C
   393.038 59.233,393.196 58.905,393.259 58.613 C
   393.322 58.322,393.456 57.933,393.557 57.750 C
   393.745 57.410,394.175 55.419,394.223 54.667 C
   394.243 54.355,394.158 54.237,393.886 54.198 C
   393.418 54.131,393.190 54.557,392.987 55.884 C
   392.841 56.832,392.599 57.592,392.105 58.655 C
   391.471 60.020,390.169 60.535,389.531 59.673 L 389.167 59.180 389.167 42.275 C
   389.167 27.013,389.192 25.359,389.430 25.268 C
   389.575 25.212,390.357 25.167,391.167 25.167 C
   392.916 25.167,393.167 25.091,393.167 24.566 C
   393.167 24.169,393.155 24.167,391.354 24.167 C
   388.979 24.167,389.180 24.671,389.127 18.569 L 389.083 13.417 386.500 13.386 C
   385.079 13.369,383.823 13.394,383.708 13.442 M230.083 23.664 C
   228.494 24.143,228.208 24.310,227.086 25.417 C
   225.841 26.645,225.267 27.380,224.996 28.093 C
   224.563 29.232,224.252 28.524,224.203 26.287 L 224.157 24.161 219.620 24.206 C
   215.141 24.249,215.083 24.254,215.033 24.603 C
   214.970 25.042,215.349 25.160,216.833 25.167 C
   217.429 25.170,218.029 25.243,218.167 25.330 C
   218.458 25.514,218.618 60.038,218.330 60.505 C
   218.222 60.681,217.743 60.773,216.632 60.833 C
   215.210 60.910,215.079 60.946,215.033 61.271 C
   214.990 61.574,215.088 61.641,215.699 61.727 C
   216.652 61.861,225.789 61.797,226.020 61.655 C
   226.417 61.409,226.090 60.917,225.499 60.872 C
   225.179 60.847,224.748 60.789,224.542 60.744 L 224.167 60.660 224.171 46.872 C
   224.173 39.169,224.241 32.826,224.326 32.500 C
   225.257 28.909,227.480 25.833,229.144 25.833 C
   230.015 25.833,231.163 26.755,231.169 27.459 C
   231.171 27.619,231.280 28.050,231.412 28.417 C
   231.614 28.979,231.653 31.558,231.659 44.872 L 231.667 60.660 231.292 60.744 C
   231.085 60.790,230.617 60.848,230.250 60.872 C
   229.588 60.916,229.103 61.632,229.697 61.687 C
   230.643 61.775,239.198 61.750,239.345 61.660 C
   239.825 61.363,239.414 60.917,238.582 60.830 C
   238.124 60.783,237.655 60.684,237.539 60.611 C
   237.162 60.372,237.326 32.593,237.708 31.849 C
   237.869 31.537,238.000 31.175,238.000 31.045 C
   238.000 30.914,238.298 30.251,238.662 29.572 C
   240.950 25.305,243.225 24.566,244.525 27.667 L 244.910 28.583 244.961 44.375 C
   245.017 61.961,245.100 60.791,243.790 60.866 C
   243.047 60.909,242.909 60.969,242.866 61.269 C
   242.793 61.781,243.440 61.842,248.770 61.828 C
   253.790 61.814,253.833 61.809,253.833 61.232 C
   253.833 60.843,253.799 60.833,252.437 60.833 C
   251.477 60.833,250.983 60.764,250.856 60.611 C
   250.725 60.453,250.658 55.984,250.626 45.153 C
   250.576 28.783,250.570 28.661,249.707 27.000 C
   248.667 24.997,247.810 24.284,245.668 23.638 C
   244.945 23.419,243.077 23.594,242.606 23.924 C
   242.415 24.057,242.115 24.167,241.938 24.167 C
   241.592 24.167,240.291 25.154,239.751 25.826 C
   239.568 26.054,239.286 26.353,239.126 26.490 C
   238.965 26.628,238.833 26.797,238.833 26.865 C
   238.833 27.134,237.436 29.007,237.275 28.953 C
   237.181 28.922,237.037 28.676,236.956 28.406 C
   236.339 26.373,234.817 24.193,234.000 24.171 C
   233.862 24.168,233.525 24.047,233.250 23.903 C
   232.656 23.592,230.789 23.451,230.083 23.664 M344.667 23.611 C
   343.873 23.737,342.000 24.376,342.000 24.521 C
   342.000 24.601,341.884 24.667,341.743 24.667 C
   341.410 24.667,339.134 26.930,338.810 27.583 C
   338.673 27.858,338.475 28.233,338.370 28.417 C
   338.049 28.975,337.500 30.302,337.495 30.533 C
   337.492 30.652,337.379 30.896,337.245 31.074 C
   337.110 31.251,337.000 31.610,337.000 31.871 C
   337.000 32.131,336.894 32.598,336.764 32.909 C
   336.634 33.220,336.446 34.149,336.345 34.974 C
   336.245 35.799,336.089 36.865,335.998 37.343 C
   335.787 38.455,335.784 47.561,335.994 48.333 C
   336.081 48.654,336.235 49.584,336.334 50.401 C
   336.434 51.217,336.593 52.028,336.686 52.203 C
   336.780 52.379,336.920 52.873,336.998 53.303 C
   337.284 54.890,338.492 57.594,339.442 58.778 C
   339.749 59.160,340.000 59.520,340.000 59.578 C
   340.000 60.002,342.202 61.440,343.605 61.931 C
   347.317 63.231,351.054 61.642,352.660 58.083 C
   352.867 57.625,353.137 57.025,353.261 56.750 C
   353.385 56.475,353.539 55.950,353.603 55.583 C
   353.667 55.217,353.820 54.675,353.943 54.380 C
   354.066 54.085,354.166 53.560,354.166 53.214 C
   354.165 52.867,354.290 51.927,354.444 51.125 C
   354.744 49.559,354.748 49.591,354.250 49.441 C
   353.655 49.262,353.524 49.554,353.269 51.623 C
   353.131 52.741,352.939 53.803,352.842 53.983 C
   352.746 54.164,352.664 54.523,352.661 54.781 C
   352.658 55.039,352.510 55.550,352.333 55.917 C
   352.156 56.283,352.009 56.671,352.006 56.778 C
   352.001 56.947,351.424 58.116,350.915 58.989 C
   349.782 60.932,347.230 61.865,345.452 60.987 C
   344.756 60.643,343.333 59.273,343.333 58.946 C
   343.333 58.853,343.233 58.658,343.111 58.513 C
   342.224 57.466,341.365 44.735,342.129 43.971 C
   342.222 43.878,344.806 43.790,348.352 43.760 C
   351.688 43.732,354.473 43.699,354.542 43.687 C
   354.773 43.649,354.676 38.801,354.417 37.444 C
   354.279 36.725,354.165 35.750,354.163 35.277 C
   354.161 34.804,354.011 33.859,353.829 33.178 C
   353.648 32.497,353.500 31.770,353.500 31.563 C
   353.500 31.356,353.434 31.146,353.354 31.096 C
   353.273 31.046,353.122 30.666,353.017 30.250 C
   351.901 25.816,348.387 23.023,344.667 23.611 M365.924 23.612 C
   365.654 23.658,365.281 23.801,365.096 23.931 C
   364.911 24.061,364.667 24.167,364.555 24.167 C
   363.840 24.167,361.991 25.931,361.119 27.444 C
   360.561 28.412,360.444 28.839,360.001 31.500 C
   359.777 32.848,360.239 36.424,360.742 37.232 C
   360.862 37.425,361.008 37.787,361.065 38.036 C
   361.236 38.771,362.396 40.663,363.786 42.471 C
   363.949 42.684,364.867 43.659,365.824 44.637 C
   370.478 49.394,371.611 50.887,372.675 53.667 C
   373.080 54.724,373.076 57.463,372.667 58.416 C
   370.595 63.251,364.818 61.847,362.728 56.001 C
   362.580 55.588,362.356 55.014,362.230 54.725 C
   362.103 54.436,362.000 54.040,362.000 53.844 C
   362.000 53.649,361.895 53.237,361.766 52.929 C
   361.637 52.621,361.482 51.779,361.421 51.059 C
   361.279 49.371,361.066 48.800,360.601 48.867 C
   360.252 48.916,360.250 48.951,360.206 55.303 L 360.163 61.689 360.617 61.636 C
   360.975 61.595,361.124 61.442,361.319 60.917 C
   361.664 59.987,362.308 59.693,362.870 60.208 C
   363.500 60.786,365.070 61.605,366.263 61.980 C
   368.176 62.580,372.076 62.283,372.667 61.492 C
   372.712 61.430,372.977 61.263,373.255 61.121 C
   374.887 60.286,376.273 57.832,376.518 55.346 C
   376.605 54.468,376.715 53.641,376.762 53.508 C
   377.004 52.831,376.183 48.420,375.644 47.500 C
   375.564 47.362,375.455 47.137,375.402 47.000 C
   375.065 46.120,373.550 43.517,373.171 43.167 C
   373.122 43.121,372.860 42.786,372.588 42.423 C
   372.010 41.648,372.264 41.920,369.000 38.578 C
   364.387 33.855,363.316 32.124,363.339 29.432 C
   363.369 25.931,366.389 23.469,368.917 24.885 C
   369.192 25.039,369.504 25.165,369.610 25.166 C
   369.873 25.167,371.805 27.084,372.009 27.546 C
   372.099 27.750,372.353 28.236,372.573 28.627 C
   372.794 29.018,373.026 29.580,373.090 29.877 C
   373.153 30.174,373.271 30.499,373.351 30.599 C
   373.485 30.766,373.848 32.295,374.134 33.898 C
   374.268 34.646,374.919 35.067,375.126 34.539 C
   375.188 34.380,375.242 32.000,375.245 29.250 L 375.250 24.250 374.791 24.197 C
   374.466 24.160,374.300 24.233,374.224 24.447 C
   373.869 25.444,373.171 25.558,371.917 24.823 C
   370.231 23.835,367.671 23.318,365.924 23.612 M256.614 24.275 C
   256.049 24.840,256.710 25.161,258.448 25.164 C
   259.108 25.166,259.693 25.241,259.749 25.333 C
   259.884 25.551,259.881 60.508,259.746 60.643 C
   259.689 60.700,258.954 60.785,258.113 60.832 C
   256.715 60.909,256.579 60.947,256.533 61.269 C
   256.458 61.791,256.794 61.817,263.096 61.779 L 268.750 61.745 268.750 61.331 C
   268.750 60.927,268.714 60.915,267.390 60.869 C
   266.526 60.838,265.964 60.741,265.849 60.602 C
   265.722 60.449,265.656 55.008,265.626 42.317 L 265.583 24.250 261.155 24.206 C
   258.720 24.181,256.676 24.213,256.614 24.275 M297.894 24.417 C
   297.667 25.008,297.949 25.167,299.224 25.167 C
   300.639 25.167,300.742 25.241,300.906 26.376 C
   300.969 26.812,301.128 27.374,301.259 27.626 C
   301.391 27.877,301.499 28.346,301.500 28.667 C
   301.501 28.988,301.604 29.445,301.728 29.684 C
   301.853 29.922,302.007 30.469,302.072 30.899 C
   302.136 31.328,302.296 31.937,302.428 32.251 C
   302.559 32.565,302.667 33.026,302.667 33.275 C
   302.667 33.523,302.808 34.182,302.981 34.738 C
   303.383 36.034,303.644 37.038,303.850 38.083 C
   303.940 38.542,304.090 39.113,304.185 39.353 C
   304.279 39.593,304.466 40.268,304.602 40.853 C
   305.298 43.861,305.531 44.786,306.493 48.333 C
   306.605 48.746,306.789 49.533,306.902 50.083 C
   307.015 50.633,307.195 51.294,307.304 51.553 C
   307.412 51.811,307.500 52.207,307.500 52.433 C
   307.500 52.659,307.601 53.087,307.725 53.383 C
   307.849 53.680,308.007 54.296,308.075 54.753 C
   308.144 55.210,308.268 55.712,308.352 55.869 C
   308.436 56.026,308.613 56.663,308.745 57.285 C
   309.008 58.520,309.404 60.132,309.934 62.125 C
   309.965 62.240,310.048 62.332,310.120 62.331 C
   310.476 62.324,310.669 61.976,310.903 60.913 C
   311.196 59.590,311.434 58.617,311.760 57.417 C
   312.142 56.008,312.509 54.457,312.667 53.583 C
   312.750 53.125,312.930 52.450,313.068 52.083 C
   313.206 51.717,313.322 51.192,313.327 50.917 C
   313.332 50.642,313.447 50.202,313.584 49.939 C
   313.721 49.677,313.833 49.246,313.833 48.982 C
   313.833 48.717,313.976 48.070,314.151 47.542 C
   314.326 47.015,314.517 46.253,314.574 45.849 C
   314.632 45.445,314.732 45.033,314.798 44.933 C
   314.863 44.832,315.000 44.337,315.102 43.833 C
   315.783 40.455,316.657 37.952,316.901 38.679 C
   317.075 39.195,317.525 40.961,317.684 41.750 C
   317.776 42.208,317.955 42.883,318.082 43.250 C
   318.209 43.617,318.359 44.271,318.416 44.704 C
   318.473 45.138,318.580 45.530,318.654 45.576 C
   318.729 45.622,318.840 46.040,318.902 46.505 C
   318.963 46.970,319.115 47.591,319.240 47.884 C
   319.364 48.177,319.544 48.792,319.640 49.250 C
   320.073 51.308,320.351 52.442,320.497 52.750 C
   320.584 52.933,320.728 53.458,320.818 53.917 C
   321.000 54.846,321.227 55.735,321.689 57.320 C
   321.860 57.908,322.000 58.555,322.000 58.758 C
   322.000 58.960,322.105 59.379,322.234 59.688 C
   322.363 59.997,322.522 60.610,322.588 61.050 C
   322.754 62.162,323.500 62.864,323.500 61.908 C
   323.500 61.675,323.604 61.281,323.731 61.033 C
   323.859 60.786,324.043 60.065,324.141 59.432 C
   324.239 58.799,324.398 58.202,324.493 58.107 C
   324.589 58.011,324.667 57.696,324.667 57.405 C
   324.667 57.114,324.817 56.407,325.000 55.833 C
   325.183 55.259,325.334 54.593,325.334 54.353 C
   325.335 54.113,325.436 53.724,325.559 53.488 C
   325.682 53.253,325.836 52.659,325.901 52.168 C
   325.966 51.677,326.095 51.135,326.187 50.962 C
   326.279 50.790,326.429 50.186,326.520 49.622 C
   326.611 49.057,326.747 48.517,326.822 48.422 C
   326.897 48.328,327.012 47.875,327.078 47.417 C
   327.144 46.958,327.304 46.330,327.433 46.021 C
   327.561 45.712,327.667 45.244,327.667 44.980 C
   327.667 44.717,327.810 44.070,327.986 43.542 C
   328.162 43.015,328.354 42.229,328.412 41.796 C
   328.471 41.362,328.581 40.970,328.657 40.923 C
   328.732 40.876,328.848 40.407,328.914 39.880 C
   328.980 39.354,329.133 38.659,329.254 38.336 C
   329.375 38.014,329.522 37.395,329.580 36.962 C
   329.638 36.529,329.757 36.131,329.843 36.077 C
   329.929 36.024,330.000 35.814,330.000 35.610 C
   330.000 35.406,330.117 34.829,330.260 34.328 C
   330.402 33.827,330.623 32.929,330.750 32.333 C
   331.160 30.403,331.509 29.095,331.757 28.558 C
   331.891 28.269,332.002 27.894,332.006 27.725 C
   332.022 26.797,333.996 25.167,335.103 25.167 C
   335.561 25.167,335.723 24.941,335.583 24.499 C
   335.440 24.048,326.231 23.971,326.059 24.419 C
   325.873 24.905,326.325 25.167,327.353 25.167 C
   329.725 25.167,330.736 28.283,329.401 31.479 C
   329.272 31.788,329.166 32.238,329.165 32.479 C
   329.163 32.720,329.013 33.392,328.831 33.973 C
   328.649 34.554,328.498 35.267,328.495 35.557 C
   328.492 35.846,328.379 36.229,328.245 36.407 C
   328.110 36.585,328.000 36.972,328.000 37.267 C
   328.000 37.562,327.851 38.279,327.669 38.860 C
   327.487 39.441,327.337 40.104,327.336 40.333 C
   327.336 40.563,327.227 41.087,327.094 41.500 C
   326.722 42.658,326.162 45.041,326.164 45.455 C
   326.166 45.659,326.058 46.109,325.925 46.455 C
   325.792 46.801,325.602 47.402,325.502 47.792 C
   325.301 48.576,325.066 48.696,324.853 48.125 C
   324.417 46.959,324.284 46.495,324.145 45.667 C
   324.061 45.163,323.881 44.467,323.746 44.122 C
   323.611 43.776,323.500 43.265,323.500 42.986 C
   323.500 42.706,323.412 42.314,323.305 42.114 C
   323.198 41.914,323.021 41.300,322.911 40.750 C
   322.646 39.424,322.372 38.316,321.983 37.000 C
   321.806 36.404,321.663 35.769,321.664 35.589 C
   321.666 35.409,321.562 34.959,321.435 34.589 C
   321.308 34.219,321.124 33.542,321.027 33.083 C
   320.752 31.780,319.960 28.549,319.597 27.250 C
   319.470 26.792,319.314 26.119,319.252 25.756 C
   318.873 23.550,318.359 23.917,317.743 26.833 C
   317.618 27.429,317.365 28.517,317.181 29.250 C
   316.998 29.983,316.765 30.921,316.663 31.333 C
   316.561 31.746,316.403 32.383,316.312 32.750 C
   316.220 33.117,316.046 33.904,315.924 34.500 C
   315.802 35.096,315.623 35.808,315.527 36.083 C
   315.336 36.631,315.081 37.705,314.774 39.250 C
   314.665 39.800,314.484 40.414,314.371 40.614 C
   314.259 40.814,314.167 41.198,314.167 41.467 C
   314.167 41.736,314.017 42.426,313.833 43.000 C
   313.650 43.574,313.500 44.202,313.501 44.397 C
   313.501 44.591,313.390 45.087,313.253 45.500 C
   313.116 45.913,312.923 46.681,312.824 47.208 C
   312.635 48.211,312.410 48.386,311.929 47.905 C
   311.670 47.646,311.585 47.384,311.447 46.417 C
   311.358 45.798,310.861 43.757,310.634 43.083 C
   310.541 42.808,310.398 42.171,310.315 41.667 C
   310.232 41.162,310.082 40.563,309.981 40.333 C
   309.880 40.104,309.734 39.542,309.657 39.083 C
   309.509 38.211,309.146 36.664,308.756 35.250 C
   308.538 34.460,308.397 33.868,307.991 32.042 C
   307.894 31.606,307.708 30.875,307.579 30.417 C
   307.449 29.958,307.295 29.253,307.236 28.850 C
   307.177 28.447,307.033 27.885,306.916 27.600 C
   306.095 25.605,306.340 25.201,308.417 25.126 C
   309.553 25.084,309.585 25.072,309.636 24.625 L 309.689 24.167 303.839 24.167 C
   298.793 24.167,297.976 24.201,297.894 24.417 M284.250 24.816 C
   285.624 25.451,286.788 26.974,287.220 28.701 C
   287.986 31.759,287.557 57.540,286.725 58.513 C
   286.601 58.658,286.500 58.848,286.500 58.937 C
   286.500 59.025,286.181 59.439,285.792 59.857 C
   283.226 62.607,280.848 61.682,279.305 57.333 L 278.921 56.250 278.867 44.167 C
   278.815 32.543,278.915 29.089,279.321 28.598 C
   279.403 28.498,279.522 28.138,279.586 27.798 C
   279.649 27.457,279.806 27.092,279.934 26.986 C
   280.062 26.879,280.167 26.723,280.167 26.637 C
   280.167 26.348,281.081 25.312,281.583 25.031 C
   282.658 24.430,283.303 24.378,284.250 24.816 M347.078 25.068 C
   347.390 25.306,347.696 25.669,347.758 25.875 C
   347.821 26.081,347.967 26.363,348.083 26.500 C
   348.789 27.342,349.223 42.136,348.555 42.624 C
   348.161 42.913,342.488 42.921,342.200 42.633 C
   341.739 42.172,342.000 28.541,342.482 27.930 C
   342.560 27.831,342.671 27.485,342.729 27.161 C
   343.085 25.178,345.622 23.957,347.078 25.068 M97.583 31.413 C
   98.133 31.546,98.789 31.657,99.041 31.661 C
   102.160 31.701,106.668 35.652,107.279 38.881 C
   107.335 39.176,107.441 39.495,107.516 39.592 C
   107.591 39.688,107.699 40.100,107.756 40.508 C
   107.812 40.916,107.971 41.831,108.107 42.541 C
   108.581 45.009,108.310 49.821,107.550 52.417 C
   106.338 56.562,103.226 59.683,99.750 60.242 C
   97.927 60.535,93.916 60.636,93.542 60.397 L 93.167 60.159 93.167 45.774 C
   93.167 37.862,93.217 31.339,93.278 31.278 C
   93.483 31.072,96.574 31.170,97.583 31.413 M139.333 31.343 C
   139.746 31.431,140.507 31.571,141.025 31.654 C
   145.156 32.310,148.081 34.900,149.510 39.167 C
   150.313 41.566,150.723 46.507,150.326 49.000 C
   150.245 49.504,150.105 50.420,150.013 51.036 C
   149.922 51.652,149.732 52.379,149.590 52.653 C
   149.449 52.926,149.333 53.262,149.333 53.400 C
   149.333 54.910,145.670 59.028,143.833 59.582 C
   143.604 59.651,143.325 59.781,143.213 59.870 C
   142.841 60.168,139.948 60.498,137.704 60.499 L 135.492 60.500 135.329 60.072 C
   135.100 59.468,135.109 31.919,135.339 31.489 C
   135.537 31.120,137.898 31.034,139.333 31.343 " stroke="none" fill="#414559" fill-rule="evenodd"></path><path id="path1" d="M0.000 13.500 C
   0.000 26.062,0.101 29.063,0.415 25.792 C
   0.479 25.127,0.625 24.433,0.740 24.250 C
   0.854 24.067,0.996 23.608,1.056 23.230 C
   1.115 22.853,1.276 22.328,1.414 22.064 C
   1.552 21.800,1.666 21.433,1.667 21.250 C
   1.668 21.067,1.818 20.692,2.000 20.417 C
   2.182 20.142,2.332 19.795,2.332 19.645 C
   2.333 19.496,2.438 19.287,2.566 19.181 C
   2.694 19.075,2.847 18.728,2.907 18.411 C
   2.966 18.093,3.081 17.833,3.161 17.833 C
   3.242 17.833,3.394 17.571,3.500 17.250 C
   3.606 16.929,3.762 16.667,3.846 16.667 C
   3.931 16.667,4.000 16.577,4.000 16.467 C
   4.000 16.248,4.842 14.950,5.042 14.861 C
   5.110 14.831,5.167 14.728,5.167 14.634 C
   5.167 14.540,5.467 14.105,5.833 13.667 C
   6.200 13.229,6.500 12.818,6.500 12.755 C
   6.500 12.443,10.989 8.223,12.179 7.417 C
   12.585 7.142,12.977 6.824,13.050 6.709 C
   13.123 6.594,13.386 6.500,13.635 6.500 C
   13.906 6.500,14.170 6.367,14.295 6.167 C
   14.409 5.983,14.606 5.833,14.731 5.833 C
   14.857 5.833,15.053 5.721,15.167 5.583 C
   15.281 5.446,15.508 5.333,15.671 5.333 C
   15.834 5.333,16.432 5.071,17.000 4.750 C
   17.568 4.429,18.110 4.167,18.206 4.167 C
   18.302 4.167,18.688 4.027,19.065 3.856 C
   19.969 3.447,20.735 3.179,21.750 2.918 C
   22.208 2.800,22.794 2.620,23.053 2.518 C
   23.311 2.417,23.948 2.332,24.469 2.331 C
   24.990 2.329,25.867 2.219,26.417 2.087 C
   27.589 1.804,32.731 1.739,33.414 1.999 C
   33.653 2.090,34.557 2.229,35.421 2.309 C
   36.286 2.388,37.088 2.530,37.205 2.625 C
   37.321 2.719,37.754 2.846,38.167 2.907 C
   38.579 2.967,39.179 3.120,39.500 3.245 C
   39.821 3.370,40.533 3.638,41.083 3.839 C
   41.633 4.041,42.196 4.265,42.333 4.338 C
   42.471 4.411,43.296 4.831,44.167 5.272 C
   45.038 5.713,45.810 6.170,45.883 6.287 C
   45.957 6.404,46.114 6.500,46.233 6.500 C
   47.069 6.500,52.519 11.400,53.925 13.417 C
   54.244 13.875,54.791 14.649,55.138 15.136 C
   55.486 15.624,56.141 16.749,56.594 17.636 C
   57.046 18.524,57.467 19.288,57.528 19.335 C
   57.590 19.382,57.693 19.644,57.756 19.918 C
   57.820 20.192,58.003 20.717,58.163 21.083 C
   58.591 22.064,58.839 22.805,59.083 23.833 C
   59.203 24.337,59.381 24.975,59.478 25.250 C
   59.725 25.953,59.985 30.842,59.794 31.221 C
   59.689 31.431,59.729 31.711,59.922 32.118 C
   60.166 32.633,60.179 32.906,60.025 34.230 C
   59.928 35.066,59.800 36.387,59.741 37.167 C
   59.683 37.946,59.530 38.749,59.401 38.952 C
   59.272 39.155,59.164 39.568,59.161 39.869 C
   59.158 40.170,59.010 40.717,58.833 41.083 C
   58.656 41.450,58.509 41.913,58.506 42.113 C
   58.503 42.313,58.388 42.589,58.250 42.726 C
   58.112 42.864,58.000 43.070,58.000 43.185 C
   58.000 43.400,57.494 44.590,57.088 45.333 C
   56.962 45.563,56.670 46.132,56.438 46.598 C
   56.206 47.065,55.900 47.542,55.758 47.660 C
   55.616 47.778,55.500 47.960,55.500 48.065 C
   55.500 48.546,54.645 49.500,54.202 49.513 C
   53.954 49.520,53.844 49.564,53.958 49.610 C
   54.321 49.757,54.185 50.343,53.662 50.889 C
   52.791 51.801,52.000 52.745,52.000 52.875 C
   52.000 52.944,51.924 53.000,51.832 53.000 C
   51.740 53.000,51.159 53.506,50.540 54.125 C
   49.922 54.744,49.079 55.475,48.667 55.750 C
   48.254 56.024,47.879 56.287,47.833 56.333 C
   47.788 56.380,47.413 56.642,47.000 56.917 C
   46.587 57.191,46.212 57.461,46.167 57.515 C
   46.073 57.628,45.838 57.755,43.648 58.875 C
   42.797 59.310,41.980 59.667,41.832 59.667 C
   41.685 59.667,41.418 59.777,41.240 59.911 C
   41.062 60.046,40.804 60.161,40.667 60.167 C
   40.529 60.173,40.117 60.323,39.750 60.500 C
   39.383 60.677,38.858 60.825,38.583 60.829 C
   38.308 60.832,37.864 60.950,37.596 61.090 C
   37.327 61.230,36.502 61.408,35.762 61.487 C
   35.022 61.565,34.206 61.713,33.947 61.815 C
   33.340 62.055,27.151 62.055,26.250 61.814 C
   25.883 61.717,25.020 61.572,24.332 61.493 C
   23.644 61.415,22.802 61.234,22.462 61.092 C
   22.122 60.950,21.671 60.833,21.459 60.833 C
   21.247 60.833,20.917 60.723,20.725 60.589 C
   20.533 60.454,20.067 60.295,19.691 60.235 C
   19.314 60.174,18.921 60.022,18.816 59.896 C
   18.711 59.770,18.466 59.666,18.271 59.666 C
   18.076 59.665,17.692 59.515,17.417 59.333 C
   17.142 59.151,16.783 59.002,16.620 59.001 C
   16.456 59.000,16.280 58.887,16.227 58.750 C
   16.174 58.613,15.999 58.500,15.838 58.500 C
   15.677 58.500,15.365 58.369,15.146 58.208 C
   14.597 57.808,14.158 57.535,13.669 57.290 C
   13.441 57.177,13.141 56.986,13.002 56.867 C
   12.864 56.748,12.188 56.226,11.500 55.706 C
   10.171 54.701,7.000 51.622,7.000 51.337 C
   7.000 51.243,6.904 51.167,6.786 51.167 C
   6.668 51.167,6.431 50.923,6.258 50.625 C
   6.085 50.327,5.788 49.884,5.597 49.640 C
   4.819 48.646,4.667 48.426,4.667 48.298 C
   4.667 48.224,4.404 47.790,4.083 47.333 C
   3.763 46.877,3.500 46.421,3.500 46.320 C
   3.500 46.219,3.350 45.945,3.167 45.712 C
   2.983 45.479,2.833 45.158,2.833 44.998 C
   2.833 44.838,2.721 44.614,2.583 44.500 C
   2.446 44.386,2.333 44.207,2.333 44.102 C
   2.333 43.997,2.183 43.632,2.000 43.290 C
   1.817 42.948,1.667 42.467,1.667 42.222 C
   1.667 41.977,1.555 41.652,1.418 41.501 C
   1.280 41.350,1.120 40.856,1.062 40.404 C
   1.003 39.953,0.860 39.471,0.743 39.333 C
   0.627 39.196,0.478 38.577,0.413 37.958 C
   0.091 34.897,0.000 37.926,0.000 51.750 L 0.000 66.667 200.000 66.667 L 400.000 66.667 400.000 33.333 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 13.500 M183.255 2.560 C
   183.435 2.684,183.865 2.838,184.208 2.901 C
   184.552 2.964,184.833 3.070,184.833 3.138 C
   184.833 3.205,185.040 3.353,185.292 3.467 C
   186.177 3.866,186.756 4.217,187.445 4.773 C
   187.827 5.081,188.178 5.333,188.226 5.333 C
   188.274 5.333,188.561 5.561,188.865 5.839 C
   190.353 7.201,191.667 6.605,191.667 4.566 C
   191.667 3.437,192.063 3.150,193.166 3.480 L 193.833 3.680 193.833 12.584 C
   193.833 22.568,193.929 21.833,192.628 21.833 C
   191.979 21.833,191.667 21.585,191.667 21.069 C
   191.667 20.869,191.555 20.490,191.418 20.227 C
   191.281 19.965,191.168 19.322,191.168 18.798 C
   191.167 18.241,191.028 17.495,190.833 17.000 C
   190.650 16.535,190.500 15.951,190.499 15.702 C
   190.498 15.453,190.386 15.035,190.249 14.773 C
   190.112 14.510,190.000 14.143,190.000 13.958 C
   190.000 13.502,189.041 11.398,188.012 9.594 C
   186.863 7.580,183.568 4.997,182.151 4.999 C
   181.914 5.000,181.351 4.888,180.901 4.752 C
   179.865 4.437,178.279 4.434,177.417 4.745 C
   177.050 4.877,176.440 4.989,176.062 4.993 C
   175.662 4.997,175.287 5.105,175.167 5.250 C
   175.053 5.388,174.882 5.500,174.787 5.500 C
   174.066 5.500,172.008 6.970,171.378 7.935 C
   171.265 8.108,170.984 8.535,170.753 8.882 C
   170.522 9.230,170.333 9.664,170.333 9.847 C
   170.333 10.029,170.207 10.370,170.054 10.605 C
   169.638 11.239,169.619 16.680,170.029 17.417 C
   170.182 17.692,170.476 18.292,170.681 18.750 C
   171.120 19.728,171.159 19.780,172.439 21.083 C
   173.387 22.048,174.091 22.667,174.244 22.667 C
   174.284 22.667,174.667 22.903,175.096 23.192 C
   175.972 23.784,178.650 25.157,178.938 25.163 C
   179.041 25.165,179.213 25.271,179.319 25.399 C
   179.425 25.527,179.772 25.681,180.089 25.740 C
   180.407 25.800,180.667 25.908,180.667 25.981 C
   180.667 26.054,180.967 26.204,181.333 26.314 C
   181.700 26.423,182.000 26.585,182.000 26.673 C
   182.000 26.761,182.109 26.833,182.241 26.833 C
   182.474 26.833,183.856 27.513,184.233 27.813 C
   184.334 27.893,184.600 28.016,184.824 28.084 C
   185.048 28.153,185.591 28.462,186.031 28.771 C
   186.470 29.080,186.908 29.333,187.004 29.333 C
   187.099 29.333,187.218 29.439,187.267 29.568 C
   187.317 29.698,187.521 29.845,187.720 29.897 C
   188.139 30.005,192.000 33.691,192.000 33.982 C
   192.000 34.090,192.110 34.219,192.244 34.271 C
   192.378 34.322,192.537 34.519,192.597 34.709 C
   192.657 34.899,192.960 35.408,193.270 35.839 C
   193.580 36.270,193.833 36.715,193.833 36.827 C
   193.833 36.939,193.983 37.221,194.167 37.455 C
   194.350 37.688,194.500 38.004,194.500 38.157 C
   194.500 38.311,194.610 38.582,194.745 38.760 C
   194.879 38.938,194.993 39.271,194.998 39.500 C
   195.003 39.729,195.156 40.244,195.337 40.643 C
   195.518 41.043,195.667 41.690,195.667 42.081 C
   195.667 42.472,195.779 43.110,195.917 43.500 C
   196.089 43.988,196.167 44.871,196.167 46.333 C
   196.167 47.796,196.089 48.679,195.917 49.167 C
   195.779 49.556,195.667 50.204,195.667 50.605 C
   195.667 51.016,195.521 51.606,195.333 51.956 C
   195.150 52.299,195.000 52.742,195.000 52.942 C
   195.000 53.143,194.894 53.519,194.764 53.778 C
   194.634 54.038,194.447 54.438,194.349 54.667 C
   194.250 54.896,194.105 55.196,194.026 55.333 C
   193.947 55.471,193.703 55.948,193.483 56.393 C
   193.263 56.839,192.990 57.240,192.875 57.286 C
   192.760 57.332,192.667 57.444,192.667 57.536 C
   192.667 57.927,190.178 60.571,189.522 60.877 C
   189.372 60.947,189.025 61.154,188.750 61.338 C
   188.475 61.522,187.969 61.795,187.625 61.945 C
   187.281 62.095,187.000 62.277,187.000 62.351 C
   187.000 62.425,186.719 62.529,186.375 62.584 C
   186.031 62.638,185.493 62.810,185.180 62.966 C
   184.316 63.396,178.023 63.390,177.546 62.958 C
   177.369 62.798,177.079 62.667,176.901 62.667 C
   176.724 62.667,176.299 62.517,175.956 62.333 C
   175.614 62.150,175.231 62.000,175.104 62.000 C
   174.978 62.000,174.782 61.889,174.670 61.754 C
   174.558 61.619,174.212 61.461,173.900 61.403 C
   173.588 61.344,173.333 61.245,173.333 61.182 C
   173.333 61.078,172.831 60.794,170.286 59.461 C
   169.068 58.823,168.000 58.872,168.000 59.566 C
   168.000 59.764,167.885 60.090,167.745 60.290 C
   167.604 60.491,167.436 61.089,167.370 61.619 L 167.250 62.583 166.529 62.635 C
   165.248 62.727,165.333 63.493,165.333 51.868 C
   165.333 40.293,165.280 40.833,166.422 40.833 C
   167.171 40.833,167.278 41.044,167.398 42.750 C
   167.456 43.575,167.612 44.550,167.744 44.917 C
   167.877 45.283,167.989 46.080,167.993 46.688 C
   167.998 47.419,168.078 47.857,168.231 47.984 C
   168.358 48.090,168.514 48.657,168.577 49.244 C
   168.640 49.839,168.796 50.400,168.929 50.510 C
   169.060 50.619,169.167 50.905,169.168 51.145 C
   169.168 51.386,169.318 51.808,169.500 52.083 C
   169.682 52.358,169.832 52.743,169.832 52.938 C
   169.833 53.133,169.946 53.386,170.083 53.500 C
   170.221 53.614,170.333 53.807,170.333 53.930 C
   170.333 54.125,170.506 54.421,171.378 55.723 C
   172.472 57.355,175.216 59.639,176.436 59.931 C
   176.700 59.994,177.067 60.129,177.250 60.230 C
   178.695 61.026,184.378 61.038,185.333 60.246 C
   185.471 60.133,185.771 59.982,186.000 59.912 C
   189.487 58.846,191.409 52.812,189.476 49.000 C
   189.050 48.160,187.395 46.428,186.853 46.256 C
   186.659 46.195,186.500 46.074,186.500 45.989 C
   186.500 45.903,186.428 45.833,186.341 45.833 C
   186.193 45.833,185.149 45.197,184.737 44.856 C
   184.638 44.774,184.402 44.658,184.211 44.597 C
   184.020 44.537,183.772 44.377,183.661 44.244 C
   183.550 44.110,183.316 44.000,183.141 44.000 C
   182.966 44.000,182.780 43.888,182.727 43.750 C
   182.674 43.613,182.499 43.500,182.338 43.500 C
   182.177 43.500,181.854 43.350,181.621 43.167 C
   181.388 42.983,181.126 42.833,181.038 42.833 C
   180.950 42.833,180.699 42.695,180.481 42.525 C
   180.262 42.356,179.877 42.167,179.625 42.106 C
   179.373 42.044,179.167 41.920,179.167 41.830 C
   179.167 41.740,179.062 41.667,178.933 41.667 C
   178.805 41.667,178.309 41.403,177.831 41.082 C
   177.353 40.760,176.783 40.452,176.564 40.397 C
   176.346 40.342,176.167 40.231,176.167 40.151 C
   176.167 40.070,175.923 39.892,175.625 39.755 C
   173.303 38.688,169.330 34.726,167.917 32.068 C
   167.688 31.637,167.435 31.202,167.354 31.101 C
   167.273 31.000,167.151 30.733,167.082 30.508 C
   167.013 30.283,166.853 29.996,166.728 29.871 C
   166.603 29.745,166.500 29.506,166.500 29.340 C
   166.500 29.173,166.352 28.709,166.170 28.310 C
   165.989 27.910,165.839 27.337,165.837 27.036 C
   165.835 26.735,165.708 26.298,165.556 26.065 C
   165.159 25.459,165.154 16.119,165.550 15.083 C
   165.690 14.717,165.845 14.076,165.894 13.659 C
   165.944 13.243,166.100 12.680,166.241 12.409 C
   166.382 12.138,166.498 11.716,166.499 11.470 C
   166.500 11.225,166.612 10.911,166.750 10.774 C
   166.887 10.636,167.002 10.425,167.005 10.304 C
   167.044 8.681,171.848 3.500,173.314 3.500 C
   173.417 3.500,173.781 3.350,174.123 3.167 C
   174.465 2.983,174.938 2.833,175.174 2.833 C
   175.410 2.833,175.749 2.723,175.926 2.589 C
   176.382 2.244,182.760 2.219,183.255 2.560 M104.183 3.109 C
   104.311 3.215,104.867 3.355,105.417 3.421 C
   105.967 3.486,106.529 3.633,106.667 3.747 C
   106.804 3.861,107.177 4.004,107.496 4.064 C
   108.049 4.168,109.491 4.785,109.667 4.992 C
   109.712 5.046,109.954 5.180,110.203 5.289 C
   110.743 5.526,111.383 6.066,112.741 7.431 C
   113.805 8.501,114.020 8.776,114.423 9.583 C
   114.560 9.858,114.737 10.196,114.816 10.333 C
   115.213 11.018,115.667 12.499,115.667 13.107 C
   115.667 13.484,115.779 14.110,115.917 14.500 C
   116.251 15.449,116.255 18.825,115.921 19.750 C
   115.789 20.117,115.678 20.736,115.674 21.126 C
   115.670 21.516,115.517 22.114,115.333 22.456 C
   115.150 22.799,115.000 23.243,115.000 23.444 C
   115.000 23.645,114.888 23.922,114.750 24.060 C
   114.612 24.197,114.500 24.417,114.500 24.549 C
   114.500 24.681,114.350 24.979,114.167 25.212 C
   113.983 25.445,113.833 25.714,113.833 25.810 C
   113.833 25.905,113.738 26.043,113.621 26.117 C
   113.504 26.190,113.288 26.438,113.141 26.667 C
   112.405 27.811,109.917 29.758,108.616 30.208 C
   107.432 30.617,107.560 30.955,109.167 31.666 C
   110.350 32.190,111.000 32.543,111.000 32.663 C
   111.000 32.737,111.198 32.847,111.440 32.908 C
   111.682 32.968,112.113 33.237,112.398 33.505 C
   112.683 33.772,113.273 34.322,113.708 34.726 C
   114.144 35.130,114.500 35.544,114.500 35.647 C
   114.500 35.749,114.566 35.833,114.647 35.833 C
   114.886 35.833,116.333 38.078,116.333 38.450 C
   116.333 38.638,116.440 38.881,116.570 38.989 C
   116.701 39.097,116.860 39.499,116.925 39.881 C
   116.989 40.263,117.136 40.728,117.251 40.913 C
   117.880 41.924,117.828 50.951,117.189 51.724 C
   117.102 51.830,116.976 52.217,116.911 52.583 C
   116.846 52.950,116.652 53.484,116.480 53.770 C
   116.308 54.055,116.167 54.412,116.167 54.561 C
   116.167 54.711,116.093 54.833,116.003 54.833 C
   115.913 54.833,115.794 55.015,115.738 55.238 C
   115.682 55.460,115.493 55.772,115.318 55.930 C
   115.143 56.089,115.000 56.287,115.000 56.371 C
   115.000 56.984,112.393 59.290,110.884 60.011 C
   110.444 60.221,110.038 60.461,109.983 60.542 C
   109.927 60.624,109.627 60.755,109.316 60.834 C
   109.005 60.913,108.450 61.092,108.083 61.231 C
   107.717 61.371,107.147 61.489,106.817 61.493 C
   106.488 61.497,105.963 61.628,105.651 61.784 C
   104.835 62.192,80.258 62.240,79.991 61.833 C
   79.298 60.780,80.196 60.000,82.103 60.000 C
   83.282 60.000,83.704 59.938,83.905 59.738 C
   84.142 59.501,84.164 56.839,84.125 32.279 L 84.083 5.083 82.250 5.000 C
   80.112 4.903,80.066 4.883,79.912 3.970 C
   79.705 2.747,78.981 2.812,92.183 2.867 C
   100.760 2.903,104.012 2.969,104.183 3.109 M145.333 3.007 C
   147.189 3.302,148.672 3.630,148.875 3.790 C
   148.990 3.880,149.308 4.004,149.583 4.066 C
   150.055 4.172,150.943 4.539,151.500 4.859 C
   151.637 4.938,151.938 5.093,152.167 5.204 C
   153.421 5.811,155.840 8.205,156.382 9.375 C
   156.583 9.810,156.799 10.167,156.861 10.167 C
   156.923 10.167,157.026 10.410,157.089 10.708 C
   157.153 11.006,157.309 11.438,157.436 11.667 C
   157.563 11.896,157.739 12.608,157.825 13.250 C
   157.912 13.892,158.069 14.867,158.174 15.417 C
   158.374 16.468,158.266 19.422,158.016 19.740 C
   157.939 19.837,157.837 20.404,157.789 21.000 C
   157.741 21.596,157.582 22.308,157.435 22.583 C
   157.289 22.858,157.168 23.247,157.168 23.446 C
   157.167 23.646,157.058 23.918,156.925 24.051 C
   156.792 24.184,156.632 24.496,156.570 24.743 C
   156.508 24.991,156.354 25.233,156.229 25.281 C
   156.103 25.330,156.000 25.439,156.000 25.525 C
   156.000 25.758,154.655 27.538,154.215 27.888 C
   154.004 28.055,153.713 28.311,153.567 28.456 C
   153.151 28.872,151.938 29.660,151.708 29.664 C
   151.594 29.665,151.500 29.742,151.500 29.834 C
   151.500 29.926,151.168 30.090,150.761 30.199 C
   150.320 30.317,149.992 30.511,149.948 30.681 C
   149.874 30.964,150.004 31.045,152.250 32.110 C
   153.121 32.524,153.883 33.022,154.050 33.288 C
   154.123 33.404,154.261 33.500,154.357 33.500 C
   154.820 33.500,157.321 36.153,157.781 37.132 C
   157.898 37.380,158.068 37.696,158.158 37.833 C
   158.466 38.302,159.000 39.648,159.000 39.954 C
   159.000 40.122,159.109 40.414,159.241 40.603 C
   160.050 41.758,160.060 50.268,159.254 51.826 C
   159.114 52.096,159.000 52.437,159.000 52.584 C
   159.000 52.858,158.452 54.217,158.160 54.667 C
   158.071 54.804,157.907 55.104,157.796 55.333 C
   157.293 56.377,155.775 58.082,154.272 59.293 C
   153.872 59.614,151.510 60.833,151.284 60.834 C
   151.174 60.835,150.858 60.953,150.583 61.097 C
   150.308 61.240,149.708 61.397,149.250 61.445 C
   148.792 61.492,148.154 61.652,147.833 61.799 C
   147.107 62.133,123.281 62.340,122.458 62.020 C
   122.083 61.874,122.000 61.739,122.000 61.273 C
   122.000 60.207,122.350 60.010,124.333 59.960 L 126.083 59.917 126.125 32.458 L 126.168 5.000 124.417 5.000 C
   122.889 5.000,122.624 4.958,122.333 4.667 C
   121.960 4.293,121.883 3.350,122.200 3.033 C
   122.440 2.793,143.833 2.769,145.333 3.007 M95.083 4.481 C
   94.808 4.548,94.340 4.700,94.042 4.818 L 93.500 5.033 93.500 17.200 L 93.500 29.368 96.141 29.309 C
   97.877 29.270,98.865 29.181,99.024 29.049 C
   99.158 28.938,99.600 28.796,100.008 28.732 C
   100.416 28.668,100.929 28.478,101.147 28.308 C
   101.366 28.139,101.616 28.000,101.702 28.000 C
   102.218 28.000,105.163 25.223,105.166 24.734 C
   105.166 24.633,105.317 24.378,105.500 24.167 C
   105.683 23.955,105.833 23.621,105.833 23.424 C
   105.833 23.228,105.919 22.981,106.023 22.877 C
   106.621 22.279,107.148 16.212,106.657 15.582 C
   106.585 15.489,106.474 14.927,106.411 14.332 C
   106.348 13.737,106.192 13.084,106.065 12.881 C
   105.937 12.678,105.833 12.424,105.833 12.317 C
   105.833 11.161,103.400 7.782,101.583 6.417 C
   101.339 6.233,101.039 5.990,100.915 5.875 C
   100.792 5.760,100.591 5.666,100.470 5.666 C
   100.349 5.665,100.025 5.515,99.750 5.333 C
   99.475 5.151,99.079 5.002,98.870 5.001 C
   98.660 5.000,98.219 4.887,97.888 4.749 C
   97.164 4.446,95.772 4.314,95.083 4.481 M136.506 4.662 C
   136.006 4.848,135.574 5.000,135.547 5.000 C
   135.520 5.000,135.517 10.456,135.541 17.125 L 135.583 29.250 138.266 29.250 C
   140.057 29.250,141.027 29.186,141.183 29.057 C
   141.312 28.950,141.717 28.809,142.083 28.744 C
   142.450 28.678,142.862 28.530,143.000 28.415 C
   143.137 28.300,143.438 28.151,143.667 28.083 C
   143.896 28.015,144.396 27.706,144.778 27.396 C
   145.160 27.087,145.535 26.831,145.611 26.829 C
   145.688 26.826,145.938 26.556,146.167 26.229 C
   146.396 25.901,146.708 25.497,146.860 25.332 C
   147.471 24.666,148.500 22.216,148.500 21.428 C
   148.500 21.003,148.612 20.387,148.750 20.058 C
   149.087 19.251,149.087 16.090,148.750 15.500 C
   148.619 15.271,148.509 14.717,148.506 14.268 C
   148.500 13.457,148.371 13.099,147.207 10.667 C
   146.495 9.180,146.546 9.249,144.985 7.660 C
   143.606 6.256,141.800 5.117,140.664 4.936 C
   140.262 4.871,139.844 4.745,139.736 4.655 C
   139.312 4.304,137.461 4.308,136.506 4.662 M264.675 5.709 C
   265.572 6.312,266.500 8.155,266.500 9.333 C
   266.500 10.466,265.316 12.833,264.751 12.833 C
   264.635 12.833,264.447 12.946,264.333 13.083 C
   263.862 13.652,261.310 13.421,260.533 12.740 C
   259.252 11.617,258.521 8.077,259.417 7.333 C
   259.554 7.219,259.667 7.026,259.667 6.905 C
   259.667 5.647,263.293 4.781,264.675 5.709 M293.515 5.449 C
   293.603 5.536,293.683 17.012,293.708 33.008 L 293.750 60.417 295.250 60.500 L 296.750 60.583 296.803 61.136 C
   296.902 62.170,296.918 62.167,292.021 62.163 C
   287.281 62.159,287.071 62.124,286.778 61.288 C
   286.681 61.011,286.654 61.013,286.343 61.324 C
   284.613 63.054,280.378 63.177,278.696 61.547 C
   278.575 61.429,278.383 61.333,278.268 61.333 C
   278.154 61.333,277.915 61.165,277.738 60.958 C
   276.514 59.534,276.000 58.873,276.000 58.723 C
   276.000 58.629,275.850 58.416,275.667 58.250 C
   275.483 58.084,275.333 57.819,275.333 57.661 C
   275.333 57.503,275.226 57.285,275.094 57.176 C
   274.962 57.066,274.808 56.762,274.750 56.500 C
   274.692 56.238,274.538 55.934,274.406 55.824 C
   274.274 55.715,274.167 55.482,274.167 55.306 C
   274.167 55.131,274.017 54.693,273.833 54.333 C
   273.650 53.974,273.500 53.458,273.500 53.187 C
   273.500 52.916,273.420 52.668,273.323 52.635 C
   273.225 52.603,273.076 52.030,272.993 51.362 C
   272.909 50.694,272.726 49.812,272.587 49.401 C
   272.219 48.320,272.226 37.578,272.595 36.583 C
   272.731 36.217,272.889 35.411,272.945 34.792 C
   273.003 34.166,273.148 33.584,273.274 33.480 C
   273.398 33.377,273.500 33.039,273.500 32.729 C
   273.500 32.419,273.650 31.886,273.833 31.544 C
   274.017 31.201,274.167 30.820,274.167 30.695 C
   274.167 30.570,274.279 30.269,274.416 30.026 C
   274.552 29.782,274.741 29.396,274.835 29.167 C
   274.928 28.938,275.152 28.525,275.331 28.250 C
   275.510 27.975,275.773 27.544,275.916 27.292 C
   276.059 27.040,276.248 26.833,276.338 26.833 C
   276.427 26.833,276.500 26.753,276.500 26.655 C
   276.500 26.349,277.852 24.856,278.483 24.466 C
   280.202 23.403,280.400 23.346,282.490 23.302 C
   284.173 23.267,284.525 23.304,284.793 23.547 C
   284.967 23.704,285.191 23.833,285.290 23.833 C
   285.390 23.833,285.739 24.093,286.066 24.411 C
   286.636 24.965,286.668 24.975,286.840 24.655 C
   287.025 24.308,287.065 8.698,286.884 7.465 L 286.793 6.846 285.355 6.798 L 283.917 6.750 283.866 6.054 C
   283.818 5.400,283.844 5.354,284.283 5.304 C
   285.333 5.183,293.376 5.310,293.515 5.449 M389.426 13.238 C
   389.629 13.387,389.754 13.684,389.761 14.030 C
   389.766 14.334,389.785 16.665,389.802 19.208 L 389.833 23.833 391.587 23.833 L 393.342 23.833 393.514 24.287 C
   393.891 25.278,393.555 25.500,391.680 25.500 C
   390.767 25.500,389.974 25.574,389.918 25.664 C
   389.787 25.876,389.802 58.899,389.933 59.424 L 390.034 59.823 390.690 59.488 C
   391.051 59.304,391.419 58.987,391.506 58.785 C
   391.594 58.582,391.730 58.304,391.809 58.167 C
   392.178 57.521,392.556 56.154,392.621 55.229 C
   392.709 53.991,393.578 53.413,394.487 53.989 C
   394.803 54.188,394.741 55.975,394.406 56.345 C
   394.275 56.490,394.167 56.917,394.167 57.293 C
   394.167 57.678,394.058 58.085,393.917 58.226 C
   393.779 58.364,393.667 58.632,393.667 58.821 C
   393.667 59.011,393.517 59.317,393.333 59.500 C
   393.150 59.683,393.000 59.935,393.000 60.060 C
   393.000 60.364,391.388 61.961,390.940 62.100 C
   390.744 62.162,390.530 62.295,390.465 62.397 C
   390.213 62.791,386.741 62.739,385.955 62.330 C
   385.193 61.934,384.171 61.025,384.168 60.742 C
   384.167 60.645,384.084 60.484,383.984 60.384 C
   383.884 60.284,383.621 59.687,383.401 59.059 L 383.001 57.917 383.001 41.708 L 383.000 25.500 381.322 25.500 C
   379.387 25.500,379.000 25.361,379.000 24.667 C
   379.000 23.972,379.387 23.833,381.322 23.833 L 383.000 23.833 383.000 18.617 C
   383.000 14.781,383.053 13.347,383.200 13.200 C
   383.511 12.889,388.998 12.923,389.426 13.238 M233.749 23.568 C
   233.972 23.714,234.241 23.833,234.347 23.833 C
   235.100 23.833,237.167 26.708,237.167 27.755 C
   237.167 27.981,237.225 28.167,237.296 28.167 C
   237.465 28.167,238.000 27.541,238.000 27.343 C
   238.000 27.259,238.094 27.091,238.208 26.970 C
   238.323 26.849,238.621 26.450,238.870 26.083 C
   239.120 25.717,239.560 25.229,239.848 25.000 C
   240.137 24.771,240.581 24.415,240.835 24.208 C
   241.090 24.002,241.384 23.833,241.488 23.833 C
   241.593 23.833,241.848 23.722,242.055 23.587 C
   242.706 23.160,246.421 23.211,247.280 23.659 C
   248.703 24.400,250.086 26.159,250.411 27.643 C
   250.477 27.943,250.636 28.390,250.765 28.636 C
   250.960 29.010,250.998 31.617,250.999 44.528 C
   251.000 53.023,251.046 60.091,251.101 60.236 C
   251.182 60.446,251.473 60.500,252.529 60.500 C
   254.120 60.500,254.500 60.663,254.500 61.343 C
   254.500 62.122,254.154 62.167,248.189 62.167 C
   242.268 62.167,242.333 62.177,242.333 61.234 C
   242.333 60.622,242.539 60.500,243.577 60.500 C
   243.978 60.500,244.375 60.400,244.482 60.272 C
   244.780 59.912,244.734 30.838,244.433 29.642 C
   244.309 29.152,244.159 28.424,244.100 28.026 C
   243.991 27.302,243.067 26.167,242.586 26.167 C
   242.333 26.167,241.307 26.671,241.080 26.907 C
   239.949 28.080,238.333 30.866,238.333 31.642 C
   238.333 31.895,238.223 32.249,238.089 32.426 C
   237.878 32.705,237.843 34.689,237.839 46.625 L 237.833 60.500 238.594 60.500 C
   239.622 60.500,240.000 60.726,240.000 61.343 C
   240.000 62.128,239.642 62.176,234.195 62.127 L 229.258 62.083 229.094 61.616 C
   228.843 60.904,229.130 60.593,230.096 60.531 C
   230.543 60.502,230.998 60.404,231.107 60.313 C
   231.800 59.738,231.275 27.672,230.565 27.198 C
   230.470 27.135,230.344 26.952,230.285 26.792 C
   230.226 26.631,230.062 26.500,229.922 26.500 C
   229.782 26.500,229.667 26.425,229.667 26.333 C
   229.667 26.242,229.479 26.167,229.250 26.167 C
   229.021 26.167,228.833 26.242,228.833 26.333 C
   228.833 26.425,228.693 26.500,228.521 26.500 C
   228.260 26.500,227.478 27.250,226.625 28.319 C
   226.344 28.669,225.833 29.710,225.833 29.929 C
   225.833 30.035,225.694 30.301,225.524 30.519 C
   225.354 30.738,225.160 31.217,225.093 31.583 C
   225.025 31.950,224.883 32.355,224.777 32.484 C
   224.571 32.733,224.386 58.877,224.583 59.958 C
   224.676 60.469,224.723 60.500,225.387 60.500 C
   226.648 60.500,227.290 61.360,226.442 61.915 C
   226.107 62.135,225.254 62.165,220.401 62.127 L 214.750 62.083 214.750 61.333 L 214.750 60.583 216.250 60.500 L 217.750 60.417 217.798 43.500 C
   217.824 34.196,217.802 26.340,217.749 26.042 L 217.652 25.500 216.248 25.500 C
   214.710 25.500,214.409 25.313,214.619 24.485 C
   214.780 23.848,214.852 23.839,219.528 23.836 C
   225.009 23.833,224.500 23.636,224.500 25.758 C
   224.500 26.726,224.573 27.525,224.667 27.583 C
   224.758 27.640,224.833 27.581,224.833 27.452 C
   224.833 27.323,224.983 27.045,225.167 26.833 C
   225.350 26.622,225.500 26.404,225.501 26.349 C
   225.504 25.981,227.955 23.833,228.372 23.833 C
   228.497 23.833,228.683 23.750,228.785 23.649 C
   229.277 23.156,233.028 23.095,233.749 23.568 M370.286 23.599 C
   370.489 23.728,370.797 23.833,370.972 23.833 C
   371.146 23.833,371.479 23.983,371.712 24.167 C
   371.945 24.350,372.199 24.500,372.276 24.501 C
   372.353 24.502,372.623 24.610,372.876 24.741 C
   373.316 24.971,373.353 24.958,373.792 24.407 C
   374.337 23.724,375.198 23.601,375.498 24.163 C
   375.858 24.835,375.640 34.652,375.260 34.930 C
   374.316 35.620,373.710 34.992,373.480 33.087 C
   373.380 32.264,373.194 31.441,373.066 31.258 C
   372.938 31.075,372.831 30.774,372.828 30.588 C
   372.825 30.402,372.677 29.950,372.500 29.583 C
   372.323 29.217,372.175 28.785,372.172 28.625 C
   372.169 28.465,372.090 28.333,371.996 28.333 C
   371.903 28.333,371.736 28.127,371.625 27.875 C
   371.514 27.623,371.328 27.357,371.212 27.283 C
   371.095 27.210,371.000 27.082,371.000 26.999 C
   371.000 26.783,370.306 26.120,369.825 25.876 C
   369.601 25.762,369.304 25.603,369.167 25.521 C
   367.628 24.611,364.667 25.184,364.667 26.391 C
   364.667 26.482,364.535 26.702,364.375 26.880 C
   364.005 27.288,363.935 31.771,364.292 32.200 C
   364.407 32.339,364.682 32.792,364.903 33.209 C
   365.124 33.625,365.743 34.431,366.278 35.001 C
   366.812 35.570,367.363 36.183,367.500 36.364 C
   367.637 36.544,368.121 37.043,368.573 37.471 C
   370.109 38.924,372.000 40.918,372.000 41.084 C
   372.000 41.136,372.225 41.398,372.500 41.667 C
   372.775 41.935,373.000 42.230,373.000 42.322 C
   373.000 42.413,373.145 42.566,373.322 42.661 C
   373.499 42.755,373.697 43.002,373.761 43.208 C
   373.826 43.415,373.925 43.621,373.981 43.667 C
   374.205 43.847,375.000 45.112,375.000 45.287 C
   375.000 45.389,375.057 45.497,375.126 45.528 C
   375.309 45.608,376.167 47.320,376.167 47.605 C
   376.167 47.737,376.271 48.011,376.399 48.214 C
   376.526 48.417,376.681 48.942,376.743 49.381 C
   376.805 49.820,376.963 50.437,377.095 50.751 C
   377.366 51.401,377.410 54.019,377.167 55.083 C
   377.083 55.450,376.926 56.163,376.817 56.667 C
   376.708 57.171,376.518 57.702,376.393 57.846 C
   376.269 57.991,376.167 58.266,376.167 58.456 C
   376.167 59.092,374.288 61.230,373.554 61.431 C
   373.354 61.485,373.150 61.636,373.101 61.765 C
   373.051 61.894,372.914 62.000,372.797 62.001 C
   372.679 62.002,372.358 62.133,372.083 62.293 C
   371.344 62.723,366.323 62.723,365.583 62.293 C
   365.308 62.133,364.989 62.002,364.873 62.001 C
   364.758 62.000,364.308 61.752,363.873 61.448 C
   363.439 61.144,362.952 60.847,362.792 60.787 C
   362.631 60.726,362.500 60.598,362.500 60.501 C
   362.500 60.404,362.314 60.448,362.083 60.599 C
   361.838 60.760,361.667 61.030,361.667 61.257 C
   361.667 62.039,360.632 62.461,359.946 61.958 C
   359.686 61.767,359.666 61.208,359.706 55.167 L 359.750 48.583 360.471 48.531 C
   361.445 48.461,361.667 48.687,361.667 49.749 C
   361.667 50.230,361.817 51.093,362.000 51.667 C
   362.183 52.241,362.336 52.982,362.339 53.313 C
   362.342 53.645,362.454 54.062,362.589 54.240 C
   362.723 54.418,362.833 54.712,362.833 54.894 C
   362.833 55.279,364.133 57.961,364.500 58.333 C
   364.637 58.473,364.900 58.776,365.083 59.007 C
   365.842 59.963,367.715 60.978,368.750 60.994 C
   369.500 61.006,371.500 59.972,371.500 59.572 C
   371.500 59.501,371.606 59.325,371.736 59.180 C
   372.368 58.473,372.961 55.368,372.516 55.093 C
   372.433 55.042,372.314 54.629,372.251 54.176 C
   372.134 53.324,371.437 51.812,370.736 50.886 C
   370.514 50.594,370.333 50.282,370.333 50.192 C
   370.333 50.102,370.272 50.003,370.197 49.972 C
   370.122 49.942,369.534 49.317,368.890 48.583 C
   368.246 47.850,366.939 46.456,365.985 45.486 C
   365.031 44.515,364.025 43.436,363.750 43.087 C
   363.475 42.739,363.137 42.331,363.000 42.182 C
   362.604 41.752,361.860 40.608,361.419 39.750 C
   361.277 39.475,361.094 39.137,361.011 39.000 C
   360.585 38.295,360.167 37.281,360.166 36.953 C
   360.165 36.750,360.042 36.358,359.893 36.083 C
   359.469 35.304,359.471 30.611,359.895 29.250 C
   360.601 26.985,362.725 24.190,363.963 23.896 C
   364.213 23.837,364.530 23.691,364.668 23.572 C
   365.148 23.157,369.623 23.179,370.286 23.599 M348.393 23.583 C
   348.530 23.721,348.761 23.833,348.905 23.833 C
   349.049 23.833,349.317 23.983,349.500 24.167 C
   349.683 24.350,349.879 24.500,349.935 24.500 C
   350.099 24.500,351.500 25.969,351.500 26.141 C
   351.500 26.227,351.594 26.335,351.708 26.381 C
   352.151 26.557,353.569 29.862,353.833 31.333 C
   353.908 31.746,354.085 32.383,354.227 32.750 C
   354.369 33.117,354.489 33.850,354.493 34.380 C
   354.497 34.910,354.608 35.603,354.740 35.918 C
   354.993 36.523,355.164 43.599,354.935 43.970 C
   354.861 44.091,352.858 44.152,348.715 44.160 C
   345.354 44.167,342.563 44.215,342.511 44.268 C
   342.245 44.535,342.423 55.621,342.703 56.183 C
   342.858 56.495,343.029 57.031,343.083 57.375 C
   343.137 57.719,343.248 58.000,343.330 58.000 C
   343.411 58.000,343.527 58.155,343.587 58.344 C
   343.894 59.313,344.986 60.365,345.929 60.601 C
   346.153 60.658,346.378 60.770,346.428 60.852 C
   346.547 61.044,347.619 61.045,347.738 60.852 C
   347.789 60.771,348.079 60.658,348.384 60.601 C
   348.987 60.487,350.250 59.361,350.436 58.770 C
   350.498 58.576,350.687 58.319,350.857 58.199 C
   351.027 58.079,351.167 57.852,351.167 57.694 C
   351.167 57.537,351.270 57.260,351.395 57.079 C
   351.521 56.898,351.679 56.487,351.745 56.167 C
   351.812 55.846,351.960 55.433,352.074 55.250 C
   352.188 55.067,352.326 54.480,352.380 53.947 C
   352.434 53.413,352.564 52.874,352.669 52.748 C
   352.773 52.622,352.902 51.799,352.954 50.920 C
   353.007 50.041,353.125 49.276,353.218 49.218 C
   353.433 49.086,354.826 49.104,354.963 49.241 C
   355.154 49.432,354.981 52.005,354.738 52.585 C
   354.607 52.899,354.498 53.477,354.496 53.870 C
   354.494 54.262,354.344 54.910,354.163 55.310 C
   353.982 55.709,353.833 56.192,353.833 56.382 C
   353.833 56.703,353.346 57.923,352.988 58.500 C
   352.902 58.637,352.759 58.921,352.669 59.129 C
   352.489 59.545,352.367 59.701,351.499 60.627 C
   350.905 61.261,349.954 61.995,349.715 62.006 C
   349.643 62.009,349.283 62.156,348.917 62.333 C
   347.459 63.037,343.585 62.747,342.167 61.826 C
   342.029 61.737,341.729 61.571,341.500 61.457 C
   340.738 61.078,338.500 58.644,338.500 58.194 C
   338.500 58.110,338.392 57.951,338.261 57.842 C
   338.129 57.733,337.974 57.429,337.917 57.167 C
   337.859 56.904,337.704 56.600,337.573 56.491 C
   337.441 56.382,337.333 56.151,337.333 55.979 C
   337.333 55.807,337.183 55.386,337.000 55.044 C
   336.817 54.701,336.667 54.192,336.667 53.911 C
   336.667 53.630,336.580 53.313,336.473 53.206 C
   336.367 53.100,336.186 52.391,336.072 51.631 C
   335.957 50.872,335.782 49.950,335.682 49.583 C
   335.315 48.238,335.431 36.748,335.826 35.237 C
   336.013 34.522,336.169 33.633,336.172 33.260 C
   336.175 32.888,336.287 32.438,336.422 32.260 C
   336.557 32.082,336.667 31.743,336.667 31.507 C
   336.667 31.271,336.817 30.799,337.000 30.456 C
   337.183 30.114,337.333 29.729,337.333 29.599 C
   337.333 29.090,338.952 26.397,339.745 25.586 C
   340.266 25.054,342.058 23.838,342.333 23.830 C
   342.471 23.826,342.729 23.713,342.907 23.578 C
   343.382 23.219,348.033 23.223,348.393 23.583 M265.787 23.968 C
   265.945 24.068,266.001 28.168,266.037 42.257 L 266.083 60.417 267.492 60.466 C
   269.273 60.528,269.888 61.088,269.071 61.905 C
   268.791 62.186,256.809 62.313,256.401 62.039 C
   255.381 61.355,256.141 60.518,257.833 60.463 L 259.250 60.417 259.293 43.583 C
   259.317 34.325,259.291 26.469,259.237 26.125 L 259.137 25.500 257.916 25.500 C
   257.244 25.500,256.575 25.454,256.430 25.399 C
   256.108 25.275,256.062 24.070,256.375 23.944 C
   256.719 23.805,265.567 23.828,265.787 23.968 M309.803 23.978 C
   309.941 24.065,310.003 24.360,309.970 24.767 L 309.917 25.417 308.458 25.465 L 307.000 25.513 307.000 26.096 C
   307.000 26.416,307.104 26.845,307.230 27.048 C
   307.357 27.251,307.504 27.818,307.558 28.309 C
   307.611 28.800,307.728 29.290,307.817 29.397 C
   307.906 29.504,308.028 30.002,308.088 30.504 C
   308.148 31.006,308.298 31.643,308.421 31.919 C
   308.544 32.195,308.699 32.827,308.764 33.324 C
   308.830 33.820,308.985 34.419,309.108 34.655 C
   309.231 34.891,309.332 35.324,309.332 35.619 C
   309.333 35.913,309.483 36.535,309.667 37.000 C
   309.850 37.465,310.000 38.146,310.000 38.512 C
   310.000 38.879,310.104 39.345,310.230 39.548 C
   310.357 39.751,310.501 40.292,310.551 40.750 C
   310.601 41.208,310.760 41.808,310.903 42.083 C
   311.047 42.358,311.165 42.868,311.166 43.217 C
   311.166 43.565,311.274 44.057,311.405 44.311 C
   311.536 44.564,311.695 45.229,311.758 45.788 C
   311.940 47.397,312.667 47.385,312.667 45.773 C
   312.667 45.389,312.770 44.926,312.896 44.745 C
   313.022 44.565,313.181 44.004,313.249 43.500 C
   313.317 42.996,313.476 42.381,313.603 42.133 C
   313.730 41.886,313.834 41.398,313.834 41.050 C
   313.835 40.702,313.950 40.198,314.089 39.930 C
   314.228 39.663,314.411 38.964,314.494 38.377 C
   314.577 37.791,314.714 37.222,314.800 37.114 C
   314.885 37.005,315.004 36.477,315.064 35.940 C
   315.125 35.402,315.285 34.804,315.420 34.611 C
   315.556 34.417,315.667 33.957,315.668 33.588 C
   315.668 33.219,315.771 32.721,315.896 32.482 C
   316.020 32.243,316.177 31.611,316.244 31.077 C
   316.310 30.544,316.461 29.952,316.580 29.762 C
   316.698 29.572,316.836 28.980,316.886 28.447 C
   316.937 27.913,317.061 27.378,317.162 27.256 C
   317.262 27.135,317.392 26.634,317.449 26.143 C
   317.730 23.730,319.309 22.944,319.576 25.083 C
   319.641 25.601,319.800 26.279,319.930 26.590 C
   320.060 26.901,320.167 27.365,320.168 27.620 C
   320.168 27.875,320.279 28.294,320.413 28.552 C
   320.548 28.810,320.704 29.410,320.761 29.886 C
   320.817 30.361,320.961 30.975,321.079 31.250 C
   321.198 31.525,321.346 32.107,321.408 32.543 C
   321.470 32.978,321.628 33.541,321.759 33.793 C
   321.891 34.044,321.998 34.557,321.999 34.933 C
   322.000 35.308,322.105 35.833,322.234 36.099 C
   322.363 36.366,322.522 36.996,322.586 37.500 C
   322.651 38.004,322.777 38.507,322.865 38.616 C
   322.954 38.726,323.089 39.214,323.165 39.700 C
   323.241 40.186,323.419 40.883,323.561 41.250 C
   323.703 41.617,323.824 42.217,323.831 42.583 C
   323.838 42.950,323.954 43.396,324.089 43.574 C
   324.223 43.751,324.333 44.142,324.333 44.442 C
   324.333 44.742,324.483 45.368,324.667 45.833 C
   324.850 46.298,325.005 46.957,325.012 47.298 C
   325.027 48.106,325.304 47.538,325.322 46.662 C
   325.328 46.339,325.435 45.926,325.559 45.745 C
   325.683 45.565,325.827 45.004,325.878 44.500 C
   325.929 43.996,326.090 43.358,326.235 43.083 C
   326.379 42.808,326.498 42.244,326.499 41.829 C
   326.500 41.414,326.602 40.926,326.727 40.745 C
   326.853 40.565,327.010 39.967,327.078 39.417 C
   327.146 38.867,327.306 38.214,327.434 37.967 C
   327.562 37.719,327.667 37.209,327.667 36.833 C
   327.667 36.458,327.783 35.925,327.926 35.649 C
   328.068 35.374,328.227 34.759,328.279 34.283 C
   328.331 33.806,328.465 33.267,328.577 33.083 C
   328.690 32.900,328.824 32.327,328.877 31.810 C
   328.929 31.294,329.091 30.641,329.236 30.360 C
   329.652 29.555,329.606 27.087,329.167 26.711 C
   328.983 26.554,328.833 26.349,328.833 26.255 C
   328.832 25.987,327.485 25.500,326.744 25.500 C
   325.878 25.500,325.333 25.131,325.453 24.626 C
   325.636 23.855,325.783 23.833,330.810 23.833 C
   335.862 23.833,336.167 23.881,336.167 24.667 C
   336.167 25.139,334.823 25.992,334.058 26.005 C
   333.714 26.011,332.833 26.721,332.833 26.993 C
   332.833 27.089,332.683 27.448,332.500 27.790 C
   332.317 28.132,332.166 28.563,332.166 28.748 C
   332.165 28.932,332.055 29.294,331.920 29.552 C
   331.785 29.810,331.626 30.410,331.565 30.886 C
   331.505 31.361,331.384 31.841,331.296 31.952 C
   331.208 32.064,331.074 32.655,330.998 33.268 C
   330.922 33.880,330.775 34.433,330.671 34.498 C
   330.566 34.562,330.436 35.058,330.381 35.599 C
   330.326 36.140,330.190 36.733,330.077 36.917 C
   329.965 37.100,329.825 37.690,329.766 38.227 C
   329.707 38.764,329.549 39.362,329.413 39.556 C
   329.278 39.749,329.167 40.249,329.167 40.667 C
   329.167 41.084,329.062 41.575,328.934 41.758 C
   328.806 41.941,328.648 42.593,328.582 43.207 C
   328.516 43.821,328.358 44.410,328.231 44.516 C
   328.104 44.621,328.000 44.982,328.000 45.318 C
   328.000 45.654,327.850 46.355,327.667 46.877 C
   327.483 47.398,327.331 48.071,327.328 48.371 C
   327.325 48.671,327.213 49.062,327.078 49.240 C
   326.943 49.418,326.833 49.814,326.833 50.119 C
   326.833 50.425,326.683 51.102,326.500 51.623 C
   326.317 52.145,326.167 52.839,326.167 53.165 C
   326.167 53.492,326.062 53.909,325.933 54.092 C
   325.805 54.276,325.645 54.873,325.577 55.421 C
   325.510 55.969,325.353 56.565,325.227 56.745 C
   325.102 56.926,325.000 57.414,324.999 57.829 C
   324.998 58.244,324.891 58.789,324.759 59.041 C
   324.628 59.292,324.469 59.855,324.406 60.291 C
   324.343 60.727,324.194 61.271,324.076 61.500 C
   323.957 61.729,323.818 62.050,323.767 62.212 C
   323.524 62.987,322.333 62.666,322.333 61.826 C
   322.333 61.543,322.189 60.960,322.012 60.531 C
   321.836 60.101,321.644 59.395,321.586 58.962 C
   321.528 58.529,321.419 58.136,321.343 58.089 C
   321.268 58.043,321.152 57.572,321.086 57.044 C
   321.020 56.516,320.861 55.881,320.733 55.633 C
   320.605 55.386,320.500 54.990,320.500 54.754 C
   320.500 54.518,320.350 53.898,320.167 53.377 C
   319.983 52.855,319.833 52.216,319.833 51.956 C
   319.833 51.696,319.728 51.280,319.599 51.031 C
   319.471 50.782,319.311 50.213,319.244 49.765 C
   319.176 49.317,319.020 48.756,318.895 48.517 C
   318.771 48.279,318.668 47.821,318.667 47.500 C
   318.666 47.179,318.547 46.692,318.403 46.417 C
   318.260 46.142,318.098 45.545,318.044 45.092 C
   317.990 44.638,317.846 44.075,317.724 43.842 C
   317.602 43.608,317.501 43.154,317.500 42.833 C
   317.499 42.512,317.380 42.025,317.237 41.750 C
   317.093 41.475,316.933 40.896,316.882 40.463 C
   316.786 39.661,316.500 39.514,316.500 40.266 C
   316.500 40.493,316.396 40.845,316.270 41.048 C
   316.143 41.251,315.999 41.792,315.949 42.250 C
   315.899 42.708,315.740 43.308,315.597 43.583 C
   315.453 43.858,315.335 44.385,315.334 44.755 C
   315.334 45.124,315.228 45.576,315.100 45.759 C
   314.972 45.942,314.812 46.540,314.744 47.088 C
   314.677 47.635,314.519 48.231,314.394 48.412 C
   314.269 48.593,314.167 49.021,314.167 49.364 C
   314.167 49.707,314.017 50.368,313.833 50.833 C
   313.650 51.298,313.500 51.967,313.500 52.319 C
   313.500 52.685,313.393 53.048,313.250 53.167 C
   313.106 53.286,313.000 53.650,313.000 54.025 C
   313.000 54.382,312.850 55.102,312.667 55.623 C
   312.483 56.145,312.333 56.784,312.333 57.044 C
   312.333 57.304,312.225 57.726,312.093 57.982 C
   311.960 58.238,311.801 58.853,311.740 59.349 C
   311.679 59.845,311.524 60.416,311.398 60.619 C
   311.271 60.822,311.167 61.288,311.167 61.655 C
   311.167 62.362,310.868 62.667,310.175 62.667 C
   309.733 62.667,309.000 61.138,309.000 60.214 C
   309.000 59.862,308.898 59.426,308.774 59.245 C
   308.650 59.065,308.498 58.528,308.436 58.052 C
   308.375 57.577,308.215 56.977,308.080 56.719 C
   307.945 56.461,307.834 55.987,307.833 55.667 C
   307.832 55.346,307.721 54.872,307.587 54.614 C
   307.452 54.356,307.291 53.756,307.229 53.281 C
   307.167 52.806,307.024 52.267,306.911 52.083 C
   306.798 51.900,306.663 51.348,306.609 50.857 C
   306.555 50.366,306.431 49.868,306.333 49.750 C
   306.235 49.632,306.110 49.134,306.054 48.643 C
   305.999 48.152,305.859 47.562,305.743 47.331 C
   305.627 47.101,305.478 46.538,305.412 46.081 C
   305.346 45.624,305.226 45.167,305.146 45.067 C
   305.066 44.966,304.957 44.516,304.903 44.067 C
   304.849 43.617,304.662 42.899,304.486 42.469 C
   304.310 42.040,304.164 41.402,304.161 41.052 C
   304.158 40.703,304.046 40.271,303.911 40.093 C
   303.777 39.915,303.667 39.557,303.667 39.297 C
   303.667 39.038,303.517 38.398,303.333 37.877 C
   303.150 37.355,303.000 36.679,303.000 36.375 C
   303.000 36.071,302.885 35.646,302.745 35.432 C
   302.604 35.218,302.442 34.643,302.384 34.154 C
   302.326 33.666,302.179 33.075,302.057 32.842 C
   301.935 32.608,301.835 32.181,301.834 31.893 C
   301.834 31.605,301.685 31.043,301.504 30.643 C
   301.322 30.244,301.172 29.567,301.170 29.139 C
   301.168 28.711,301.090 28.336,300.997 28.305 C
   300.904 28.274,300.750 27.763,300.654 27.169 C
   300.428 25.762,300.148 25.500,298.874 25.500 C
   297.586 25.500,297.190 25.057,297.673 24.156 C
   297.858 23.809,309.274 23.642,309.803 23.978 M344.892 25.215 C
   344.054 25.641,343.171 26.913,342.971 27.981 C
   342.894 28.394,342.738 28.789,342.624 28.860 C
   342.410 28.992,342.240 41.028,342.437 42.042 L 342.526 42.500 345.333 42.500 L 348.140 42.500 348.238 42.042 C
   348.538 40.628,348.165 27.167,347.826 27.167 C
   347.753 27.167,347.641 26.923,347.577 26.625 C
   347.438 25.975,346.039 24.702,345.626 24.848 C
   345.602 24.857,345.272 25.021,344.892 25.215 M282.209 25.236 C
   281.583 25.464,280.539 26.539,280.397 27.101 C
   280.344 27.313,280.195 27.574,280.067 27.680 C
   279.935 27.790,279.833 28.176,279.833 28.567 C
   279.833 28.958,279.707 29.438,279.542 29.671 C
   279.270 30.055,279.250 30.967,279.250 43.000 C
   279.250 55.033,279.270 55.945,279.542 56.329 C
   279.702 56.555,279.833 57.019,279.833 57.359 C
   279.833 57.698,279.942 58.085,280.075 58.218 C
   280.208 58.351,280.363 58.645,280.420 58.872 C
   280.872 60.670,283.518 61.479,284.799 60.212 C
   285.010 60.004,285.235 59.833,285.299 59.832 C
   285.424 59.831,285.978 58.973,286.211 58.421 C
   286.288 58.241,286.436 58.039,286.542 57.974 C
   287.193 57.572,287.172 28.085,286.520 27.868 C
   286.413 27.832,286.225 27.566,286.102 27.276 C
   285.405 25.633,283.650 24.713,282.209 25.236 M93.500 45.649 L 93.500 59.827 93.933 59.936 C
   94.810 60.156,99.950 59.887,100.361 59.600 C
   100.570 59.453,100.878 59.333,101.046 59.333 C
   101.381 59.333,102.560 58.728,102.992 58.333 C
   103.143 58.196,103.528 57.858,103.847 57.583 C
   105.169 56.446,107.000 53.525,107.000 52.553 C
   107.000 52.334,107.104 51.989,107.230 51.786 C
   107.357 51.583,107.507 50.763,107.564 49.964 C
   107.621 49.165,107.752 48.118,107.855 47.637 C
   107.994 46.991,107.994 46.411,107.858 45.422 C
   107.756 44.686,107.623 43.296,107.564 42.333 C
   107.499 41.296,107.362 40.449,107.227 40.255 C
   107.102 40.074,107.000 39.673,107.000 39.364 C
   107.000 39.055,106.850 38.612,106.667 38.379 C
   106.483 38.146,106.333 37.820,106.333 37.654 C
   106.333 37.488,106.257 37.306,106.164 37.248 C
   106.071 37.191,105.890 36.892,105.761 36.584 C
   105.502 35.963,103.491 33.906,102.987 33.746 C
   102.811 33.690,102.667 33.582,102.667 33.507 C
   102.667 33.431,102.484 33.324,102.262 33.268 C
   102.039 33.212,101.753 33.063,101.627 32.937 C
   101.501 32.811,101.065 32.627,100.657 32.529 C
   100.250 32.431,99.617 32.276,99.250 32.184 C
   98.533 32.005,96.017 31.670,94.458 31.546 L 93.500 31.470 93.500 45.649 M135.601 31.764 C
   135.351 32.417,135.493 59.620,135.748 59.831 C
   136.131 60.149,141.967 59.969,142.512 59.623 C
   142.735 59.482,143.155 59.316,143.447 59.256 C
   143.738 59.195,144.066 59.038,144.176 58.906 C
   144.285 58.774,144.442 58.667,144.525 58.667 C
   145.290 58.667,148.500 55.019,148.500 54.149 C
   148.500 53.998,148.603 53.789,148.729 53.684 C
   148.855 53.579,149.009 53.176,149.071 52.789 C
   149.133 52.401,149.280 51.933,149.399 51.750 C
   150.043 50.754,150.035 41.767,149.389 40.417 C
   149.257 40.142,149.106 39.599,149.052 39.210 C
   148.999 38.822,148.853 38.419,148.728 38.315 C
   148.602 38.211,148.500 38.002,148.500 37.851 C
   148.500 36.388,144.064 32.500,142.396 32.500 C
   142.215 32.500,141.981 32.414,141.876 32.309 C
   141.771 32.205,140.939 32.056,140.027 31.979 C
   139.115 31.902,138.168 31.762,137.923 31.669 C
   137.198 31.393,135.720 31.453,135.601 31.764 " stroke="none" fill="#fbfbfb" fill-rule="evenodd"></path><path id="path2" d="M26.417 2.087 C
   25.958 2.205,25.119 2.309,24.553 2.317 C
   23.986 2.326,23.311 2.417,23.053 2.518 C
   22.794 2.620,22.208 2.800,21.750 2.918 C
   20.735 3.179,19.969 3.447,19.065 3.856 C
   18.688 4.027,18.302 4.167,18.206 4.167 C
   18.110 4.167,17.568 4.429,17.000 4.750 C
   16.432 5.071,15.834 5.333,15.671 5.333 C
   15.508 5.333,15.281 5.446,15.167 5.583 C
   15.053 5.721,14.857 5.833,14.731 5.833 C
   14.606 5.833,14.409 5.983,14.295 6.167 C
   14.170 6.367,13.906 6.500,13.635 6.500 C
   13.386 6.500,13.123 6.594,13.050 6.709 C
   12.977 6.824,12.585 7.142,12.179 7.417 C
   10.989 8.223,6.500 12.443,6.500 12.755 C
   6.500 12.818,6.200 13.229,5.833 13.667 C
   5.467 14.105,5.167 14.540,5.167 14.634 C
   5.167 14.728,5.110 14.831,5.042 14.861 C
   4.842 14.950,4.000 16.248,4.000 16.467 C
   4.000 16.577,3.931 16.667,3.846 16.667 C
   3.762 16.667,3.606 16.929,3.500 17.250 C
   3.394 17.571,3.242 17.833,3.161 17.833 C
   3.081 17.833,2.966 18.093,2.907 18.411 C
   2.847 18.728,2.694 19.075,2.566 19.181 C
   2.438 19.287,2.333 19.496,2.332 19.645 C
   2.332 19.795,2.182 20.142,2.000 20.417 C
   1.818 20.692,1.668 21.067,1.667 21.250 C
   1.666 21.433,1.552 21.800,1.414 22.064 C
   1.276 22.328,1.115 22.853,1.056 23.230 C
   0.996 23.608,0.854 24.067,0.740 24.250 C
   0.625 24.433,0.479 25.127,0.415 25.792 C
   0.352 26.456,0.232 27.000,0.150 27.000 C
   0.067 27.000,0.000 27.150,0.000 27.333 C
   0.000 27.517,0.074 27.667,0.163 27.667 C
   0.342 27.667,0.424 27.383,0.645 26.000 C
   0.846 24.737,1.144 23.570,1.517 22.580 C
   1.691 22.120,1.833 21.661,1.833 21.560 C
   1.833 20.811,4.879 15.061,5.661 14.333 C
   5.711 14.287,5.955 13.949,6.204 13.582 C
   7.865 11.130,13.348 6.371,13.889 6.912 C
   14.101 7.125,14.157 7.092,14.338 6.655 C
   14.630 5.951,15.987 5.310,16.245 5.755 C
   16.339 5.918,16.548 6.169,16.708 6.312 C
   16.869 6.456,17.000 6.707,17.000 6.870 C
   17.000 7.033,17.070 7.167,17.155 7.167 C
   17.241 7.167,17.358 7.316,17.416 7.499 C
   17.474 7.682,17.614 7.926,17.726 8.041 C
   17.838 8.156,18.183 8.644,18.493 9.125 C
   18.803 9.606,19.119 10.000,19.195 10.000 C
   19.271 10.000,19.333 10.133,19.333 10.296 C
   19.333 10.459,19.465 10.714,19.625 10.863 C
   20.110 11.312,20.667 12.018,20.667 12.184 C
   20.667 12.270,21.042 12.719,21.500 13.183 C
   21.958 13.646,22.333 14.064,22.333 14.111 C
   22.333 14.193,22.758 14.649,24.949 16.920 C
   25.481 17.473,26.404 18.356,27.000 18.883 C
   27.596 19.411,28.202 19.953,28.347 20.088 C
   28.493 20.223,28.699 20.333,28.806 20.333 C
   28.913 20.333,29.000 20.446,29.000 20.583 C
   29.000 20.721,29.061 20.833,29.136 20.833 C
   29.211 20.833,29.568 21.098,29.928 21.421 C
   30.288 21.744,30.752 22.053,30.958 22.106 C
   31.165 22.159,31.333 22.307,31.333 22.435 C
   31.333 22.562,31.480 22.667,31.658 22.667 C
   31.837 22.667,32.043 22.774,32.117 22.904 C
   32.265 23.168,33.434 24.000,33.656 24.000 C
   33.734 24.000,33.838 24.105,33.888 24.234 C
   33.937 24.363,34.201 24.518,34.475 24.578 C
   34.748 24.638,35.014 24.795,35.064 24.927 C
   35.115 25.059,35.292 25.167,35.458 25.167 C
   35.624 25.167,35.888 25.317,36.045 25.500 C
   36.202 25.683,36.449 25.833,36.594 25.833 C
   36.739 25.833,36.970 25.946,37.107 26.083 C
   37.245 26.221,37.498 26.333,37.670 26.333 C
   37.843 26.333,38.053 26.457,38.138 26.609 C
   38.233 26.780,38.399 26.844,38.571 26.778 C
   38.736 26.714,38.809 26.738,38.749 26.835 C
   38.693 26.926,38.792 27.000,38.970 27.000 C
   39.147 27.000,39.378 27.103,39.483 27.230 C
   39.588 27.356,39.902 27.505,40.180 27.561 C
   40.458 27.617,40.800 27.776,40.938 27.914 C
   41.077 28.053,41.412 28.167,41.684 28.167 C
   41.959 28.167,42.218 28.273,42.269 28.406 C
   42.390 28.721,43.241 28.902,43.615 28.692 C
   43.876 28.546,43.853 28.505,43.413 28.328 C
   43.140 28.219,42.718 28.025,42.474 27.898 C
   42.231 27.771,41.846 27.667,41.620 27.667 C
   41.393 27.667,41.114 27.553,40.999 27.415 C
   40.884 27.277,40.504 27.118,40.155 27.062 C
   39.806 27.006,39.485 26.867,39.440 26.752 C
   39.396 26.637,39.158 26.492,38.910 26.430 C
   38.662 26.368,38.351 26.208,38.218 26.075 C
   38.085 25.942,37.794 25.833,37.571 25.833 C
   37.349 25.833,37.167 25.755,37.167 25.658 C
   37.167 25.562,36.904 25.373,36.583 25.239 C
   36.262 25.105,36.000 24.922,36.000 24.831 C
   36.000 24.741,35.861 24.667,35.692 24.667 C
   35.523 24.667,35.211 24.517,35.000 24.333 C
   34.789 24.150,34.515 24.000,34.391 24.000 C
   34.268 24.000,33.998 23.847,33.792 23.659 C
   33.585 23.471,33.298 23.209,33.153 23.076 C
   33.007 22.942,32.764 22.833,32.611 22.833 C
   32.458 22.833,32.333 22.757,32.333 22.663 C
   32.333 22.569,32.127 22.401,31.875 22.289 C
   31.623 22.177,31.183 21.841,30.897 21.542 C
   30.611 21.244,30.299 21.000,30.203 21.000 C
   30.106 21.000,30.003 20.944,29.972 20.875 C
   29.942 20.806,29.617 20.529,29.250 20.258 C
   27.699 19.113,22.110 13.467,21.598 12.528 C
   21.515 12.375,21.234 12.043,20.973 11.790 C
   20.713 11.537,20.500 11.281,20.500 11.220 C
   20.500 11.159,20.375 10.972,20.223 10.804 C
   19.923 10.472,19.613 9.967,19.345 9.375 C
   19.251 9.169,19.094 9.000,18.995 9.000 C
   18.897 9.000,18.738 8.775,18.642 8.500 C
   18.546 8.225,18.398 8.000,18.313 8.000 C
   18.228 8.000,18.087 7.813,18.000 7.583 C
   17.913 7.354,17.771 7.167,17.684 7.167 C
   17.597 7.167,17.477 6.943,17.417 6.669 C
   17.357 6.395,17.238 6.128,17.154 6.075 C
   16.921 5.931,16.968 5.000,17.208 4.997 C
   17.323 4.996,17.942 4.733,18.583 4.414 C
   19.225 4.095,19.900 3.831,20.083 3.828 C
   20.267 3.825,20.717 3.677,21.083 3.500 C
   21.450 3.323,21.938 3.172,22.167 3.165 C
   22.396 3.158,22.883 3.043,23.250 2.910 C
   24.039 2.623,29.784 2.117,30.748 2.250 C
   31.116 2.301,32.525 2.409,33.881 2.489 C
   35.236 2.569,36.439 2.714,36.555 2.810 C
   36.671 2.906,37.175 3.057,37.675 3.145 C
   38.593 3.307,39.646 3.646,40.609 4.091 C
   40.897 4.224,41.214 4.333,41.312 4.333 C
   41.478 4.333,42.603 4.842,43.167 5.173 C
   43.304 5.254,43.867 5.551,44.417 5.833 C
   46.301 6.801,46.187 6.725,48.467 8.524 C
   50.814 10.375,54.667 14.484,54.667 15.136 C
   54.667 15.216,54.817 15.417,55.000 15.583 C
   55.183 15.749,55.333 15.956,55.333 16.043 C
   55.333 16.130,55.558 16.534,55.832 16.940 C
   56.106 17.346,56.378 17.869,56.436 18.103 C
   56.495 18.336,56.646 18.566,56.771 18.615 C
   56.897 18.663,57.000 18.807,57.000 18.935 C
   57.000 19.063,57.150 19.448,57.333 19.790 C
   57.517 20.132,57.667 20.538,57.667 20.692 C
   57.667 20.847,57.772 21.185,57.901 21.445 C
   58.367 22.383,58.833 23.766,58.833 24.208 C
   58.833 24.457,58.965 24.980,59.125 25.372 C
   59.359 25.943,59.417 26.587,59.417 28.640 L 59.417 31.197 56.889 31.223 C
   54.534 31.248,53.945 31.334,54.223 31.612 C
   54.284 31.673,55.478 31.749,56.876 31.781 L 59.417 31.838 59.602 32.294 C
   59.867 32.944,59.617 37.954,59.287 38.603 C
   59.153 38.867,58.992 39.438,58.929 39.871 C
   58.866 40.304,58.752 40.697,58.676 40.744 C
   58.600 40.791,58.487 41.101,58.425 41.433 C
   58.362 41.765,58.155 42.384,57.964 42.810 C
   57.356 44.166,55.808 47.242,55.615 47.476 C
   55.424 47.709,55.162 48.139,54.816 48.785 C
   54.626 49.141,53.836 49.395,52.417 49.559 C
   52.095 49.596,52.192 49.626,52.688 49.642 C
   53.795 49.678,54.002 49.998,53.415 50.767 C
   52.532 51.925,49.738 54.621,48.431 55.578 C
   46.882 56.711,44.391 58.335,44.210 58.330 C
   44.140 58.328,43.558 58.588,42.917 58.908 C
   42.275 59.227,41.300 59.650,40.750 59.847 C
   40.200 60.044,39.504 60.309,39.203 60.436 C
   38.902 60.563,38.468 60.667,38.239 60.667 C
   38.010 60.667,37.544 60.786,37.203 60.933 C
   36.862 61.079,35.983 61.264,35.250 61.343 C
   34.517 61.423,33.467 61.559,32.917 61.646 C
   31.410 61.886,28.252 61.852,26.750 61.581 C
   26.017 61.448,25.142 61.338,24.807 61.337 C
   24.471 61.335,23.721 61.184,23.140 61.002 C
   22.559 60.820,21.912 60.670,21.702 60.669 C
   21.493 60.668,21.155 60.562,20.952 60.433 C
   20.749 60.305,20.321 60.145,20.000 60.078 C
   19.679 60.012,19.269 59.854,19.088 59.729 C
   18.907 59.603,18.642 59.500,18.500 59.500 C
   17.916 59.500,14.533 57.586,12.166 55.918 C
   9.191 53.819,5.388 49.482,3.833 46.412 C
   3.604 45.959,3.323 45.489,3.208 45.366 C
   3.094 45.243,3.000 45.054,3.000 44.946 C
   3.001 44.770,2.688 44.062,2.056 42.808 C
   1.934 42.564,1.831 42.227,1.828 42.058 C
   1.825 41.888,1.677 41.450,1.500 41.083 C
   1.323 40.717,1.175 40.207,1.172 39.950 C
   1.169 39.693,1.064 39.281,0.938 39.033 C
   0.812 38.786,0.658 38.133,0.595 37.583 C
   0.422 36.077,0.000 35.249,0.000 36.417 C
   0.000 36.646,0.066 36.833,0.147 36.833 C
   0.228 36.833,0.348 37.340,0.413 37.958 C
   0.478 38.577,0.627 39.196,0.743 39.333 C
   0.860 39.471,1.003 39.953,1.062 40.404 C
   1.120 40.856,1.280 41.350,1.418 41.501 C
   1.555 41.652,1.667 41.977,1.667 42.222 C
   1.667 42.467,1.817 42.948,2.000 43.290 C
   2.183 43.632,2.333 43.997,2.333 44.102 C
   2.333 44.207,2.446 44.386,2.583 44.500 C
   2.721 44.614,2.833 44.838,2.833 44.998 C
   2.833 45.158,2.983 45.479,3.167 45.712 C
   3.350 45.945,3.500 46.219,3.500 46.320 C
   3.500 46.421,3.763 46.877,4.083 47.333 C
   4.404 47.790,4.667 48.224,4.667 48.298 C
   4.667 48.426,4.819 48.646,5.597 49.640 C
   5.788 49.884,6.085 50.327,6.258 50.625 C
   6.431 50.923,6.668 51.167,6.786 51.167 C
   6.904 51.167,7.000 51.243,7.000 51.337 C
   7.000 51.622,10.171 54.701,11.500 55.706 C
   12.188 56.226,12.864 56.748,13.002 56.867 C
   13.141 56.986,13.441 57.177,13.669 57.290 C
   14.158 57.535,14.597 57.808,15.146 58.208 C
   15.365 58.369,15.677 58.500,15.838 58.500 C
   15.999 58.500,16.174 58.613,16.227 58.750 C
   16.280 58.887,16.456 59.000,16.620 59.001 C
   16.783 59.002,17.142 59.151,17.417 59.333 C
   17.692 59.515,18.076 59.665,18.271 59.666 C
   18.466 59.666,18.711 59.770,18.816 59.896 C
   18.921 60.022,19.314 60.174,19.691 60.235 C
   20.067 60.295,20.533 60.454,20.725 60.589 C
   20.917 60.723,21.247 60.833,21.459 60.833 C
   21.671 60.833,22.122 60.950,22.462 61.092 C
   22.802 61.234,23.644 61.415,24.332 61.493 C
   25.020 61.572,25.883 61.717,26.250 61.814 C
   27.151 62.055,33.340 62.055,33.947 61.815 C
   34.206 61.713,35.022 61.565,35.762 61.487 C
   36.502 61.408,37.327 61.230,37.596 61.090 C
   37.864 60.950,38.308 60.832,38.583 60.829 C
   38.858 60.825,39.383 60.677,39.750 60.500 C
   40.117 60.323,40.529 60.173,40.667 60.167 C
   40.804 60.161,41.062 60.046,41.240 59.911 C
   41.418 59.777,41.685 59.667,41.832 59.667 C
   41.980 59.667,42.797 59.310,43.648 58.875 C
   45.838 57.755,46.073 57.628,46.167 57.515 C
   46.212 57.461,46.587 57.191,47.000 56.917 C
   47.413 56.642,47.788 56.380,47.833 56.333 C
   47.879 56.287,48.254 56.024,48.667 55.750 C
   49.079 55.475,49.922 54.744,50.540 54.125 C
   51.159 53.506,51.740 53.000,51.832 53.000 C
   51.924 53.000,52.000 52.944,52.000 52.875 C
   52.000 52.745,52.791 51.801,53.662 50.889 C
   54.185 50.343,54.321 49.757,53.958 49.610 C
   53.844 49.564,53.954 49.520,54.202 49.513 C
   54.645 49.500,55.500 48.546,55.500 48.065 C
   55.500 47.960,55.616 47.778,55.758 47.660 C
   55.900 47.542,56.206 47.065,56.438 46.598 C
   56.670 46.132,56.962 45.563,57.088 45.333 C
   57.494 44.590,58.000 43.400,58.000 43.185 C
   58.000 43.070,58.112 42.864,58.250 42.726 C
   58.388 42.589,58.503 42.313,58.506 42.113 C
   58.509 41.913,58.656 41.450,58.833 41.083 C
   59.010 40.717,59.158 40.170,59.161 39.869 C
   59.164 39.568,59.272 39.155,59.401 38.952 C
   59.530 38.749,59.683 37.946,59.741 37.167 C
   59.800 36.387,59.928 35.066,60.025 34.230 C
   60.179 32.906,60.166 32.633,59.922 32.118 C
   59.729 31.711,59.689 31.431,59.794 31.221 C
   59.985 30.842,59.725 25.953,59.478 25.250 C
   59.381 24.975,59.203 24.337,59.083 23.833 C
   58.839 22.805,58.591 22.064,58.163 21.083 C
   58.003 20.717,57.820 20.192,57.756 19.918 C
   57.693 19.644,57.590 19.382,57.528 19.335 C
   57.467 19.288,57.046 18.524,56.594 17.636 C
   56.141 16.749,55.486 15.624,55.138 15.136 C
   54.791 14.649,54.244 13.875,53.925 13.417 C
   52.519 11.400,47.069 6.500,46.233 6.500 C
   46.114 6.500,45.957 6.404,45.883 6.287 C
   45.810 6.170,45.038 5.713,44.167 5.272 C
   43.296 4.831,42.471 4.411,42.333 4.338 C
   42.196 4.265,41.633 4.041,41.083 3.839 C
   40.533 3.638,39.821 3.370,39.500 3.245 C
   39.179 3.120,38.579 2.967,38.167 2.907 C
   37.754 2.846,37.321 2.719,37.205 2.625 C
   37.088 2.530,36.286 2.388,35.421 2.309 C
   34.557 2.229,33.653 2.090,33.414 1.999 C
   32.785 1.760,27.427 1.827,26.417 2.087 M175.926 2.589 C
   175.749 2.723,175.410 2.833,175.174 2.833 C
   174.938 2.833,174.465 2.983,174.123 3.167 C
   173.781 3.350,173.417 3.500,173.314 3.500 C
   171.848 3.500,167.044 8.681,167.005 10.304 C
   167.002 10.425,166.887 10.636,166.750 10.774 C
   166.612 10.911,166.500 11.225,166.499 11.470 C
   166.498 11.716,166.382 12.138,166.241 12.409 C
   166.100 12.680,165.944 13.243,165.894 13.659 C
   165.845 14.076,165.698 14.699,165.569 15.045 C
   165.439 15.391,165.343 16.141,165.356 16.712 L 165.378 17.750 165.503 17.000 C
   166.079 13.566,166.382 12.307,166.934 11.058 C
   167.062 10.769,167.167 10.469,167.167 10.390 C
   167.167 9.701,169.244 6.755,170.556 5.585 C
   171.605 4.648,174.629 3.000,175.298 3.000 C
   175.489 3.000,175.856 2.918,176.114 2.817 C
   176.372 2.717,176.921 2.576,177.333 2.504 L 178.083 2.373 177.167 2.358 C
   176.599 2.349,176.127 2.437,175.926 2.589 M182.000 2.504 C
   183.007 2.674,184.116 3.050,184.875 3.480 C
   185.219 3.674,185.767 3.985,186.094 4.170 C
   186.847 4.596,187.559 5.048,187.667 5.168 C
   189.795 7.540,191.617 7.314,191.915 4.643 C
   192.028 3.628,192.333 3.391,193.140 3.689 L 193.500 3.823 193.500 12.572 C
   193.500 21.052,193.490 21.327,193.178 21.494 C
   192.603 21.802,192.015 21.706,191.928 21.292 C
   191.810 20.727,191.365 18.527,191.178 17.583 C
   190.907 16.217,190.536 14.793,190.267 14.083 C
   190.128 13.717,190.011 13.321,190.007 13.205 C
   189.971 12.161,187.793 8.617,186.277 7.137 C
   183.455 4.380,178.181 3.477,174.750 5.163 C
   174.567 5.253,174.229 5.406,174.000 5.504 C
   170.271 7.090,168.061 13.959,170.019 17.880 C
   170.192 18.227,170.333 18.581,170.333 18.667 C
   170.333 19.343,172.648 21.896,174.167 22.896 C
   174.671 23.227,175.158 23.558,175.250 23.630 C
   175.406 23.753,179.147 25.582,180.250 26.074 C
   181.909 26.815,185.291 28.584,186.574 29.382 C
   190.435 31.782,194.030 36.428,194.985 40.250 C
   195.076 40.617,195.227 41.217,195.320 41.583 C
   195.797 43.461,196.018 45.918,195.844 47.417 C
   195.480 50.558,195.365 51.296,195.185 51.632 C
   195.083 51.822,195.000 52.113,195.000 52.278 C
   195.000 52.460,195.131 52.333,195.333 51.956 C
   195.521 51.606,195.667 51.016,195.667 50.605 C
   195.667 50.204,195.779 49.556,195.917 49.167 C
   196.089 48.679,196.167 47.796,196.167 46.333 C
   196.167 44.871,196.089 43.988,195.917 43.500 C
   195.779 43.110,195.667 42.472,195.667 42.081 C
   195.667 41.690,195.518 41.043,195.337 40.643 C
   195.156 40.244,195.003 39.729,194.998 39.500 C
   194.993 39.271,194.879 38.938,194.745 38.760 C
   194.610 38.582,194.500 38.311,194.500 38.157 C
   194.500 38.004,194.350 37.688,194.167 37.455 C
   193.983 37.221,193.833 36.939,193.833 36.827 C
   193.833 36.715,193.580 36.270,193.270 35.839 C
   192.960 35.408,192.657 34.899,192.597 34.709 C
   192.537 34.519,192.378 34.322,192.244 34.271 C
   192.110 34.219,192.000 34.090,192.000 33.982 C
   192.000 33.691,188.139 30.005,187.720 29.897 C
   187.521 29.845,187.317 29.698,187.267 29.568 C
   187.218 29.439,187.099 29.333,187.004 29.333 C
   186.908 29.333,186.470 29.080,186.031 28.771 C
   185.591 28.462,185.048 28.153,184.824 28.084 C
   184.600 28.016,184.334 27.893,184.233 27.813 C
   183.856 27.513,182.474 26.833,182.241 26.833 C
   182.109 26.833,182.000 26.761,182.000 26.673 C
   182.000 26.585,181.700 26.423,181.333 26.314 C
   180.967 26.204,180.667 26.054,180.667 25.981 C
   180.667 25.908,180.407 25.800,180.089 25.740 C
   179.772 25.681,179.425 25.527,179.319 25.399 C
   179.213 25.271,179.041 25.165,178.938 25.163 C
   178.650 25.157,175.972 23.784,175.096 23.192 C
   174.667 22.903,174.284 22.667,174.244 22.667 C
   174.091 22.667,173.387 22.048,172.439 21.083 C
   171.159 19.780,171.120 19.728,170.681 18.750 C
   170.476 18.292,170.182 17.692,170.029 17.417 C
   169.619 16.680,169.638 11.239,170.054 10.605 C
   170.207 10.370,170.333 10.029,170.333 9.847 C
   170.333 9.664,170.522 9.230,170.753 8.882 C
   170.984 8.535,171.265 8.108,171.378 7.935 C
   172.008 6.970,174.066 5.500,174.787 5.500 C
   174.882 5.500,175.053 5.388,175.167 5.250 C
   175.287 5.105,175.662 4.997,176.062 4.993 C
   176.440 4.989,177.050 4.877,177.417 4.745 C
   178.279 4.434,179.865 4.437,180.901 4.752 C
   181.351 4.888,181.914 5.000,182.151 4.999 C
   183.568 4.997,186.863 7.580,188.012 9.594 C
   189.041 11.398,190.000 13.502,190.000 13.958 C
   190.000 14.143,190.112 14.510,190.249 14.773 C
   190.386 15.035,190.498 15.453,190.499 15.702 C
   190.500 15.951,190.650 16.535,190.833 17.000 C
   191.028 17.495,191.167 18.241,191.168 18.798 C
   191.168 19.322,191.281 19.965,191.418 20.227 C
   191.555 20.490,191.667 20.869,191.667 21.069 C
   191.667 21.585,191.979 21.833,192.628 21.833 C
   193.929 21.833,193.833 22.568,193.833 12.584 L 193.833 3.680 193.166 3.480 C
   192.063 3.150,191.667 3.437,191.667 4.566 C
   191.667 6.605,190.353 7.201,188.865 5.839 C
   188.561 5.561,188.274 5.333,188.226 5.333 C
   188.178 5.333,187.827 5.081,187.445 4.773 C
   186.756 4.217,186.177 3.866,185.292 3.467 C
   185.040 3.353,184.833 3.205,184.833 3.138 C
   184.833 3.070,184.552 2.964,184.208 2.901 C
   183.865 2.838,183.435 2.684,183.255 2.560 C
   183.070 2.433,182.557 2.343,182.088 2.356 L 181.250 2.378 182.000 2.504 M80.105 3.046 C
   79.849 3.234,79.815 3.397,79.912 3.970 C
   80.066 4.883,80.112 4.903,82.250 5.000 L 84.083 5.083 84.125 32.279 C
   84.164 56.839,84.142 59.501,83.905 59.738 C
   83.704 59.938,83.282 60.000,82.103 60.000 C
   80.196 60.000,79.298 60.780,79.991 61.833 C
   80.258 62.240,104.835 62.192,105.651 61.784 C
   105.963 61.628,106.488 61.497,106.817 61.493 C
   107.147 61.489,107.717 61.371,108.083 61.231 C
   108.450 61.092,109.005 60.913,109.316 60.834 C
   109.627 60.755,109.927 60.624,109.983 60.542 C
   110.038 60.461,110.444 60.221,110.884 60.011 C
   112.393 59.290,115.000 56.984,115.000 56.371 C
   115.000 56.287,115.143 56.089,115.318 55.930 C
   115.493 55.772,115.682 55.460,115.738 55.238 C
   115.794 55.015,115.913 54.833,116.003 54.833 C
   116.093 54.833,116.167 54.711,116.167 54.561 C
   116.167 54.412,116.308 54.055,116.480 53.770 C
   116.652 53.484,116.846 52.950,116.911 52.583 C
   116.976 52.217,117.102 51.830,117.189 51.724 C
   117.828 50.951,117.880 41.924,117.251 40.913 C
   117.136 40.728,116.989 40.263,116.925 39.881 C
   116.860 39.499,116.701 39.097,116.570 38.989 C
   116.440 38.881,116.333 38.638,116.333 38.450 C
   116.333 38.078,114.886 35.833,114.647 35.833 C
   114.566 35.833,114.500 35.749,114.500 35.647 C
   114.500 35.544,114.144 35.130,113.708 34.726 C
   113.273 34.322,112.683 33.772,112.398 33.505 C
   112.113 33.237,111.682 32.968,111.440 32.908 C
   111.198 32.847,111.000 32.737,111.000 32.663 C
   111.000 32.543,110.350 32.190,109.167 31.666 C
   107.560 30.955,107.432 30.617,108.616 30.208 C
   109.917 29.758,112.405 27.811,113.141 26.667 C
   113.288 26.438,113.504 26.190,113.621 26.117 C
   113.738 26.043,113.833 25.905,113.833 25.810 C
   113.833 25.714,113.983 25.445,114.167 25.212 C
   114.350 24.979,114.500 24.681,114.500 24.549 C
   114.500 24.417,114.612 24.197,114.750 24.060 C
   114.888 23.922,115.000 23.645,115.000 23.444 C
   115.000 23.243,115.150 22.799,115.333 22.456 C
   115.517 22.114,115.670 21.516,115.674 21.126 C
   115.678 20.736,115.789 20.117,115.921 19.750 C
   116.255 18.825,116.251 15.449,115.917 14.500 C
   115.779 14.110,115.667 13.484,115.667 13.107 C
   115.667 12.499,115.213 11.018,114.816 10.333 C
   114.737 10.196,114.560 9.858,114.423 9.583 C
   114.020 8.776,113.805 8.501,112.741 7.431 C
   111.383 6.066,110.743 5.526,110.203 5.289 C
   109.954 5.180,109.712 5.046,109.667 4.992 C
   109.491 4.785,108.049 4.168,107.496 4.064 C
   107.177 4.004,106.804 3.861,106.667 3.747 C
   106.529 3.633,105.967 3.486,105.417 3.421 C
   104.867 3.355,104.311 3.215,104.183 3.109 C
   103.808 2.801,80.523 2.740,80.105 3.046 M122.200 3.033 C
   121.883 3.350,121.960 4.293,122.333 4.667 C
   122.624 4.958,122.889 5.000,124.417 5.000 L 126.168 5.000 126.125 32.458 L 126.083 59.917 124.333 59.960 C
   122.350 60.010,122.000 60.207,122.000 61.273 C
   122.000 61.739,122.083 61.874,122.458 62.020 C
   123.281 62.340,147.107 62.133,147.833 61.799 C
   148.154 61.652,148.792 61.492,149.250 61.445 C
   149.708 61.397,150.308 61.240,150.583 61.097 C
   150.858 60.953,151.174 60.835,151.284 60.834 C
   151.510 60.833,153.872 59.614,154.272 59.293 C
   155.775 58.082,157.293 56.377,157.796 55.333 C
   157.907 55.104,158.071 54.804,158.160 54.667 C
   158.452 54.217,159.000 52.858,159.000 52.584 C
   159.000 52.437,159.114 52.096,159.254 51.826 C
   160.060 50.268,160.050 41.758,159.241 40.603 C
   159.109 40.414,159.000 40.122,159.000 39.954 C
   159.000 39.648,158.466 38.302,158.158 37.833 C
   158.068 37.696,157.898 37.380,157.781 37.132 C
   157.321 36.153,154.820 33.500,154.357 33.500 C
   154.261 33.500,154.123 33.404,154.050 33.288 C
   153.883 33.022,153.121 32.524,152.250 32.110 C
   150.004 31.045,149.874 30.964,149.948 30.681 C
   149.992 30.511,150.320 30.317,150.761 30.199 C
   151.168 30.090,151.500 29.926,151.500 29.834 C
   151.500 29.742,151.594 29.665,151.708 29.664 C
   151.938 29.660,153.151 28.872,153.567 28.456 C
   153.713 28.311,154.004 28.055,154.215 27.888 C
   154.655 27.538,156.000 25.758,156.000 25.525 C
   156.000 25.439,156.103 25.330,156.229 25.281 C
   156.354 25.233,156.508 24.991,156.570 24.743 C
   156.632 24.496,156.792 24.184,156.925 24.051 C
   157.058 23.918,157.167 23.646,157.168 23.446 C
   157.168 23.247,157.289 22.858,157.435 22.583 C
   157.582 22.308,157.741 21.596,157.789 21.000 C
   157.837 20.404,157.939 19.837,158.016 19.740 C
   158.266 19.422,158.374 16.468,158.174 15.417 C
   158.069 14.867,157.912 13.892,157.825 13.250 C
   157.739 12.608,157.563 11.896,157.436 11.667 C
   157.309 11.438,157.153 11.006,157.089 10.708 C
   157.026 10.410,156.923 10.167,156.861 10.167 C
   156.799 10.167,156.583 9.810,156.382 9.375 C
   155.840 8.205,153.421 5.811,152.167 5.204 C
   151.938 5.093,151.637 4.938,151.500 4.859 C
   150.943 4.539,150.055 4.172,149.583 4.066 C
   149.308 4.004,148.990 3.880,148.875 3.790 C
   148.672 3.630,147.189 3.302,145.333 3.007 C
   143.833 2.769,122.440 2.793,122.200 3.033 M104.589 3.450 C
   105.417 3.633,106.256 3.869,106.453 3.975 C
   106.650 4.080,106.942 4.167,107.102 4.167 C
   108.557 4.167,112.507 7.047,113.777 9.034 C
   117.235 14.443,116.210 24.126,111.790 27.808 C
   111.498 28.051,111.126 28.381,110.963 28.542 C
   110.800 28.702,110.601 28.833,110.520 28.833 C
   110.440 28.833,110.284 28.942,110.174 29.075 C
   109.976 29.314,109.006 29.825,108.135 30.150 C
   107.088 30.541,107.733 31.784,109.015 31.844 C
   109.144 31.851,109.625 32.075,110.083 32.344 C
   110.542 32.612,111.029 32.882,111.167 32.944 C
   112.633 33.599,116.126 37.564,116.160 38.611 C
   116.164 38.719,116.274 39.019,116.406 39.278 C
   117.896 42.212,117.879 49.710,116.374 53.417 C
   114.668 57.620,110.608 60.848,106.333 61.398 C
   105.921 61.451,105.208 61.573,104.750 61.670 C
   103.554 61.921,81.231 62.092,80.625 61.854 C
   80.268 61.715,80.167 61.565,80.167 61.180 C
   80.167 60.376,80.652 60.167,82.519 60.167 C
   83.835 60.167,84.150 60.118,84.232 59.903 C
   84.443 59.354,84.345 5.079,84.133 4.867 C
   84.005 4.739,83.351 4.667,82.312 4.667 C
   80.508 4.667,80.167 4.508,80.167 3.669 C
   80.167 2.985,79.608 3.014,91.833 3.067 C
   102.365 3.113,103.180 3.138,104.589 3.450 M146.667 3.469 C
   150.896 4.506,152.843 5.518,154.866 7.731 C
   159.192 12.464,158.974 22.435,154.438 27.310 C
   153.773 28.026,151.847 29.500,151.579 29.500 C
   151.466 29.500,151.288 29.603,151.183 29.730 C
   151.078 29.856,150.775 30.003,150.509 30.057 C
   150.036 30.151,149.282 30.786,149.409 30.984 C
   149.484 31.102,150.176 31.450,151.709 32.142 C
   153.680 33.031,154.986 34.055,156.832 36.156 C
   157.794 37.252,158.863 39.752,159.365 42.083 C
   159.591 43.134,159.596 49.331,159.372 50.333 C
   157.988 56.509,154.165 60.470,148.750 61.338 C
   148.200 61.426,147.375 61.579,146.917 61.677 C
   145.685 61.941,123.161 62.098,122.690 61.846 C
   122.201 61.584,122.209 60.773,122.704 60.426 C
   122.973 60.238,123.488 60.167,124.587 60.167 C
   125.542 60.167,126.174 60.093,126.300 59.967 C
   126.698 59.568,126.577 5.053,126.178 4.839 C
   126.000 4.744,125.153 4.667,124.294 4.667 C
   123.302 4.667,122.660 4.594,122.533 4.467 C
   122.316 4.249,122.260 3.296,122.453 3.103 C
   122.782 2.773,145.213 3.113,146.667 3.469 M94.716 4.332 C
   93.172 4.659,93.325 3.187,93.374 17.215 L 93.417 29.583 95.833 29.520 C
   97.258 29.483,98.592 29.358,99.083 29.215 C
   99.542 29.083,100.158 28.934,100.452 28.886 C
   100.747 28.838,101.075 28.694,101.181 28.566 C
   101.287 28.438,101.462 28.333,101.570 28.333 C
   102.352 28.333,105.802 24.866,105.839 24.043 C
   105.842 23.974,105.990 23.617,106.167 23.250 C
   106.344 22.883,106.491 22.389,106.494 22.151 C
   106.497 21.914,106.612 21.351,106.748 20.901 C
   106.888 20.440,106.985 19.538,106.971 18.833 L 106.946 17.583 106.817 18.750 C
   106.599 20.721,106.222 22.678,106.023 22.877 C
   105.919 22.981,105.833 23.228,105.833 23.424 C
   105.833 23.621,105.683 23.955,105.500 24.167 C
   105.317 24.378,105.166 24.633,105.166 24.734 C
   105.163 25.223,102.218 28.000,101.702 28.000 C
   101.616 28.000,101.366 28.139,101.147 28.308 C
   100.929 28.478,100.416 28.668,100.008 28.732 C
   99.600 28.796,99.158 28.938,99.024 29.049 C
   98.865 29.181,97.877 29.270,96.141 29.309 L 93.500 29.368 93.500 17.216 L 93.500 5.064 94.125 4.794 C
   95.015 4.409,96.142 4.283,96.917 4.481 C
   97.283 4.575,97.921 4.737,98.333 4.841 C
   98.746 4.946,99.361 5.174,99.700 5.349 C
   100.039 5.524,100.400 5.667,100.503 5.667 C
   100.606 5.667,100.792 5.760,100.915 5.875 C
   101.039 5.990,101.339 6.233,101.583 6.417 C
   103.400 7.782,105.833 11.161,105.833 12.317 C
   105.833 12.424,105.937 12.678,106.065 12.881 C
   106.192 13.084,106.348 13.737,106.411 14.332 C
   106.474 14.927,106.588 15.489,106.665 15.582 C
   106.741 15.674,106.837 15.975,106.877 16.250 C
   106.946 16.722,106.951 16.717,106.975 16.151 C
   106.989 15.822,106.888 15.185,106.752 14.735 C
   106.615 14.285,106.503 13.738,106.502 13.520 C
   106.495 12.076,103.966 7.981,102.266 6.662 C
   101.799 6.300,101.342 5.943,101.250 5.869 C
   99.939 4.813,96.387 3.977,94.716 4.332 M136.806 4.314 C
   136.470 4.385,136.001 4.570,135.764 4.725 L 135.333 5.008 135.333 17.074 C
   135.333 23.710,135.379 29.258,135.434 29.403 C
   135.704 30.106,141.496 29.419,143.499 28.447 C
   145.784 27.338,148.523 24.094,148.495 22.528 C
   148.492 22.375,148.185 22.876,147.812 23.642 C
   147.439 24.407,147.010 25.168,146.858 25.333 C
   146.707 25.498,146.396 25.901,146.167 26.229 C
   145.938 26.556,145.688 26.826,145.611 26.829 C
   145.535 26.831,145.160 27.087,144.778 27.396 C
   144.396 27.706,143.896 28.015,143.667 28.083 C
   143.438 28.151,143.137 28.300,143.000 28.415 C
   142.862 28.530,142.450 28.678,142.083 28.744 C
   141.717 28.809,141.312 28.950,141.183 29.057 C
   141.027 29.186,140.057 29.250,138.266 29.250 L 135.583 29.250 135.541 17.148 L 135.498 5.046 136.291 4.731 C
   137.187 4.376,139.321 4.311,139.703 4.628 C
   139.830 4.733,140.262 4.871,140.664 4.936 C
   141.800 5.117,143.606 6.256,144.985 7.660 C
   146.546 9.249,146.495 9.180,147.207 10.667 C
   148.371 13.099,148.500 13.457,148.506 14.268 C
   148.509 14.717,148.619 15.271,148.750 15.500 C
   149.087 16.090,149.087 19.251,148.750 20.058 C
   148.612 20.387,148.507 21.015,148.515 21.453 C
   148.530 22.232,148.537 22.219,148.843 20.917 C
   149.225 19.290,149.271 16.311,148.934 14.993 C
   148.806 14.493,148.651 13.772,148.589 13.390 C
   148.527 13.009,148.371 12.559,148.243 12.390 C
   148.115 12.222,148.006 11.971,148.000 11.833 C
   147.966 11.011,146.337 8.542,144.962 7.226 C
   142.686 5.049,139.210 3.808,136.806 4.314 M284.875 5.282 C
   285.035 5.323,285.298 5.323,285.458 5.282 C
   285.619 5.240,285.487 5.205,285.167 5.205 C
   284.846 5.205,284.715 5.240,284.875 5.282 M292.640 5.333 C
   292.947 5.405,293.265 5.532,293.348 5.615 C
   293.437 5.703,293.516 17.119,293.541 33.175 L 293.583 60.583 295.083 60.667 C
   296.494 60.745,296.587 60.773,296.638 61.134 C
   296.757 61.974,296.620 62.000,292.013 62.000 C
   287.484 62.000,287.333 61.975,287.333 61.217 C
   287.333 60.686,286.716 60.708,286.121 61.259 C
   282.523 64.595,275.967 61.044,274.260 54.833 C
   274.197 54.604,274.078 54.267,273.996 54.083 C
   273.913 53.900,273.725 53.300,273.577 52.750 C
   273.038 50.742,273.004 50.670,273.067 51.660 C
   273.098 52.160,273.209 52.597,273.312 52.632 C
   273.415 52.666,273.500 52.916,273.500 53.187 C
   273.500 53.458,273.650 53.974,273.833 54.333 C
   274.017 54.693,274.167 55.131,274.167 55.306 C
   274.167 55.482,274.274 55.715,274.406 55.824 C
   274.538 55.934,274.692 56.238,274.750 56.500 C
   274.808 56.762,274.962 57.066,275.094 57.176 C
   275.226 57.285,275.333 57.503,275.333 57.661 C
   275.333 57.819,275.483 58.084,275.667 58.250 C
   275.850 58.416,276.000 58.629,276.000 58.723 C
   276.000 58.873,276.514 59.534,277.738 60.958 C
   277.915 61.165,278.154 61.333,278.268 61.333 C
   278.383 61.333,278.575 61.429,278.696 61.547 C
   280.378 63.177,284.613 63.054,286.343 61.324 C
   286.654 61.013,286.681 61.011,286.778 61.288 C
   287.071 62.124,287.281 62.159,292.021 62.163 C
   296.918 62.167,296.902 62.170,296.803 61.136 L 296.750 60.583 295.250 60.500 L 293.750 60.417 293.708 32.954 L 293.666 5.491 293.239 5.329 C
   293.004 5.240,292.648 5.174,292.447 5.184 C
   292.203 5.195,292.266 5.244,292.640 5.333 M260.458 6.014 C
   260.023 6.387,259.667 6.790,259.667 6.909 C
   259.667 7.028,259.554 7.219,259.417 7.333 C
   259.275 7.451,259.170 7.803,259.174 8.145 L 259.181 8.750 259.401 8.167 C
   259.853 6.965,259.974 6.741,260.357 6.392 C
   260.573 6.196,261.012 5.877,261.333 5.685 C
   261.777 5.418,261.837 5.335,261.583 5.335 C
   261.400 5.335,260.894 5.641,260.458 6.014 M264.169 5.729 C
   267.008 7.234,266.684 12.067,263.667 13.212 C
   263.493 13.278,263.525 13.311,263.771 13.320 C
   263.966 13.327,264.219 13.221,264.333 13.083 C
   264.447 12.946,264.635 12.833,264.751 12.833 C
   265.316 12.833,266.500 10.466,266.500 9.333 C
   266.500 7.636,264.907 5.333,263.733 5.333 C
   263.552 5.333,263.734 5.499,264.169 5.729 M283.865 6.042 L 283.917 6.750 285.355 6.798 L 286.793 6.846 286.884 7.465 C
   287.065 8.698,287.025 24.308,286.840 24.655 C
   286.668 24.975,286.636 24.965,286.066 24.411 C
   285.739 24.093,285.390 23.833,285.290 23.833 C
   285.191 23.833,284.967 23.704,284.793 23.547 C
   284.525 23.304,284.173 23.267,282.490 23.302 C
   280.400 23.346,280.202 23.403,278.483 24.466 C
   277.852 24.856,276.500 26.349,276.500 26.655 C
   276.500 26.753,276.427 26.833,276.338 26.833 C
   276.248 26.833,276.059 27.040,275.916 27.292 C
   275.773 27.544,275.510 27.975,275.331 28.250 C
   275.152 28.525,274.928 28.938,274.835 29.167 C
   274.741 29.396,274.552 29.782,274.416 30.026 C
   274.279 30.269,274.167 30.570,274.167 30.695 C
   274.167 30.820,274.017 31.201,273.833 31.544 C
   273.650 31.886,273.500 32.419,273.500 32.729 C
   273.500 33.039,273.387 33.386,273.250 33.500 C
   273.090 33.633,273.004 33.985,273.011 34.479 L 273.022 35.250 273.269 34.417 C
   273.404 33.958,273.583 33.283,273.665 32.917 C
   274.535 29.069,277.335 25.120,280.151 23.772 C
   281.712 23.024,285.063 23.560,286.154 24.733 C
   286.376 24.971,286.734 25.167,286.950 25.167 L 287.342 25.167 287.296 16.097 C
   287.271 11.109,287.188 6.965,287.112 6.889 C
   287.036 6.812,286.323 6.712,285.529 6.667 L 284.083 6.583 284.028 6.101 C
   283.997 5.828,284.069 5.559,284.195 5.479 C
   284.363 5.373,284.343 5.338,284.115 5.336 C
   283.864 5.334,283.823 5.450,283.865 6.042 M14.705 10.667 C
   14.709 12.243,14.710 12.246,14.873 11.298 C
   14.981 10.668,14.979 10.132,14.869 9.714 C
   14.724 9.171,14.702 9.303,14.705 10.667 M259.188 10.120 C
   259.151 11.358,260.633 13.355,261.563 13.320 C
   261.809 13.311,261.840 13.278,261.667 13.213 C
   260.622 12.818,259.547 11.383,259.323 10.083 L 259.208 9.417 259.188 10.120 M383.200 13.200 C
   383.053 13.347,383.000 14.781,383.000 18.617 L 383.000 23.833 381.322 23.833 C
   379.387 23.833,379.000 23.972,379.000 24.667 C
   379.000 25.361,379.387 25.500,381.322 25.500 L 383.000 25.500 383.001 41.708 L 383.001 57.917 383.401 59.059 C
   383.621 59.687,383.884 60.284,383.984 60.384 C
   384.084 60.484,384.167 60.645,384.168 60.742 C
   384.171 61.025,385.193 61.934,385.955 62.330 C
   386.741 62.739,390.213 62.791,390.465 62.397 C
   390.530 62.295,390.744 62.162,390.940 62.100 C
   391.388 61.961,393.000 60.364,393.000 60.060 C
   393.000 59.935,393.150 59.683,393.333 59.500 C
   393.517 59.317,393.667 59.011,393.667 58.821 C
   393.667 58.632,393.779 58.364,393.917 58.226 C
   394.066 58.077,394.160 57.696,394.150 57.280 L 394.133 56.583 393.980 57.237 C
   393.896 57.596,393.763 57.971,393.686 58.070 C
   393.609 58.169,393.496 58.461,393.434 58.718 C
   392.516 62.558,386.652 63.878,384.488 60.733 C
   383.197 58.856,383.267 59.868,383.170 41.667 L 383.083 25.417 381.426 25.369 C
   379.662 25.317,379.333 25.191,379.333 24.566 C
   379.333 24.082,379.586 24.014,381.583 23.958 L 383.083 23.917 383.127 18.703 L 383.171 13.490 383.597 13.328 C
   384.113 13.132,388.829 13.116,389.134 13.310 C
   389.306 13.419,389.349 14.536,389.338 18.620 L 389.325 23.792 389.704 23.894 C
   389.913 23.950,390.762 23.996,391.592 23.998 C
   392.567 23.999,393.178 24.074,393.321 24.208 C
   393.499 24.376,393.527 24.368,393.466 24.167 C
   393.405 23.969,393.018 23.907,391.611 23.869 L 389.833 23.822 389.802 19.203 C
   389.785 16.662,389.766 14.334,389.761 14.030 C
   389.742 13.082,389.462 13.000,386.251 13.000 C
   384.256 13.000,383.340 13.060,383.200 13.200 M15.379 14.333 C
   15.379 14.746,15.411 14.915,15.451 14.708 C
   15.491 14.502,15.491 14.165,15.451 13.958 C
   15.411 13.752,15.379 13.921,15.379 14.333 M15.836 16.968 C
   15.834 17.405,15.899 17.646,16.000 17.583 C
   16.211 17.453,16.211 16.827,16.000 16.500 C
   15.882 16.317,15.838 16.443,15.836 16.968 M16.539 19.167 C
   16.539 19.487,16.573 19.619,16.615 19.458 C
   16.657 19.298,16.657 19.035,16.615 18.875 C
   16.573 18.715,16.539 18.846,16.539 19.167 M165.227 20.833 C
   165.227 21.704,165.255 22.060,165.289 21.625 C
   165.324 21.190,165.324 20.477,165.289 20.042 C
   165.255 19.606,165.227 19.962,165.227 20.833 M17.000 21.083 C
   17.000 21.313,17.075 21.500,17.167 21.500 C
   17.258 21.500,17.333 21.313,17.333 21.083 C
   17.333 20.854,17.258 20.667,17.167 20.667 C
   17.075 20.667,17.000 20.854,17.000 21.083 M17.705 22.833 C
   17.705 23.154,17.740 23.285,17.782 23.125 C
   17.823 22.965,17.823 22.702,17.782 22.542 C
   17.740 22.381,17.705 22.512,17.705 22.833 M165.360 24.426 C
   165.341 25.384,165.404 25.863,165.578 26.093 C
   165.713 26.271,165.827 26.679,165.832 27.000 C
   165.837 27.321,165.989 27.910,166.170 28.310 C
   166.352 28.709,166.500 29.173,166.500 29.340 C
   166.500 29.506,166.603 29.745,166.728 29.871 C
   166.853 29.996,167.013 30.283,167.082 30.508 C
   167.151 30.733,167.273 31.000,167.354 31.101 C
   167.435 31.202,167.688 31.637,167.917 32.068 C
   169.330 34.726,173.303 38.688,175.625 39.755 C
   175.923 39.892,176.167 40.070,176.167 40.151 C
   176.167 40.231,176.346 40.342,176.564 40.397 C
   176.783 40.452,177.353 40.760,177.831 41.082 C
   178.309 41.403,178.805 41.667,178.933 41.667 C
   179.062 41.667,179.167 41.740,179.167 41.830 C
   179.167 41.920,179.373 42.044,179.625 42.106 C
   179.877 42.167,180.262 42.356,180.481 42.525 C
   180.699 42.695,180.950 42.833,181.038 42.833 C
   181.126 42.833,181.388 42.983,181.621 43.167 C
   181.854 43.350,182.177 43.500,182.338 43.500 C
   182.499 43.500,182.674 43.612,182.727 43.750 C
   182.780 43.887,182.966 44.000,183.141 44.000 C
   183.316 44.000,183.550 44.110,183.661 44.244 C
   183.772 44.377,184.020 44.537,184.211 44.597 C
   184.402 44.658,184.638 44.774,184.737 44.856 C
   185.149 45.197,186.193 45.833,186.341 45.833 C
   186.428 45.833,186.500 45.903,186.500 45.989 C
   186.500 46.074,186.659 46.195,186.853 46.256 C
   189.087 46.965,190.544 50.693,190.106 54.583 L 189.994 55.583 190.248 54.833 C
   190.843 53.080,190.426 50.003,189.375 48.384 C
   188.474 46.996,187.376 46.154,183.848 44.145 C
   182.877 43.593,181.971 43.076,181.833 42.998 C
   181.696 42.920,181.096 42.600,180.500 42.288 C
   178.801 41.399,177.773 40.810,177.487 40.562 C
   177.342 40.436,177.151 40.333,177.062 40.333 C
   176.873 40.333,174.564 38.824,173.489 37.997 C
   169.022 34.564,166.071 29.478,165.512 24.250 L 165.387 23.083 165.360 24.426 M229.526 23.349 C
   229.220 23.412,228.886 23.547,228.785 23.649 C
   228.683 23.750,228.497 23.833,228.372 23.833 C
   227.955 23.833,225.504 25.981,225.501 26.349 C
   225.500 26.404,225.350 26.622,225.167 26.833 C
   224.983 27.045,224.833 27.323,224.833 27.452 C
   224.833 27.581,224.758 27.640,224.667 27.583 C
   224.573 27.525,224.500 26.726,224.500 25.758 C
   224.500 23.636,225.009 23.833,219.528 23.836 C
   214.852 23.839,214.780 23.848,214.619 24.485 C
   214.409 25.313,214.710 25.500,216.248 25.500 L 217.652 25.500 217.749 26.042 C
   217.802 26.340,217.824 34.196,217.798 43.500 L 217.750 60.417 216.250 60.500 L 214.750 60.583 214.750 61.333 L 214.750 62.083 220.401 62.127 C
   225.254 62.165,226.107 62.135,226.442 61.915 C
   227.290 61.360,226.648 60.500,225.387 60.500 C
   224.723 60.500,224.676 60.469,224.583 59.958 C
   224.386 58.877,224.571 32.733,224.777 32.484 C
   224.883 32.355,225.025 31.950,225.093 31.583 C
   225.160 31.217,225.354 30.738,225.524 30.519 C
   225.694 30.301,225.833 30.035,225.833 29.929 C
   225.833 29.710,226.344 28.669,226.625 28.319 C
   227.478 27.250,228.260 26.500,228.521 26.500 C
   228.693 26.500,228.833 26.425,228.833 26.333 C
   228.833 26.242,229.021 26.167,229.250 26.167 C
   229.479 26.167,229.667 26.242,229.667 26.333 C
   229.667 26.425,229.782 26.500,229.922 26.500 C
   230.062 26.500,230.226 26.631,230.285 26.792 C
   230.344 26.952,230.470 27.135,230.565 27.198 C
   231.275 27.672,231.800 59.738,231.107 60.313 C
   230.998 60.404,230.543 60.502,230.096 60.531 C
   229.130 60.593,228.843 60.904,229.094 61.616 L 229.258 62.083 234.195 62.127 C
   239.642 62.176,240.000 62.128,240.000 61.343 C
   240.000 60.726,239.622 60.500,238.594 60.500 L 237.833 60.500 237.839 46.625 C
   237.843 34.689,237.878 32.705,238.089 32.426 C
   238.223 32.249,238.333 31.895,238.333 31.642 C
   238.333 30.866,239.949 28.080,241.080 26.907 C
   241.307 26.671,242.333 26.167,242.586 26.167 C
   243.140 26.167,243.988 27.302,244.158 28.274 C
   244.253 28.811,244.398 29.512,244.481 29.833 L 244.633 30.417 244.650 29.768 C
   244.697 27.955,243.591 25.993,242.529 26.005 C
   241.089 26.022,239.095 28.559,238.007 31.757 C
   237.671 32.745,237.666 33.021,237.707 46.674 L 237.750 60.583 238.667 60.667 C
   239.682 60.759,240.045 61.311,239.401 61.782 C
   239.083 62.015,230.925 62.110,229.845 61.894 C
   228.759 61.677,229.181 60.770,230.417 60.667 L 231.417 60.583 231.466 45.500 C
   231.510 31.746,231.489 30.278,231.224 28.839 C
   230.353 24.104,227.242 25.404,224.853 31.500 L 224.429 32.583 224.376 46.394 C
   224.319 61.617,224.242 60.620,225.472 60.700 C
   226.307 60.754,226.608 61.218,226.159 61.759 C
   225.878 62.097,215.560 62.119,215.099 61.782 C
   214.305 61.202,214.813 60.754,216.325 60.701 C
   217.100 60.674,217.831 60.571,217.950 60.472 C
   218.263 60.213,218.271 26.035,217.958 25.620 C
   217.798 25.407,217.421 25.332,216.333 25.296 L 214.917 25.250 214.865 24.714 C
   214.794 23.984,214.814 23.981,219.917 24.036 L 224.250 24.083 224.333 25.987 C
   224.414 27.837,224.654 28.516,224.946 27.722 C
   226.546 23.372,233.125 21.971,235.766 25.417 C
   236.375 26.211,236.999 27.478,237.003 27.928 C
   237.006 28.379,237.300 28.796,237.458 28.575 C
   237.527 28.479,237.882 27.954,238.247 27.408 C
   238.612 26.863,239.044 26.271,239.206 26.094 C
   239.367 25.916,239.500 25.727,239.500 25.675 C
   239.500 25.468,241.294 24.197,242.063 23.858 C
   242.930 23.476,246.369 23.321,246.575 23.654 C
   246.628 23.738,246.877 23.853,247.130 23.909 C
   248.201 24.144,250.333 26.975,250.333 28.163 C
   250.333 28.326,250.446 28.729,250.583 29.058 C
   250.799 29.575,250.833 31.734,250.833 44.961 C
   250.833 62.278,250.669 60.667,252.436 60.667 C
   254.131 60.667,254.865 61.281,253.832 61.834 C
   253.348 62.093,242.920 61.987,242.654 61.721 C
   242.150 61.216,242.618 60.686,243.613 60.633 C
   244.276 60.598,244.580 60.509,244.633 60.333 C
   244.694 60.132,244.666 60.124,244.487 60.292 C
   244.366 60.406,243.950 60.500,243.564 60.500 C
   242.540 60.500,242.333 60.623,242.333 61.234 C
   242.333 62.177,242.268 62.167,248.189 62.167 C
   254.154 62.167,254.500 62.122,254.500 61.343 C
   254.500 60.663,254.120 60.500,252.529 60.500 C
   251.473 60.500,251.182 60.446,251.101 60.236 C
   251.046 60.091,251.000 53.023,250.999 44.528 C
   250.998 31.617,250.960 29.010,250.765 28.636 C
   250.636 28.390,250.477 27.943,250.411 27.643 C
   250.086 26.159,248.703 24.400,247.280 23.659 C
   246.421 23.211,242.706 23.160,242.055 23.587 C
   241.848 23.722,241.593 23.833,241.488 23.833 C
   241.384 23.833,241.090 24.002,240.835 24.208 C
   240.581 24.415,240.137 24.771,239.848 25.000 C
   239.560 25.229,239.120 25.717,238.870 26.083 C
   238.621 26.450,238.323 26.849,238.208 26.970 C
   238.094 27.091,238.000 27.259,238.000 27.343 C
   238.000 27.541,237.465 28.167,237.296 28.167 C
   237.225 28.167,237.167 27.981,237.167 27.755 C
   237.167 26.708,235.100 23.833,234.347 23.833 C
   234.241 23.833,233.972 23.714,233.749 23.568 C
   233.307 23.278,230.557 23.135,229.526 23.349 M365.751 23.302 C
   365.294 23.331,364.806 23.452,364.668 23.572 C
   364.530 23.691,364.213 23.837,363.963 23.896 C
   362.725 24.190,360.601 26.985,359.895 29.250 C
   359.471 30.611,359.469 35.304,359.893 36.083 C
   360.042 36.358,360.165 36.750,360.166 36.953 C
   360.167 37.281,360.585 38.295,361.011 39.000 C
   361.094 39.137,361.277 39.475,361.419 39.750 C
   361.860 40.608,362.604 41.752,363.000 42.182 C
   363.137 42.331,363.475 42.739,363.750 43.087 C
   364.025 43.436,365.031 44.515,365.985 45.486 C
   366.939 46.456,368.246 47.850,368.890 48.583 C
   369.534 49.317,370.122 49.942,370.197 49.972 C
   370.272 50.003,370.333 50.102,370.333 50.192 C
   370.333 50.282,370.514 50.594,370.736 50.886 C
   371.437 51.812,372.134 53.324,372.251 54.176 C
   372.314 54.629,372.433 55.042,372.516 55.093 C
   372.961 55.368,372.368 58.473,371.736 59.180 C
   371.606 59.325,371.500 59.501,371.500 59.572 C
   371.500 59.972,369.500 61.006,368.750 60.994 C
   367.715 60.978,365.842 59.963,365.083 59.007 C
   364.900 58.776,364.637 58.473,364.500 58.333 C
   364.133 57.961,362.833 55.279,362.833 54.894 C
   362.833 54.712,362.723 54.418,362.589 54.240 C
   362.454 54.062,362.342 53.645,362.339 53.313 C
   362.336 52.982,362.183 52.241,362.000 51.667 C
   361.817 51.093,361.667 50.230,361.667 49.749 C
   361.667 48.687,361.445 48.461,360.471 48.531 L 359.750 48.583 359.706 55.167 C
   359.666 61.208,359.686 61.767,359.946 61.958 C
   360.632 62.461,361.667 62.039,361.667 61.257 C
   361.667 61.030,361.838 60.760,362.083 60.599 C
   362.314 60.448,362.500 60.404,362.500 60.501 C
   362.500 60.598,362.631 60.726,362.792 60.787 C
   362.952 60.847,363.439 61.144,363.873 61.448 C
   364.308 61.752,364.758 62.000,364.873 62.001 C
   364.989 62.002,365.308 62.133,365.583 62.293 C
   366.323 62.723,371.344 62.723,372.083 62.293 C
   372.358 62.133,372.679 62.002,372.797 62.001 C
   372.914 62.000,373.051 61.894,373.101 61.765 C
   373.150 61.636,373.354 61.485,373.554 61.431 C
   374.288 61.230,376.167 59.092,376.167 58.456 C
   376.167 58.266,376.269 57.991,376.393 57.846 C
   376.518 57.702,376.708 57.171,376.817 56.667 C
   376.926 56.163,377.083 55.450,377.167 55.083 C
   377.410 54.019,377.366 51.401,377.095 50.751 C
   376.963 50.437,376.805 49.820,376.743 49.381 C
   376.681 48.942,376.526 48.417,376.399 48.214 C
   376.271 48.011,376.167 47.737,376.167 47.605 C
   376.167 47.320,375.309 45.608,375.126 45.528 C
   375.057 45.497,375.000 45.389,375.000 45.287 C
   375.000 45.112,374.205 43.847,373.981 43.667 C
   373.925 43.621,373.826 43.415,373.761 43.208 C
   373.697 43.002,373.499 42.755,373.322 42.661 C
   373.145 42.566,373.000 42.413,373.000 42.322 C
   373.000 42.230,372.775 41.935,372.500 41.667 C
   372.225 41.398,372.000 41.136,372.000 41.084 C
   372.000 40.918,370.109 38.924,368.573 37.471 C
   368.121 37.043,367.637 36.544,367.500 36.364 C
   367.363 36.183,366.812 35.570,366.278 35.001 C
   365.743 34.431,365.124 33.625,364.903 33.209 C
   364.682 32.792,364.407 32.339,364.292 32.200 C
   363.935 31.771,364.005 27.288,364.375 26.880 C
   364.535 26.702,364.667 26.482,364.667 26.391 C
   364.667 25.184,367.628 24.611,369.167 25.521 C
   369.304 25.603,369.601 25.762,369.825 25.876 C
   370.306 26.120,371.000 26.783,371.000 26.999 C
   371.000 27.082,371.095 27.210,371.212 27.283 C
   371.328 27.357,371.514 27.623,371.625 27.875 C
   371.736 28.127,371.903 28.333,371.996 28.333 C
   372.090 28.333,372.169 28.465,372.172 28.625 C
   372.175 28.785,372.323 29.217,372.500 29.583 C
   372.677 29.950,372.825 30.402,372.828 30.588 C
   372.831 30.774,372.938 31.075,373.066 31.258 C
   373.194 31.441,373.380 32.264,373.480 33.087 C
   373.710 34.992,374.316 35.620,375.260 34.930 C
   375.640 34.652,375.858 24.835,375.498 24.163 C
   375.198 23.601,374.337 23.724,373.792 24.407 C
   373.353 24.958,373.316 24.971,372.876 24.741 C
   372.623 24.610,372.353 24.502,372.276 24.501 C
   372.199 24.500,371.945 24.350,371.712 24.167 C
   371.479 23.983,371.146 23.833,370.972 23.833 C
   370.797 23.833,370.489 23.728,370.286 23.599 C
   369.911 23.361,367.414 23.198,365.751 23.302 M342.907 23.578 C
   342.729 23.713,342.471 23.826,342.333 23.830 C
   342.058 23.838,340.266 25.054,339.745 25.586 C
   338.952 26.397,337.333 29.090,337.333 29.599 C
   337.333 29.729,337.183 30.114,337.000 30.456 C
   336.817 30.799,336.667 31.271,336.667 31.507 C
   336.667 31.743,336.557 32.082,336.422 32.260 C
   336.284 32.442,336.183 32.911,336.191 33.333 L 336.205 34.083 336.341 33.500 C
   337.551 28.292,339.758 25.017,342.852 23.842 C
   343.809 23.478,347.586 23.333,347.925 23.647 C
   348.012 23.727,348.361 23.882,348.700 23.990 C
   350.534 24.576,352.763 27.941,353.728 31.583 C
   353.777 31.767,353.791 31.617,353.761 31.250 C
   353.705 30.585,353.394 29.605,352.889 28.500 C
   352.197 26.991,351.900 26.457,351.708 26.381 C
   351.594 26.335,351.500 26.227,351.500 26.141 C
   351.500 25.969,350.099 24.500,349.935 24.500 C
   349.879 24.500,349.683 24.350,349.500 24.167 C
   349.317 23.983,349.049 23.833,348.905 23.833 C
   348.761 23.833,348.530 23.721,348.393 23.583 C
   348.033 23.223,343.382 23.219,342.907 23.578 M370.417 23.874 C
   370.967 24.065,371.529 24.309,371.667 24.416 C
   371.989 24.667,373.082 25.167,373.308 25.167 C
   373.404 25.167,373.683 24.904,373.928 24.583 C
   374.361 24.015,375.023 23.800,375.243 24.155 C
   375.352 24.332,375.376 25.859,375.351 31.135 L 375.333 34.853 374.722 34.802 L 374.110 34.750 373.808 33.500 C
   373.641 32.813,373.504 32.084,373.503 31.881 C
   373.501 31.678,373.396 31.265,373.269 30.964 C
   373.142 30.663,372.865 29.967,372.653 29.417 C
   371.040 25.230,366.458 23.223,364.725 25.945 C
   364.560 26.204,364.371 26.456,364.306 26.503 C
   363.812 26.865,363.535 29.442,363.816 31.056 C
   364.138 32.903,365.531 34.875,368.643 37.890 C
   370.151 39.350,371.576 40.834,372.028 41.415 C
   372.242 41.689,372.529 42.033,372.667 42.179 C
   372.804 42.325,373.029 42.618,373.167 42.829 C
   373.304 43.040,373.585 43.420,373.792 43.672 C
   373.998 43.925,374.167 44.190,374.167 44.261 C
   374.167 44.332,374.270 44.509,374.396 44.654 C
   376.990 47.631,377.744 54.665,375.875 58.453 C
   374.208 61.834,369.878 63.460,366.017 62.157 C
   364.898 61.779,364.743 61.699,363.222 60.721 C
   362.079 59.986,361.500 60.111,361.500 61.091 C
   361.500 61.662,360.847 62.061,360.272 61.842 L 359.833 61.675 359.833 55.272 C
   359.833 49.587,359.863 48.858,360.097 48.768 C
   361.000 48.421,361.499 48.878,361.504 50.054 C
   361.506 50.528,361.587 51.217,361.684 51.583 C
   361.780 51.950,361.961 52.700,362.084 53.250 C
   362.338 54.377,362.699 55.429,363.176 56.428 C
   363.354 56.800,363.500 57.194,363.500 57.303 C
   363.500 57.411,363.575 57.500,363.667 57.500 C
   363.758 57.500,363.833 57.582,363.833 57.682 C
   363.833 57.782,364.002 58.087,364.208 58.359 C
   368.710 64.304,374.758 60.036,372.119 52.777 C
   371.617 51.395,369.483 48.695,366.564 45.750 C
   360.691 39.824,358.743 35.173,360.000 30.083 C
   360.181 29.350,360.330 28.663,360.331 28.556 C
   360.348 26.950,364.010 23.628,365.940 23.470 C
   367.392 23.350,369.445 23.536,370.417 23.874 M256.375 23.944 C
   256.062 24.070,256.108 25.275,256.430 25.399 C
   256.575 25.454,257.244 25.500,257.916 25.500 L 259.137 25.500 259.237 26.125 C
   259.291 26.469,259.317 34.325,259.293 43.583 L 259.250 60.417 257.833 60.463 C
   256.141 60.518,255.381 61.355,256.401 62.039 C
   256.809 62.313,268.791 62.186,269.071 61.905 C
   269.888 61.088,269.273 60.528,267.492 60.466 L 266.083 60.417 266.037 42.257 C
   266.001 28.168,265.945 24.068,265.787 23.968 C
   265.567 23.828,256.719 23.805,256.375 23.944 M297.673 24.156 C
   297.190 25.057,297.586 25.500,298.874 25.500 C
   300.148 25.500,300.428 25.762,300.654 27.169 C
   300.750 27.763,300.904 28.274,300.997 28.305 C
   301.090 28.336,301.168 28.711,301.170 29.139 C
   301.172 29.567,301.322 30.244,301.504 30.643 C
   301.685 31.043,301.834 31.605,301.834 31.893 C
   301.835 32.181,301.935 32.608,302.057 32.842 C
   302.179 33.075,302.325 33.655,302.381 34.129 C
   302.500 35.132,302.861 35.948,302.965 35.450 C
   302.999 35.286,302.955 35.061,302.866 34.950 C
   302.777 34.840,302.654 34.412,302.592 34.000 C
   302.530 33.587,302.378 32.912,302.254 32.500 C
   301.931 31.422,301.678 30.411,301.408 29.119 C
   301.278 28.497,301.105 27.859,301.024 27.702 C
   300.942 27.545,300.827 27.079,300.766 26.667 C
   300.598 25.513,300.493 25.427,299.149 25.333 C
   297.832 25.242,297.408 24.947,297.769 24.375 C
   297.939 24.106,298.415 24.083,303.851 24.083 L 309.750 24.083 309.750 24.667 L 309.750 25.250 308.292 25.298 L 306.833 25.346 306.833 25.966 C
   306.833 26.307,306.915 26.848,307.015 27.168 C
   307.181 27.702,307.284 28.134,307.832 30.583 C
   307.935 31.042,308.122 31.792,308.249 32.250 C
   308.633 33.644,309.005 35.202,309.179 36.147 C
   309.270 36.640,309.408 37.165,309.486 37.313 C
   309.564 37.462,309.679 37.921,309.741 38.333 C
   309.803 38.746,309.955 39.421,310.077 39.833 C
   310.401 40.926,310.848 42.780,311.040 43.833 C
   311.132 44.337,311.266 44.825,311.337 44.917 C
   311.409 45.008,311.522 45.518,311.588 46.050 C
   311.781 47.616,312.476 47.988,312.715 46.653 C
   312.788 46.248,312.959 45.542,313.095 45.083 C
   313.231 44.625,313.414 43.913,313.500 43.500 C
   313.587 43.087,313.745 42.337,313.851 41.833 C
   313.957 41.329,314.184 40.485,314.356 39.958 C
   314.527 39.430,314.667 38.821,314.667 38.603 C
   314.667 38.385,314.770 37.954,314.896 37.645 C
   315.022 37.336,315.177 36.724,315.241 36.285 C
   315.304 35.846,315.463 35.230,315.595 34.916 C
   315.726 34.601,315.833 34.112,315.833 33.828 C
   315.833 33.544,315.909 33.169,316.003 32.995 C
   316.096 32.821,316.278 32.170,316.408 31.548 C
   316.538 30.926,316.729 30.079,316.833 29.667 C
   316.937 29.254,317.098 28.617,317.191 28.250 C
   317.284 27.883,317.434 27.208,317.526 26.750 C
   317.756 25.597,318.191 24.271,318.380 24.145 C
   318.807 23.861,319.154 24.223,319.338 25.142 C
   319.441 25.660,319.626 26.458,319.748 26.917 C
   319.996 27.844,320.192 28.617,320.677 30.583 C
   320.858 31.317,321.117 32.367,321.253 32.917 C
   321.729 34.845,322.180 36.757,322.335 37.500 C
   322.420 37.912,322.601 38.625,322.736 39.083 C
   322.871 39.542,323.066 40.292,323.169 40.750 C
   323.571 42.535,324.106 44.697,324.509 46.167 C
   324.672 46.762,324.853 47.467,324.912 47.732 C
   324.972 48.003,325.080 48.149,325.158 48.066 C
   325.297 47.918,325.380 47.606,326.023 44.833 C
   326.579 42.432,326.569 42.509,326.250 43.083 C
   326.097 43.358,325.929 43.996,325.878 44.500 C
   325.827 45.004,325.683 45.565,325.559 45.745 C
   325.435 45.926,325.328 46.339,325.322 46.662 C
   325.304 47.538,325.027 48.106,325.012 47.298 C
   325.005 46.957,324.850 46.298,324.667 45.833 C
   324.483 45.368,324.333 44.742,324.333 44.442 C
   324.333 44.142,324.223 43.751,324.089 43.574 C
   323.954 43.396,323.838 42.950,323.831 42.583 C
   323.824 42.217,323.703 41.617,323.561 41.250 C
   323.419 40.883,323.241 40.186,323.165 39.700 C
   323.089 39.214,322.954 38.726,322.865 38.616 C
   322.777 38.507,322.651 38.004,322.586 37.500 C
   322.522 36.996,322.363 36.366,322.234 36.099 C
   322.105 35.833,322.000 35.308,321.999 34.933 C
   321.998 34.557,321.891 34.044,321.759 33.793 C
   321.628 33.541,321.470 32.978,321.408 32.543 C
   321.346 32.107,321.198 31.525,321.079 31.250 C
   320.961 30.975,320.817 30.361,320.761 29.886 C
   320.704 29.410,320.548 28.810,320.413 28.552 C
   320.279 28.294,320.168 27.875,320.168 27.620 C
   320.167 27.365,320.060 26.901,319.930 26.590 C
   319.800 26.279,319.641 25.601,319.576 25.083 C
   319.309 22.944,317.730 23.730,317.449 26.143 C
   317.392 26.634,317.262 27.135,317.162 27.256 C
   317.061 27.378,316.937 27.913,316.886 28.447 C
   316.836 28.980,316.698 29.572,316.580 29.762 C
   316.461 29.952,316.310 30.544,316.244 31.077 C
   316.177 31.611,316.020 32.243,315.896 32.482 C
   315.771 32.721,315.668 33.219,315.668 33.588 C
   315.667 33.957,315.556 34.417,315.420 34.611 C
   315.285 34.804,315.125 35.402,315.064 35.940 C
   315.004 36.477,314.885 37.005,314.800 37.114 C
   314.714 37.222,314.577 37.791,314.494 38.377 C
   314.411 38.964,314.228 39.663,314.089 39.930 C
   313.950 40.198,313.835 40.702,313.834 41.050 C
   313.834 41.398,313.730 41.886,313.603 42.133 C
   313.476 42.381,313.317 42.996,313.249 43.500 C
   313.181 44.004,313.022 44.565,312.896 44.745 C
   312.770 44.926,312.667 45.389,312.667 45.773 C
   312.667 47.385,311.940 47.397,311.758 45.788 C
   311.695 45.229,311.536 44.564,311.405 44.311 C
   311.274 44.057,311.166 43.565,311.166 43.217 C
   311.165 42.868,311.047 42.358,310.903 42.083 C
   310.760 41.808,310.601 41.208,310.551 40.750 C
   310.501 40.292,310.357 39.751,310.230 39.548 C
   310.104 39.345,310.000 38.879,310.000 38.512 C
   310.000 38.146,309.850 37.465,309.667 37.000 C
   309.483 36.535,309.333 35.913,309.332 35.619 C
   309.332 35.324,309.231 34.891,309.108 34.655 C
   308.985 34.419,308.830 33.820,308.764 33.324 C
   308.699 32.827,308.544 32.195,308.421 31.919 C
   308.298 31.643,308.148 31.006,308.088 30.504 C
   308.028 30.002,307.906 29.504,307.817 29.397 C
   307.728 29.290,307.611 28.800,307.558 28.309 C
   307.504 27.818,307.357 27.251,307.230 27.048 C
   307.104 26.845,307.000 26.416,307.000 26.096 L 307.000 25.513 308.458 25.465 L 309.917 25.417 309.970 24.767 C
   310.047 23.825,310.139 23.839,303.714 23.836 C
   298.081 23.833,297.838 23.846,297.673 24.156 M325.803 24.042 C
   325.007 24.679,325.536 25.500,326.744 25.500 C
   327.485 25.500,328.832 25.987,328.833 26.255 C
   328.833 26.349,328.983 26.554,329.167 26.711 C
   329.606 27.087,329.652 29.555,329.236 30.360 C
   329.091 30.641,328.942 31.294,328.906 31.810 L 328.840 32.750 329.064 31.833 C
   329.188 31.329,329.374 30.706,329.478 30.447 C
   329.582 30.189,329.667 29.266,329.667 28.394 L 329.667 26.811 328.896 26.072 C
   328.169 25.376,328.071 25.333,327.179 25.333 C
   325.986 25.333,325.204 24.444,326.097 24.101 C
   326.441 23.969,335.226 23.969,335.570 24.101 C
   336.354 24.402,335.818 25.149,334.500 25.592 C
   332.733 26.187,332.448 26.683,331.181 31.371 C
   330.990 32.080,330.833 32.854,330.833 33.091 C
   330.833 33.328,330.744 33.686,330.635 33.886 C
   330.526 34.086,330.343 34.700,330.227 35.250 C
   330.030 36.187,329.935 36.591,329.490 38.375 C
   329.098 39.948,329.097 39.964,329.396 39.560 C
   329.541 39.364,329.707 38.764,329.766 38.227 C
   329.825 37.690,329.965 37.100,330.077 36.917 C
   330.190 36.733,330.326 36.140,330.381 35.599 C
   330.436 35.058,330.566 34.562,330.671 34.498 C
   330.775 34.433,330.922 33.880,330.998 33.268 C
   331.074 32.655,331.208 32.064,331.296 31.952 C
   331.384 31.841,331.505 31.361,331.565 30.886 C
   331.626 30.410,331.785 29.810,331.920 29.552 C
   332.055 29.294,332.165 28.932,332.166 28.748 C
   332.166 28.563,332.317 28.132,332.500 27.790 C
   332.683 27.448,332.833 27.089,332.833 26.993 C
   332.833 26.721,333.714 26.011,334.058 26.005 C
   334.228 26.002,334.640 25.875,334.975 25.722 C
   336.175 25.174,336.462 24.652,335.862 24.109 C
   335.463 23.748,326.248 23.686,325.803 24.042 M18.241 24.181 C
   18.179 24.281,18.213 24.446,18.315 24.548 C
   18.451 24.684,18.500 24.636,18.500 24.367 C
   18.500 23.964,18.413 23.902,18.241 24.181 M265.833 42.333 L 265.917 60.583 267.417 60.667 C
   269.002 60.755,269.402 61.065,268.826 61.759 C
   268.582 62.053,257.506 62.121,256.750 61.833 C
   255.698 61.433,256.250 60.756,257.667 60.708 C
   258.354 60.685,259.085 60.627,259.292 60.580 L 259.667 60.494 259.667 43.015 C
   259.667 27.230,259.641 25.526,259.403 25.434 C
   259.258 25.379,258.598 25.333,257.936 25.333 C
   256.655 25.333,256.333 25.179,256.333 24.564 C
   256.333 24.023,256.354 24.021,261.250 24.053 L 265.750 24.083 265.833 42.333 M393.416 24.872 C
   393.261 25.226,393.137 25.253,391.500 25.296 C
   390.108 25.332,389.708 25.397,389.545 25.615 C
   389.146 26.147,389.258 58.405,389.661 59.292 C
   390.021 60.081,390.202 60.139,390.889 59.686 C
   391.589 59.225,392.695 57.122,392.653 56.333 C
   392.633 55.963,392.009 57.355,392.004 57.782 C
   392.002 57.983,391.920 58.196,391.823 58.256 C
   391.726 58.317,391.579 58.543,391.496 58.759 C
   391.414 58.976,391.051 59.304,390.690 59.488 L 390.034 59.823 389.933 59.424 C
   389.802 58.899,389.787 25.876,389.918 25.664 C
   389.974 25.574,390.763 25.500,391.671 25.500 C
   393.343 25.500,393.845 25.284,393.635 24.655 C
   393.605 24.566,393.507 24.664,393.416 24.872 M282.081 25.059 C
   281.033 25.547,279.667 27.378,279.667 28.294 C
   279.667 28.477,279.535 28.916,279.375 29.271 C
   278.909 30.303,278.929 55.260,279.398 56.750 C
   280.161 59.178,280.742 60.123,281.885 60.794 C
   283.905 61.981,286.506 59.895,287.085 56.621 C
   287.435 54.640,287.446 31.619,287.097 29.472 C
   286.558 26.145,284.201 24.072,282.081 25.059 M344.860 24.949 C
   344.000 25.372,343.164 26.503,342.704 27.863 C
   342.178 29.418,341.897 42.069,342.380 42.470 C
   342.560 42.619,343.375 42.661,345.355 42.625 L 348.083 42.574 345.305 42.537 L 342.526 42.500 342.437 42.042 C
   342.240 41.028,342.410 28.992,342.624 28.860 C
   342.738 28.789,342.894 28.394,342.971 27.981 C
   343.530 24.992,346.940 23.955,347.573 26.582 C
   347.639 26.856,347.771 27.128,347.867 27.188 C
   348.149 27.362,348.309 31.197,348.279 37.042 C
   348.260 40.619,348.307 42.481,348.417 42.444 C
   348.670 42.360,348.631 29.894,348.373 28.390 C
   347.894 25.600,346.436 24.172,344.860 24.949 M284.085 25.261 C
   284.822 25.569,285.787 26.533,286.102 27.276 C
   286.225 27.566,286.413 27.832,286.520 27.868 C
   287.172 28.085,287.193 57.572,286.542 57.974 C
   286.436 58.039,286.288 58.241,286.211 58.421 C
   285.978 58.973,285.424 59.831,285.299 59.832 C
   285.235 59.833,285.010 60.004,284.799 60.212 C
   283.518 61.479,280.872 60.670,280.420 58.872 C
   280.363 58.645,280.208 58.351,280.075 58.218 C
   279.942 58.085,279.833 57.698,279.833 57.359 C
   279.833 57.019,279.702 56.555,279.542 56.329 C
   279.270 55.945,279.250 55.033,279.250 43.000 C
   279.250 30.967,279.270 30.055,279.542 29.671 C
   279.707 29.438,279.833 28.958,279.833 28.567 C
   279.833 28.176,279.935 27.790,280.067 27.680 C
   280.195 27.574,280.344 27.313,280.397 27.101 C
   280.755 25.675,282.725 24.693,284.085 25.261 M18.833 25.740 C
   18.833 26.251,18.928 26.292,19.048 25.833 C
   19.096 25.650,19.067 25.458,18.984 25.407 C
   18.901 25.356,18.833 25.506,18.833 25.740 M44.037 29.031 C
   44.078 29.154,44.421 29.273,44.822 29.302 C
   45.352 29.341,45.516 29.298,45.463 29.135 C
   45.422 29.012,45.079 28.894,44.678 28.865 C
   44.148 28.826,43.984 28.869,44.037 29.031 M45.927 29.484 C
   45.715 29.827,46.019 30.000,46.833 30.000 C
   47.579 30.000,48.023 29.803,47.711 29.611 C
   47.384 29.410,46.032 29.313,45.927 29.484 M48.000 30.230 C
   48.000 30.454,48.205 30.500,49.200 30.500 C
   50.696 30.500,50.546 30.144,49.002 30.032 C
   48.160 29.971,48.000 30.003,48.000 30.230 M244.721 31.583 C
   244.722 32.225,244.752 32.467,244.788 32.121 C
   244.824 31.775,244.824 31.250,244.787 30.955 C
   244.750 30.659,244.721 30.942,244.721 31.583 M50.428 30.814 C
   50.286 31.045,50.962 31.162,52.458 31.164 C
   53.779 31.167,54.246 30.983,53.458 30.772 C
   52.877 30.616,50.531 30.649,50.428 30.814 M93.410 31.542 C
   93.366 31.656,93.350 38.125,93.374 45.917 L 93.417 60.083 96.750 60.079 C
   100.002 60.075,100.109 60.064,101.156 59.620 C
   106.688 57.273,109.025 51.039,107.758 42.000 C
   107.490 40.087,107.021 38.475,107.007 39.421 C
   107.003 39.699,107.102 40.074,107.227 40.255 C
   107.362 40.449,107.499 41.296,107.564 42.333 C
   107.623 43.296,107.756 44.686,107.858 45.422 C
   107.994 46.411,107.994 46.991,107.855 47.637 C
   107.752 48.118,107.621 49.165,107.564 49.964 C
   107.507 50.763,107.357 51.583,107.230 51.786 C
   107.104 51.989,107.000 52.334,107.000 52.553 C
   107.000 53.525,105.169 56.446,103.847 57.583 C
   103.528 57.858,103.143 58.196,102.992 58.333 C
   102.560 58.728,101.381 59.333,101.046 59.333 C
   100.878 59.333,100.570 59.453,100.361 59.600 C
   99.950 59.887,94.810 60.156,93.933 59.936 L 93.500 59.827 93.500 45.649 L 93.500 31.470 94.458 31.546 C
   96.017 31.670,98.533 32.005,99.250 32.184 C
   99.617 32.276,100.250 32.431,100.657 32.529 C
   101.065 32.627,101.501 32.811,101.627 32.937 C
   101.753 33.063,102.039 33.212,102.262 33.268 C
   102.484 33.324,102.667 33.431,102.667 33.507 C
   102.667 33.582,102.811 33.690,102.987 33.746 C
   103.491 33.906,105.502 35.963,105.761 36.584 C
   105.890 36.892,106.071 37.191,106.164 37.248 C
   106.257 37.306,106.333 37.490,106.333 37.658 C
   106.333 37.826,106.488 38.140,106.677 38.357 C
   107.099 38.838,107.070 38.745,106.315 37.154 C
   105.069 34.531,101.249 31.847,98.737 31.830 C
   98.469 31.828,97.650 31.718,96.917 31.586 C
   95.224 31.280,93.518 31.259,93.410 31.542 M135.533 31.533 C
   135.295 31.771,135.243 59.600,135.480 59.974 C
   135.714 60.343,142.031 60.169,142.917 59.769 C
   145.348 58.670,147.119 57.197,148.095 55.462 C
   148.820 54.173,149.205 53.232,149.580 51.833 C
   149.686 51.438,149.679 51.432,149.436 51.731 C
   149.295 51.904,149.132 52.370,149.072 52.767 C
   149.012 53.164,148.859 53.576,148.732 53.682 C
   148.604 53.788,148.500 53.998,148.500 54.149 C
   148.500 55.019,145.290 58.667,144.525 58.667 C
   144.442 58.667,144.285 58.774,144.176 58.906 C
   144.066 59.038,143.738 59.195,143.447 59.256 C
   143.155 59.316,142.735 59.482,142.512 59.623 C
   141.967 59.969,136.131 60.149,135.748 59.831 C
   135.493 59.620,135.351 32.417,135.601 31.764 C
   135.720 31.453,137.198 31.393,137.923 31.669 C
   138.168 31.762,139.115 31.902,140.027 31.979 C
   140.939 32.056,141.771 32.205,141.876 32.309 C
   141.981 32.414,142.215 32.500,142.396 32.500 C
   144.064 32.500,148.500 36.388,148.500 37.851 C
   148.500 38.002,148.602 38.211,148.728 38.315 C
   148.853 38.419,148.999 38.822,149.052 39.210 C
   149.106 39.599,149.257 40.142,149.389 40.417 C
   149.818 41.314,150.028 46.251,149.785 49.750 L 149.669 51.417 150.016 49.333 C
   150.458 46.679,150.105 41.046,149.415 39.722 C
   149.279 39.462,149.168 39.128,149.168 38.978 C
   149.167 38.829,148.976 38.304,148.743 37.812 C
   146.953 34.033,144.348 32.326,139.250 31.593 C
   137.069 31.280,135.807 31.260,135.533 31.533 M353.919 32.417 C
   353.975 32.692,354.092 33.367,354.178 33.917 C
   354.265 34.467,354.423 35.442,354.528 36.083 C
   354.634 36.725,354.770 38.075,354.831 39.083 L 354.942 40.917 354.971 38.697 C
   354.990 37.266,354.931 36.349,354.805 36.114 C
   354.697 35.914,354.556 35.106,354.490 34.318 C
   354.422 33.492,354.254 32.681,354.094 32.401 L 353.817 31.917 353.919 32.417 M328.529 33.125 C
   328.447 33.285,328.335 33.806,328.282 34.283 C
   328.228 34.759,328.068 35.374,327.926 35.649 C
   327.783 35.925,327.667 36.458,327.667 36.833 C
   327.667 37.209,327.562 37.719,327.434 37.967 C
   327.306 38.214,327.146 38.867,327.078 39.417 C
   327.010 39.967,326.853 40.565,326.727 40.745 C
   326.602 40.926,326.509 41.414,326.519 41.829 C
   326.538 42.579,326.539 42.580,326.654 41.994 C
   326.717 41.670,326.825 41.315,326.893 41.205 C
   326.961 41.096,327.122 40.461,327.251 39.794 C
   327.380 39.128,327.643 37.946,327.835 37.167 C
   328.027 36.387,328.283 35.300,328.404 34.750 C
   328.525 34.200,328.680 33.544,328.747 33.292 C
   328.884 32.777,328.757 32.680,328.529 33.125 M244.746 46.833 C
   244.746 54.121,244.767 57.102,244.792 53.458 C
   244.817 49.815,244.817 43.852,244.792 40.208 C
   244.767 36.565,244.746 39.546,244.746 46.833 M335.784 35.371 C
   335.428 36.841,335.345 48.347,335.682 49.583 C
   335.782 49.950,335.957 50.872,336.072 51.631 C
   336.186 52.391,336.367 53.100,336.473 53.206 C
   336.580 53.313,336.667 53.630,336.667 53.911 C
   336.667 54.192,336.817 54.701,337.000 55.044 C
   337.183 55.386,337.333 55.807,337.333 55.979 C
   337.333 56.151,337.441 56.382,337.573 56.491 C
   337.704 56.600,337.859 56.904,337.917 57.167 C
   337.974 57.429,338.129 57.733,338.261 57.842 C
   338.392 57.951,338.500 58.110,338.500 58.194 C
   338.500 58.644,340.738 61.078,341.500 61.457 C
   341.729 61.571,342.029 61.737,342.167 61.826 C
   343.585 62.747,347.459 63.037,348.917 62.333 C
   349.283 62.156,349.643 62.009,349.715 62.006 C
   349.954 61.995,350.905 61.261,351.499 60.627 C
   352.367 59.701,352.489 59.545,352.669 59.129 C
   352.759 58.921,352.902 58.637,352.988 58.500 C
   353.385 57.861,353.832 56.689,353.819 56.322 L 353.805 55.917 353.677 56.333 C
   352.840 59.054,351.204 61.044,348.958 62.071 C
   347.218 62.867,343.366 62.575,342.154 61.555 C
   342.009 61.433,341.834 61.333,341.765 61.333 C
   341.323 61.333,339.266 59.158,338.553 57.936 C
   338.111 57.180,337.333 55.445,337.333 55.216 C
   337.333 55.118,337.221 54.823,337.084 54.561 C
   336.947 54.298,336.835 53.924,336.834 53.729 C
   336.834 53.534,336.724 53.113,336.591 52.794 C
   336.458 52.475,336.274 51.659,336.183 50.982 C
   336.091 50.304,335.931 49.238,335.827 48.613 C
   335.564 47.043,335.692 36.460,335.982 35.722 C
   336.096 35.431,336.166 34.956,336.137 34.665 C
   336.094 34.239,336.026 34.374,335.784 35.371 M272.856 35.583 C
   272.841 35.767,272.720 36.217,272.588 36.583 C
   272.240 37.550,272.224 48.324,272.569 49.369 C
   272.699 49.762,272.845 50.233,272.893 50.417 C
   273.045 50.990,272.869 49.301,272.667 48.250 C
   272.405 46.883,272.528 37.464,272.818 36.720 C
   272.932 36.429,272.993 35.979,272.954 35.720 C
   272.910 35.430,272.873 35.378,272.856 35.583 M303.011 36.339 C
   303.005 36.663,303.150 37.355,303.333 37.877 C
   303.517 38.398,303.667 39.038,303.667 39.297 C
   303.667 39.557,303.777 39.915,303.911 40.093 C
   304.046 40.271,304.158 40.703,304.161 41.052 C
   304.164 41.402,304.310 42.040,304.486 42.469 C
   304.662 42.899,304.849 43.617,304.903 44.067 C
   304.957 44.516,305.066 44.966,305.146 45.067 C
   305.226 45.167,305.346 45.624,305.412 46.081 C
   305.478 46.538,305.627 47.101,305.743 47.331 C
   305.859 47.562,305.999 48.152,306.054 48.643 C
   306.110 49.134,306.235 49.632,306.333 49.750 C
   306.431 49.868,306.555 50.366,306.609 50.857 C
   306.663 51.348,306.798 51.900,306.911 52.083 C
   307.024 52.267,307.167 52.806,307.229 53.281 C
   307.291 53.756,307.452 54.356,307.587 54.614 C
   307.721 54.872,307.832 55.346,307.833 55.667 C
   307.834 55.987,307.945 56.461,308.080 56.719 C
   308.215 56.977,308.375 57.577,308.436 58.052 C
   308.498 58.528,308.650 59.065,308.774 59.245 C
   308.898 59.426,309.000 59.862,309.000 60.214 C
   309.000 61.138,309.733 62.667,310.175 62.667 C
   310.889 62.667,311.168 62.366,311.149 61.619 L 311.131 60.917 310.974 61.616 C
   310.750 62.617,309.667 62.795,309.667 61.832 C
   309.667 61.670,309.555 61.323,309.418 61.061 C
   309.281 60.798,309.168 60.335,309.168 60.031 C
   309.167 59.727,309.078 59.314,308.969 59.114 C
   308.860 58.914,308.676 58.300,308.560 57.750 C
   308.347 56.736,308.211 56.177,307.667 54.083 C
   307.288 52.629,307.049 51.675,306.767 50.500 C
   306.320 48.635,306.134 47.897,305.907 47.083 C
   305.409 45.299,305.140 44.220,305.016 43.500 C
   304.945 43.087,304.771 42.375,304.630 41.917 C
   304.304 40.855,304.047 39.864,303.743 38.500 C
   303.273 36.391,303.024 35.656,303.011 36.339 M316.613 39.167 C
   316.278 40.000,315.846 41.529,315.754 42.204 C
   315.695 42.638,315.577 43.036,315.490 43.089 C
   315.404 43.143,315.334 43.388,315.334 43.635 L 315.335 44.083 315.597 43.583 C
   315.740 43.308,315.899 42.708,315.949 42.250 C
   315.999 41.792,316.143 41.251,316.270 41.048 C
   316.396 40.845,316.500 40.493,316.500 40.266 C
   316.500 39.514,316.786 39.661,316.882 40.463 C
   316.933 40.896,317.093 41.475,317.237 41.750 C
   317.380 42.025,317.499 42.512,317.500 42.833 C
   317.501 43.154,317.602 43.608,317.724 43.842 C
   317.846 44.075,317.990 44.638,318.044 45.092 C
   318.098 45.545,318.260 46.142,318.403 46.417 C
   318.547 46.692,318.666 47.179,318.667 47.500 C
   318.668 47.821,318.771 48.279,318.895 48.517 C
   319.020 48.756,319.176 49.317,319.244 49.765 C
   319.311 50.213,319.471 50.782,319.599 51.031 C
   319.728 51.280,319.833 51.696,319.833 51.956 C
   319.833 52.216,319.983 52.855,320.167 53.377 C
   320.350 53.898,320.500 54.518,320.500 54.754 C
   320.500 54.990,320.604 55.386,320.730 55.633 C
   320.857 55.881,321.016 56.496,321.082 57.000 C
   321.149 57.504,321.270 57.999,321.352 58.100 C
   321.433 58.201,321.550 58.651,321.611 59.100 C
   321.672 59.549,321.864 60.217,322.037 60.583 C
   322.503 61.567,322.300 60.466,321.547 57.919 C
   321.411 57.462,321.237 56.749,321.159 56.336 C
   321.019 55.592,320.713 54.372,320.259 52.750 C
   319.981 51.755,319.725 50.744,319.435 49.500 C
   319.318 48.996,319.139 48.321,319.038 48.000 C
   318.937 47.679,318.779 47.079,318.688 46.667 C
   318.596 46.254,318.361 45.317,318.166 44.583 C
   317.971 43.850,317.754 42.950,317.683 42.583 C
   317.613 42.217,317.434 41.504,317.287 41.000 C
   317.140 40.496,316.950 39.821,316.866 39.500 C
   316.765 39.115,316.679 39.002,316.613 39.167 M329.029 40.380 C
   329.013 40.635,328.887 41.113,328.750 41.442 C
   328.612 41.771,328.500 42.255,328.500 42.517 C
   328.500 42.779,328.390 43.276,328.254 43.622 C
   328.032 44.192,327.772 45.238,327.333 47.333 C
   327.247 47.746,327.066 48.458,326.931 48.917 C
   326.678 49.775,326.487 50.588,326.076 52.548 C
   325.945 53.170,325.771 53.808,325.689 53.965 C
   325.606 54.122,325.485 54.587,325.420 55.000 C
   325.354 55.413,325.206 56.087,325.089 56.500 C
   324.973 56.913,324.714 57.962,324.513 58.833 C
   324.080 60.709,323.648 62.190,323.470 62.404 C
   323.233 62.689,322.528 62.213,322.454 61.717 C
   322.387 61.274,322.382 61.278,322.358 61.795 C
   322.319 62.661,323.515 63.015,323.767 62.212 C
   323.818 62.050,323.957 61.729,324.076 61.500 C
   324.194 61.271,324.343 60.727,324.406 60.291 C
   324.469 59.855,324.628 59.292,324.759 59.041 C
   324.891 58.789,324.998 58.244,324.999 57.829 C
   325.000 57.414,325.102 56.926,325.227 56.745 C
   325.353 56.565,325.510 55.969,325.577 55.421 C
   325.645 54.873,325.805 54.276,325.933 54.092 C
   326.062 53.909,326.167 53.492,326.167 53.165 C
   326.167 52.839,326.317 52.145,326.500 51.623 C
   326.683 51.102,326.833 50.425,326.833 50.119 C
   326.833 49.814,326.943 49.418,327.078 49.240 C
   327.213 49.062,327.325 48.671,327.328 48.371 C
   327.331 48.071,327.483 47.398,327.667 46.877 C
   327.850 46.355,328.000 45.654,328.000 45.318 C
   328.000 44.982,328.104 44.621,328.231 44.516 C
   328.358 44.410,328.516 43.824,328.581 43.214 C
   328.646 42.604,328.810 41.937,328.944 41.732 C
   329.096 41.500,329.163 41.090,329.123 40.639 C
   329.086 40.230,329.046 40.118,329.029 40.380 M165.609 41.138 C
   165.219 41.569,165.182 62.167,165.571 62.490 C
   166.179 62.994,167.248 62.635,167.276 61.917 L 167.302 61.250 167.193 61.833 C
   167.089 62.385,167.047 62.417,166.417 62.417 L 165.750 62.417 165.707 51.955 L 165.664 41.494 166.095 41.298 C
   166.693 41.025,167.059 41.295,167.185 42.100 L 167.286 42.750 167.310 41.992 C
   167.345 40.866,166.321 40.352,165.609 41.138 M354.916 43.539 C
   354.782 43.844,354.575 43.935,353.833 44.015 L 352.917 44.112 353.869 44.140 C
   354.816 44.166,355.310 43.847,355.135 43.321 C
   355.105 43.233,355.007 43.331,354.916 43.539 M167.357 44.000 C
   167.345 44.321,167.412 44.861,167.506 45.199 C
   167.600 45.538,167.741 46.288,167.818 46.866 C
   168.982 55.579,174.334 61.152,181.549 61.164 C
   183.429 61.168,187.000 60.163,187.000 59.631 C
   187.000 59.559,187.105 59.500,187.233 59.500 C
   187.823 59.500,190.142 56.342,189.958 55.789 C
   189.924 55.688,189.648 56.099,189.344 56.704 C
   188.559 58.266,187.238 59.534,186.000 59.912 C
   185.771 59.982,185.471 60.133,185.333 60.246 C
   184.378 61.038,178.695 61.026,177.250 60.230 C
   177.067 60.129,176.700 59.994,176.436 59.931 C
   175.216 59.639,172.472 57.355,171.378 55.723 C
   170.506 54.421,170.333 54.125,170.333 53.930 C
   170.333 53.807,170.221 53.614,170.083 53.500 C
   169.946 53.386,169.833 53.133,169.832 52.938 C
   169.832 52.743,169.682 52.358,169.500 52.083 C
   169.318 51.808,169.168 51.386,169.168 51.145 C
   169.167 50.905,169.060 50.619,168.929 50.510 C
   168.796 50.400,168.640 49.839,168.577 49.244 C
   168.514 48.657,168.358 48.090,168.231 47.984 C
   168.079 47.858,168.000 47.433,168.000 46.735 C
   168.000 46.154,167.893 45.357,167.763 44.964 C
   167.632 44.571,167.492 44.063,167.452 43.833 C
   167.395 43.512,167.374 43.550,167.357 44.000 M342.237 44.595 C
   341.680 47.565,342.459 57.526,343.315 58.382 C
   343.417 58.484,343.500 58.651,343.500 58.753 C
   343.500 60.077,347.148 61.738,348.240 60.911 C
   348.418 60.777,348.684 60.667,348.830 60.667 C
   349.124 60.667,350.405 59.431,350.758 58.809 C
   351.430 57.621,351.729 56.984,352.035 56.083 C
   352.499 54.719,352.847 53.234,352.914 52.333 C
   352.945 51.921,352.933 51.808,352.887 52.083 C
   352.841 52.358,352.731 52.672,352.641 52.780 C
   352.552 52.888,352.434 53.413,352.380 53.947 C
   352.326 54.480,352.188 55.067,352.074 55.250 C
   351.960 55.433,351.812 55.846,351.745 56.167 C
   351.679 56.487,351.521 56.898,351.395 57.079 C
   351.270 57.260,351.167 57.537,351.167 57.694 C
   351.167 57.852,351.027 58.079,350.857 58.199 C
   350.687 58.319,350.498 58.576,350.436 58.770 C
   350.250 59.361,348.987 60.487,348.384 60.601 C
   348.079 60.658,347.789 60.771,347.738 60.852 C
   347.619 61.045,346.547 61.044,346.428 60.852 C
   346.378 60.770,346.153 60.658,345.929 60.601 C
   344.985 60.364,344.082 59.512,343.622 58.421 C
   343.546 58.241,343.413 58.049,343.326 57.996 C
   343.240 57.942,343.103 57.547,343.023 57.118 C
   342.943 56.688,342.779 56.218,342.659 56.074 C
   342.426 55.794,342.213 45.818,342.416 44.711 L 342.514 44.171 347.632 44.125 L 352.750 44.078 347.549 44.039 L 342.348 44.000 342.237 44.595 M315.195 44.455 C
   315.179 44.659,315.064 45.109,314.938 45.455 C
   314.812 45.801,314.629 46.458,314.531 46.917 C
   314.434 47.375,314.195 48.387,314.001 49.167 C
   313.469 51.304,313.421 51.747,313.820 50.833 C
   314.000 50.421,314.152 49.781,314.157 49.412 C
   314.162 49.043,314.269 48.593,314.394 48.412 C
   314.519 48.231,314.676 47.639,314.743 47.095 C
   314.810 46.552,314.975 45.939,315.110 45.733 C
   315.258 45.507,315.329 45.105,315.289 44.721 C
   315.252 44.370,315.210 44.251,315.195 44.455 M40.407 46.516 C
   40.356 46.599,40.506 46.667,40.740 46.667 C
   41.251 46.667,41.292 46.572,40.833 46.452 C
   40.650 46.404,40.458 46.433,40.407 46.516 M42.083 47.167 C
   42.140 47.258,42.294 47.333,42.427 47.333 C
   42.559 47.333,42.667 47.258,42.667 47.167 C
   42.667 47.075,42.512 47.000,42.323 47.000 C
   42.135 47.000,42.027 47.075,42.083 47.167 M43.877 47.786 C
   44.131 47.825,44.506 47.824,44.711 47.784 C
   44.916 47.744,44.708 47.713,44.250 47.714 C
   43.792 47.716,43.624 47.748,43.877 47.786 M45.917 48.333 C
   45.854 48.435,46.095 48.499,46.532 48.497 C
   47.057 48.496,47.183 48.451,47.000 48.333 C
   46.673 48.122,46.047 48.122,45.917 48.333 M49.000 48.998 C
   49.413 49.065,49.900 49.061,50.083 48.988 C
   50.298 48.902,50.032 48.858,49.333 48.865 C
   48.288 48.875,48.276 48.879,49.000 48.998 M353.500 49.149 C
   353.109 49.257,353.082 49.335,353.066 50.424 L 353.049 51.583 353.149 50.524 C
   353.242 49.548,353.289 49.448,353.750 49.246 C
   354.290 49.010,354.170 48.963,353.500 49.149 M354.666 49.329 C
   355.002 49.542,354.919 50.351,354.166 54.167 C
   353.816 55.938,353.816 55.948,354.161 55.250 C
   354.343 54.883,354.493 54.262,354.496 53.870 C
   354.498 53.477,354.606 52.903,354.735 52.594 C
   354.864 52.286,354.984 51.482,355.002 50.808 C
   355.047 49.179,355.044 49.166,354.708 49.169 C
   354.461 49.171,354.454 49.195,354.666 49.329 M313.361 51.969 C
   313.346 52.182,313.261 52.491,313.172 52.656 C
   313.005 52.969,312.641 54.461,312.274 56.341 C
   312.157 56.941,311.999 57.534,311.923 57.657 C
   311.801 57.854,311.663 58.418,311.248 60.417 C
   311.155 60.869,311.167 60.889,311.383 60.624 C
   311.514 60.464,311.670 59.913,311.730 59.402 C
   311.790 58.890,311.951 58.256,312.086 57.994 C
   312.222 57.731,312.333 57.304,312.333 57.044 C
   312.333 56.784,312.483 56.145,312.667 55.623 C
   312.850 55.102,313.000 54.398,313.000 54.059 C
   313.000 53.720,313.117 53.313,313.261 53.154 C
   313.434 52.963,313.500 52.650,313.456 52.225 C
   313.419 51.872,313.377 51.757,313.361 51.969 M194.853 52.861 C
   194.832 53.154,194.795 53.236,193.661 55.500 C
   191.077 60.657,186.401 63.384,180.583 63.127 C
   177.996 63.012,175.366 62.087,171.917 60.077 C
   168.896 58.317,167.311 58.580,167.363 60.833 C
   167.370 61.174,167.387 61.190,167.456 60.917 C
   167.502 60.733,167.644 60.435,167.770 60.255 C
   167.897 60.074,168.000 59.764,168.000 59.566 C
   168.000 58.872,169.068 58.823,170.286 59.461 C
   172.831 60.794,173.333 61.078,173.333 61.182 C
   173.333 61.245,173.588 61.344,173.900 61.403 C
   174.212 61.461,174.558 61.619,174.670 61.754 C
   174.782 61.889,174.978 62.000,175.104 62.000 C
   175.231 62.000,175.614 62.150,175.956 62.333 C
   176.299 62.517,176.724 62.667,176.901 62.667 C
   177.079 62.667,177.369 62.798,177.546 62.958 C
   178.023 63.390,184.316 63.396,185.180 62.966 C
   185.493 62.810,186.031 62.638,186.375 62.584 C
   186.719 62.529,187.000 62.425,187.000 62.351 C
   187.000 62.277,187.281 62.095,187.625 61.945 C
   187.969 61.795,188.475 61.522,188.750 61.338 C
   189.025 61.154,189.372 60.947,189.522 60.877 C
   190.178 60.571,192.667 57.927,192.667 57.536 C
   192.667 57.444,192.760 57.332,192.875 57.286 C
   192.990 57.240,193.263 56.839,193.483 56.393 C
   193.703 55.948,193.947 55.471,194.026 55.333 C
   194.105 55.196,194.251 54.896,194.351 54.667 C
   194.451 54.438,194.645 54.015,194.782 53.728 C
   194.919 53.440,194.996 53.065,194.952 52.894 C
   194.887 52.641,194.868 52.635,194.853 52.861 M393.042 54.037 C
   392.715 54.194,392.669 54.338,392.686 55.150 L 392.706 56.083 392.845 55.254 C
   393.028 54.154,393.566 53.756,394.323 54.161 C
   394.632 54.326,394.621 54.616,394.251 56.083 C
   394.138 56.534,394.151 56.559,394.387 56.333 C
   394.744 55.993,394.820 54.199,394.487 53.989 C
   394.173 53.790,393.507 53.812,393.042 54.037 " stroke="none" fill="#abaebb" fill-rule="evenodd"></path><path id="path3" d="M177.333 2.492 C
   176.921 2.571,176.372 2.717,176.114 2.817 C
   175.856 2.918,175.489 3.000,175.298 3.000 C
   174.629 3.000,171.605 4.648,170.556 5.585 C
   169.244 6.755,167.167 9.701,167.167 10.390 C
   167.167 10.469,167.062 10.769,166.934 11.058 C
   166.376 12.320,166.068 13.607,165.495 17.083 C
   164.334 24.124,166.733 31.682,171.609 36.340 C
   173.204 37.862,176.577 40.333,177.062 40.333 C
   177.151 40.333,177.342 40.436,177.487 40.562 C
   177.773 40.810,178.801 41.399,180.500 42.288 C
   181.096 42.600,181.696 42.920,181.833 42.998 C
   181.971 43.076,182.877 43.593,183.848 44.145 C
   189.232 47.211,190.622 49.033,190.544 52.918 C
   190.493 55.425,188.443 59.500,187.233 59.500 C
   187.105 59.500,187.000 59.559,187.000 59.631 C
   187.000 60.163,183.429 61.168,181.549 61.164 C
   175.131 61.154,170.054 56.706,168.426 49.667 C
   168.310 49.163,168.133 48.413,168.033 48.000 C
   167.778 46.944,167.368 44.324,167.214 42.766 C
   167.066 41.271,166.831 40.962,166.095 41.298 L 165.664 41.494 165.707 51.955 L 165.750 62.417 166.413 62.417 L 167.076 62.417 167.260 61.333 C
   167.759 58.393,168.625 58.160,171.917 60.077 C
   181.899 65.893,190.040 63.563,194.603 53.583 C
   194.729 53.308,194.880 52.821,194.940 52.500 C
   194.999 52.179,195.120 51.775,195.209 51.601 C
   195.366 51.294,195.487 50.496,195.844 47.417 C
   196.018 45.918,195.797 43.461,195.320 41.583 C
   195.227 41.217,195.076 40.617,194.985 40.250 C
   194.030 36.428,190.435 31.782,186.574 29.382 C
   185.291 28.584,181.909 26.815,180.250 26.074 C
   179.147 25.582,175.406 23.753,175.250 23.630 C
   175.158 23.558,174.671 23.227,174.167 22.896 C
   172.648 21.896,170.333 19.343,170.333 18.667 C
   170.333 18.581,170.192 18.227,170.019 17.880 C
   168.061 13.959,170.271 7.090,174.000 5.504 C
   174.229 5.406,174.567 5.253,174.750 5.163 C
   178.181 3.477,183.455 4.380,186.277 7.137 C
   187.793 8.617,189.971 12.161,190.007 13.205 C
   190.011 13.321,190.128 13.717,190.267 14.083 C
   190.536 14.793,190.907 16.217,191.178 17.583 C
   191.365 18.527,191.810 20.727,191.928 21.292 C
   192.015 21.706,192.603 21.802,193.178 21.494 C
   193.490 21.327,193.500 21.052,193.500 12.572 L 193.500 3.823 193.140 3.689 C
   192.550 3.471,191.997 3.655,192.019 4.061 C
   192.035 4.343,192.055 4.356,192.115 4.125 C
   192.166 3.931,192.354 3.833,192.679 3.833 L 193.167 3.833 193.167 12.403 C
   193.167 17.116,193.121 21.091,193.066 21.236 C
   192.905 21.654,192.186 21.571,192.104 21.125 C
   192.066 20.919,191.948 20.337,191.843 19.833 C
   191.737 19.329,191.571 18.392,191.474 17.750 C
   191.376 17.108,191.192 16.381,191.065 16.133 C
   190.938 15.886,190.833 15.434,190.833 15.129 C
   190.833 14.824,190.730 14.426,190.605 14.245 C
   190.479 14.065,190.320 13.654,190.251 13.333 C
   189.780 11.138,186.327 6.000,185.322 6.000 C
   185.250 6.000,185.091 5.902,184.970 5.783 C
   183.620 4.453,178.600 3.676,176.250 4.434 C
   172.982 5.487,171.471 6.603,170.285 8.840 C
   169.310 10.677,168.789 14.651,169.334 16.085 C
   169.425 16.325,169.503 16.723,169.506 16.969 C
   169.509 17.215,169.656 17.717,169.833 18.083 C
   170.010 18.450,170.158 18.807,170.161 18.877 C
   170.164 18.946,170.438 19.396,170.771 19.877 C
   172.184 21.921,173.748 23.173,176.667 24.599 C
   177.537 25.024,178.362 25.439,178.500 25.520 C
   178.637 25.602,178.862 25.715,179.000 25.772 C
   181.376 26.755,185.801 29.046,186.917 29.871 C
   189.427 31.726,192.333 34.616,192.333 35.255 C
   192.333 35.330,192.458 35.528,192.610 35.696 C
   193.271 36.427,194.211 38.504,194.859 40.667 C
   195.677 43.398,195.783 48.875,195.064 51.333 C
   194.513 53.222,193.241 56.164,192.662 56.893 C
   192.578 56.998,192.161 57.525,191.734 58.065 C
   190.546 59.568,189.042 60.802,187.583 61.472 C
   187.125 61.682,186.600 61.937,186.417 62.039 C
   186.042 62.247,184.585 62.604,183.083 62.857 C
   182.019 63.036,179.249 62.925,178.417 62.669 C
   176.522 62.087,175.323 61.656,175.167 61.500 C
   175.121 61.454,174.596 61.193,174.000 60.921 C
   173.404 60.648,172.738 60.292,172.519 60.129 C
   172.301 59.966,172.062 59.833,171.989 59.833 C
   171.917 59.833,171.415 59.570,170.874 59.248 C
   168.644 57.919,167.542 58.450,167.106 61.061 C
   166.921 62.174,166.879 62.253,166.454 62.303 L 166.000 62.355 166.000 52.021 C
   166.000 45.250,166.058 41.651,166.168 41.582 C
   166.733 41.234,166.960 41.661,167.080 43.297 C
   167.145 44.188,167.271 45.254,167.360 45.667 C
   167.449 46.079,167.590 46.898,167.674 47.486 C
   167.758 48.075,167.936 48.862,168.070 49.236 C
   168.203 49.611,168.358 50.198,168.414 50.542 C
   168.469 50.885,168.578 51.167,168.655 51.167 C
   168.732 51.167,168.845 51.498,168.906 51.903 C
   168.967 52.308,169.125 52.730,169.258 52.840 C
   169.391 52.950,169.500 53.111,169.500 53.197 C
   169.500 53.482,170.337 55.129,170.587 55.337 C
   170.723 55.449,170.833 55.644,170.833 55.770 C
   170.833 55.897,170.903 56.000,170.989 56.000 C
   171.074 56.000,171.192 56.151,171.250 56.335 C
   171.361 56.683,173.617 59.000,173.846 59.000 C
   173.917 59.000,174.078 59.094,174.203 59.208 C
   176.139 60.988,181.674 61.941,184.547 60.990 C
   186.952 60.194,189.003 58.666,189.607 57.220 C
   189.729 56.928,189.898 56.646,189.983 56.594 C
   190.068 56.541,190.190 56.180,190.254 55.791 C
   190.319 55.402,190.456 54.978,190.561 54.849 C
   190.677 54.706,190.747 53.931,190.743 52.849 C
   190.730 49.624,189.443 47.212,186.944 45.729 C
   186.500 45.465,185.938 45.123,185.694 44.967 C
   185.450 44.811,184.688 44.361,184.000 43.967 C
   183.313 43.574,182.625 43.176,182.473 43.084 C
   182.187 42.911,180.096 41.789,179.295 41.379 C
   179.045 41.250,178.800 41.081,178.751 41.002 C
   178.703 40.923,178.439 40.810,178.165 40.750 C
   177.891 40.689,177.667 40.569,177.667 40.483 C
   177.667 40.396,177.472 40.251,177.233 40.160 C
   176.206 39.770,173.440 37.761,171.857 36.257 C
   170.600 35.062,168.000 31.804,168.000 31.425 C
   168.000 31.381,167.742 30.799,167.427 30.131 C
   166.784 28.769,166.345 27.621,166.227 27.000 C
   166.184 26.771,166.024 25.946,165.872 25.167 C
   165.474 23.131,165.481 17.454,165.882 16.178 C
   166.039 15.680,166.167 15.002,166.167 14.672 C
   166.167 14.342,166.394 13.399,166.671 12.577 C
   166.949 11.756,167.257 10.821,167.356 10.500 C
   167.455 10.179,167.753 9.594,168.018 9.200 C
   168.283 8.806,168.500 8.412,168.500 8.325 C
   168.500 7.835,171.323 5.115,172.396 4.571 C
   172.912 4.310,173.333 4.038,173.333 3.965 C
   173.333 3.893,173.465 3.833,173.625 3.832 C
   173.785 3.832,174.142 3.710,174.417 3.562 C
   174.890 3.306,175.931 3.040,178.000 2.644 C
   179.132 2.428,181.753 2.585,182.901 2.938 C
   184.008 3.277,185.333 3.878,185.333 4.039 C
   185.333 4.109,185.419 4.167,185.523 4.167 C
   185.766 4.167,187.946 5.601,188.497 6.123 C
   190.178 7.717,192.034 7.272,191.973 5.290 C
   191.954 4.650,191.930 4.572,191.884 5.000 C
   191.637 7.299,189.651 7.379,187.667 5.168 C
   187.559 5.048,186.847 4.596,186.094 4.170 C
   185.767 3.985,185.235 3.683,184.912 3.500 C
   183.237 2.551,179.583 2.066,177.333 2.492 M80.375 3.106 C
   80.081 3.231,80.116 4.092,80.429 4.405 C
   80.631 4.607,81.060 4.667,82.312 4.667 C
   83.351 4.667,84.005 4.739,84.133 4.867 C
   84.345 5.079,84.443 59.354,84.232 59.903 C
   84.150 60.118,83.835 60.167,82.519 60.167 C
   80.652 60.167,80.167 60.376,80.167 61.180 C
   80.167 61.565,80.268 61.715,80.625 61.854 C
   81.231 62.092,103.554 61.921,104.750 61.670 C
   105.208 61.573,105.921 61.451,106.333 61.398 C
   110.608 60.848,114.668 57.620,116.374 53.417 C
   117.879 49.710,117.896 42.212,116.406 39.278 C
   116.274 39.019,116.164 38.719,116.160 38.611 C
   116.126 37.564,112.633 33.599,111.167 32.944 C
   111.029 32.882,110.542 32.612,110.083 32.344 C
   109.625 32.075,109.144 31.851,109.015 31.844 C
   107.733 31.784,107.088 30.541,108.135 30.150 C
   109.006 29.825,109.976 29.314,110.174 29.075 C
   110.284 28.942,110.440 28.833,110.520 28.833 C
   110.601 28.833,110.800 28.702,110.963 28.542 C
   111.126 28.381,111.498 28.051,111.790 27.808 C
   116.210 24.126,117.235 14.443,113.777 9.034 C
   112.507 7.047,108.557 4.167,107.102 4.167 C
   106.942 4.167,106.650 4.080,106.453 3.975 C
   105.013 3.204,103.638 3.119,91.833 3.067 C
   85.646 3.040,80.490 3.058,80.375 3.106 M122.453 3.103 C
   122.260 3.296,122.316 4.249,122.533 4.467 C
   122.660 4.594,123.302 4.667,124.294 4.667 C
   125.153 4.667,126.000 4.744,126.178 4.839 C
   126.577 5.053,126.698 59.568,126.300 59.967 C
   126.174 60.093,125.542 60.167,124.587 60.167 C
   123.488 60.167,122.973 60.238,122.704 60.426 C
   122.209 60.773,122.201 61.584,122.690 61.846 C
   123.161 62.098,145.685 61.941,146.917 61.677 C
   147.375 61.579,148.200 61.426,148.750 61.338 C
   154.165 60.470,157.988 56.509,159.372 50.333 C
   159.596 49.331,159.591 43.134,159.365 42.083 C
   158.863 39.752,157.794 37.252,156.832 36.156 C
   154.986 34.055,153.680 33.031,151.709 32.142 C
   150.176 31.450,149.484 31.102,149.409 30.984 C
   149.282 30.786,150.036 30.151,150.509 30.057 C
   150.775 30.003,151.078 29.856,151.183 29.730 C
   151.288 29.603,151.466 29.500,151.579 29.500 C
   151.847 29.500,153.773 28.026,154.438 27.310 C
   158.974 22.435,159.192 12.464,154.866 7.731 C
   152.843 5.518,150.896 4.506,146.667 3.469 C
   145.213 3.113,122.782 2.773,122.453 3.103 M103.833 3.571 C
   104.796 3.737,105.820 3.976,106.109 4.103 C
   106.397 4.230,106.735 4.334,106.859 4.334 C
   107.185 4.335,108.847 5.036,109.329 5.376 C
   109.555 5.536,109.805 5.667,109.883 5.667 C
   110.365 5.667,113.163 8.287,113.632 9.177 C
   113.749 9.400,114.066 9.996,114.337 10.500 C
   114.607 11.004,114.829 11.552,114.831 11.717 C
   114.832 11.882,114.964 12.257,115.124 12.550 C
   115.484 13.208,115.572 21.093,115.225 21.516 C
   115.119 21.645,114.943 22.083,114.835 22.490 C
   114.562 23.511,113.701 25.261,113.208 25.797 C
   113.094 25.922,113.000 26.082,113.000 26.153 C
   113.000 26.259,111.538 27.748,110.775 28.419 C
   110.193 28.931,108.262 30.000,107.920 30.000 C
   107.794 30.000,107.535 30.198,107.345 30.440 C
   106.915 30.987,107.083 31.245,108.167 31.707 C
   109.189 32.142,110.399 32.730,110.500 32.841 C
   110.546 32.892,110.943 33.140,111.382 33.394 C
   113.060 34.364,115.466 37.192,116.025 38.854 C
   116.130 39.163,116.272 39.492,116.343 39.583 C
   116.413 39.675,116.526 40.050,116.595 40.417 C
   116.663 40.783,116.832 41.346,116.971 41.667 C
   117.669 43.283,117.258 50.504,116.330 52.917 C
   116.124 53.453,115.262 55.235,115.120 55.417 C
   115.049 55.508,114.674 55.997,114.287 56.503 C
   113.254 57.855,111.782 59.170,111.042 59.404 C
   110.835 59.469,110.667 59.592,110.667 59.678 C
   110.667 59.763,110.573 59.834,110.458 59.835 C
   110.344 59.836,109.875 60.021,109.417 60.246 C
   108.958 60.472,108.430 60.659,108.242 60.661 C
   108.054 60.664,107.825 60.741,107.734 60.832 C
   107.643 60.923,106.935 61.075,106.159 61.168 C
   105.384 61.262,104.500 61.420,104.194 61.519 C
   103.613 61.707,83.075 61.880,81.375 61.711 C
   80.594 61.634,80.500 61.583,80.500 61.235 C
   80.500 60.664,80.970 60.500,82.601 60.500 C
   83.722 60.500,84.127 60.437,84.362 60.224 C
   84.854 59.779,84.836 4.936,84.344 4.673 C
   84.167 4.578,83.317 4.500,82.456 4.500 C
   80.793 4.500,80.261 4.196,80.560 3.417 C
   80.724 2.991,101.251 3.127,103.833 3.571 M145.750 3.555 C
   146.758 3.714,147.705 3.912,147.854 3.994 C
   148.004 4.076,148.416 4.197,148.771 4.263 C
   149.126 4.329,149.678 4.521,149.998 4.691 C
   150.317 4.861,150.702 5.000,150.852 5.000 C
   151.003 5.000,151.219 5.112,151.333 5.250 C
   151.447 5.387,151.610 5.500,151.694 5.500 C
   152.916 5.500,156.986 10.379,157.002 11.865 C
   157.004 11.985,157.129 12.421,157.281 12.833 C
   157.687 13.939,157.707 20.247,157.307 21.333 C
   157.156 21.746,156.983 22.308,156.923 22.583 C
   156.416 24.908,153.023 28.986,151.219 29.439 C
   151.007 29.493,150.833 29.600,150.833 29.677 C
   150.833 29.755,150.576 29.867,150.261 29.926 C
   149.610 30.048,149.110 30.511,149.194 30.914 C
   149.253 31.201,149.520 31.359,151.882 32.509 C
   154.549 33.807,157.052 36.131,157.572 37.792 C
   157.636 37.998,157.753 38.167,157.831 38.167 C
   157.910 38.167,158.026 38.410,158.089 38.708 C
   158.153 39.006,158.277 39.340,158.366 39.449 C
   158.515 39.635,158.814 40.679,159.169 42.250 C
   159.361 43.102,159.360 49.011,159.168 50.000 C
   158.742 52.190,158.174 53.956,157.716 54.513 C
   157.597 54.658,157.500 54.836,157.500 54.909 C
   157.500 56.211,152.054 60.667,150.462 60.667 C
   150.322 60.667,149.954 60.770,149.645 60.896 C
   149.336 61.022,148.523 61.178,147.838 61.243 C
   147.154 61.308,146.454 61.436,146.282 61.527 C
   145.933 61.714,125.423 61.900,123.719 61.732 C
   121.519 61.515,122.275 60.500,124.638 60.500 C
   125.880 60.500,126.290 60.440,126.529 60.224 C
   126.993 59.804,127.006 5.204,126.542 4.794 C
   126.339 4.614,125.761 4.511,124.640 4.454 C
   122.748 4.358,122.107 3.948,122.786 3.270 C
   123.062 2.993,143.826 3.251,145.750 3.555 M95.875 4.115 C
   96.035 4.157,96.298 4.157,96.458 4.115 C
   96.619 4.073,96.487 4.039,96.167 4.039 C
   95.846 4.039,95.715 4.073,95.875 4.115 M93.537 4.426 L 93.167 4.686 93.169 17.134 C
   93.171 24.043,93.237 29.687,93.319 29.816 C
   93.475 30.062,95.363 29.996,97.333 29.674 C
   98.063 29.555,97.756 29.530,95.833 29.554 L 93.417 29.583 93.374 17.215 C
   93.325 3.187,93.173 4.646,94.716 4.342 L 95.417 4.203 94.662 4.185 C
   94.222 4.174,93.753 4.275,93.537 4.426 M97.167 4.282 C
   98.134 4.446,100.739 5.458,101.250 5.869 C
   101.342 5.943,101.799 6.300,102.266 6.662 C
   103.966 7.981,106.495 12.076,106.502 13.520 C
   106.503 13.738,106.615 14.285,106.752 14.735 C
   107.091 15.854,107.089 19.778,106.748 20.901 C
   106.612 21.351,106.497 21.914,106.494 22.151 C
   106.491 22.389,106.344 22.883,106.167 23.250 C
   105.990 23.617,105.842 23.974,105.839 24.043 C
   105.802 24.866,102.352 28.333,101.570 28.333 C
   101.462 28.333,101.287 28.438,101.181 28.566 C
   101.075 28.694,100.747 28.838,100.452 28.887 C
   100.158 28.936,99.542 29.088,99.083 29.227 L 98.250 29.478 98.869 29.489 C
   99.669 29.503,101.475 28.748,102.943 27.785 C
   104.453 26.794,106.228 24.229,106.657 22.417 C
   106.743 22.050,106.892 21.450,106.987 21.083 C
   107.256 20.042,107.201 15.052,106.912 14.250 C
   106.780 13.883,106.671 13.447,106.669 13.281 C
   106.664 12.699,105.235 9.500,104.980 9.500 C
   104.899 9.500,104.833 9.417,104.833 9.315 C
   104.833 9.100,103.685 7.662,102.890 6.881 C
   101.510 5.524,98.621 4.134,97.297 4.189 C
   96.851 4.208,96.827 4.225,97.167 4.282 M135.576 4.576 L 135.167 4.985 135.167 17.065 C
   135.167 24.511,135.229 29.309,135.329 29.572 C
   135.525 30.089,136.245 30.124,138.917 29.746 L 140.583 29.511 138.059 29.587 C
   135.872 29.653,135.521 29.628,135.434 29.401 C
   135.379 29.257,135.333 23.710,135.333 17.074 L 135.333 5.008 135.764 4.725 C
   136.001 4.570,136.470 4.389,136.806 4.324 L 137.417 4.205 136.701 4.186 C
   136.147 4.171,135.892 4.259,135.576 4.576 M139.083 4.245 C
   139.175 4.273,139.844 4.465,140.570 4.670 C
   143.739 5.567,147.906 9.584,148.000 11.833 C
   148.006 11.971,148.115 12.222,148.243 12.390 C
   148.371 12.559,148.527 13.009,148.589 13.390 C
   148.651 13.772,148.806 14.493,148.934 14.993 C
   149.485 17.148,148.986 21.839,147.994 23.833 C
   146.587 26.660,144.840 28.130,141.667 29.159 C
   140.954 29.390,140.783 29.486,141.167 29.439 C
   143.232 29.189,146.014 27.446,147.167 25.678 C
   147.304 25.467,147.548 25.123,147.708 24.913 C
   147.869 24.703,148.000 24.468,148.001 24.390 C
   148.002 24.313,148.124 24.025,148.274 23.750 C
   150.124 20.344,149.534 13.228,147.086 9.417 C
   145.230 6.528,141.733 4.125,139.464 4.180 C
   139.163 4.187,138.992 4.216,139.083 4.245 M261.333 5.675 C
   257.964 7.754,258.926 13.250,262.660 13.250 C
   267.165 13.250,267.340 5.661,262.842 5.374 C
   262.114 5.328,261.792 5.392,261.333 5.675 M284.195 5.479 C
   284.069 5.559,283.997 5.828,284.028 6.101 L 284.083 6.583 285.529 6.667 C
   286.323 6.712,287.036 6.812,287.112 6.889 C
   287.188 6.965,287.271 11.109,287.296 16.097 L 287.342 25.167 286.950 25.167 C
   286.734 25.167,286.376 24.971,286.154 24.733 C
   285.616 24.154,284.229 23.496,283.594 23.517 L 283.083 23.534 283.583 23.662 C
   284.495 23.896,285.582 24.448,286.002 24.891 C
   286.230 25.131,286.507 25.329,286.617 25.330 C
   286.727 25.332,286.886 25.456,286.970 25.607 C
   287.448 26.462,287.500 25.549,287.500 16.246 C
   287.500 5.381,287.728 6.500,285.511 6.500 C
   284.206 6.500,284.167 6.488,284.167 6.103 C
   284.167 5.832,284.286 5.674,284.542 5.605 C
   285.030 5.474,292.470 5.474,292.958 5.605 L 293.333 5.706 293.333 33.082 C
   293.333 55.314,293.374 60.493,293.550 60.639 C
   293.668 60.737,294.362 60.840,295.091 60.867 C
   296.543 60.921,297.202 61.509,296.051 61.723 C
   295.682 61.792,288.130 61.757,287.625 61.685 C
   287.556 61.675,287.500 61.371,287.500 61.010 C
   287.500 60.255,287.279 60.031,286.903 60.406 C
   286.760 60.549,286.592 60.667,286.530 60.667 C
   286.468 60.668,286.192 60.874,285.917 61.126 C
   283.782 63.081,280.381 62.709,277.911 60.250 C
   276.717 59.062,275.000 56.535,275.000 55.967 C
   275.000 55.882,274.910 55.648,274.799 55.447 C
   274.127 54.230,273.458 51.688,272.922 48.313 C
   272.566 46.076,272.569 39.518,272.927 37.530 C
   273.853 32.382,274.655 30.063,276.417 27.436 C
   277.874 25.264,279.370 24.118,281.333 23.671 L 281.917 23.538 281.323 23.519 C
   279.657 23.465,277.555 25.206,275.609 28.250 C
   275.030 29.155,273.667 32.477,273.667 32.981 C
   273.667 33.152,273.561 33.547,273.431 33.857 C
   273.301 34.168,273.108 34.983,273.001 35.669 C
   272.894 36.355,272.732 37.404,272.640 38.000 C
   272.421 39.417,272.548 48.576,272.795 49.250 C
   272.896 49.525,273.051 50.200,273.138 50.750 C
   273.309 51.822,273.751 53.539,273.996 54.083 C
   274.078 54.267,274.197 54.604,274.260 54.833 C
   275.967 61.044,282.523 64.595,286.121 61.259 C
   286.716 60.708,287.333 60.686,287.333 61.217 C
   287.333 61.975,287.484 62.000,292.013 62.000 C
   296.620 62.000,296.757 61.974,296.638 61.134 C
   296.587 60.773,296.494 60.745,295.083 60.667 L 293.583 60.583 293.542 33.167 C
   293.504 7.625,293.482 5.736,293.219 5.542 C
   292.889 5.299,284.570 5.241,284.195 5.479 M263.918 5.834 C
   266.385 6.958,266.560 11.253,264.197 12.686 C
   262.866 13.493,260.434 12.807,260.061 11.519 C
   260.018 11.371,259.874 11.038,259.741 10.778 C
   259.474 10.256,259.409 8.243,259.654 8.091 C
   259.738 8.039,259.856 7.775,259.916 7.505 C
   260.007 7.091,260.432 6.625,261.424 5.853 C
   261.991 5.411,262.974 5.404,263.918 5.834 M15.760 5.818 C
   15.706 5.904,15.439 6.024,15.165 6.084 C
   14.891 6.144,14.667 6.253,14.667 6.327 C
   14.667 6.401,14.948 6.364,15.291 6.246 C
   15.761 6.085,15.947 6.079,16.039 6.224 C
   16.119 6.350,16.162 6.319,16.164 6.135 C
   16.167 5.803,15.899 5.593,15.760 5.818 M12.685 7.418 C
   12.282 7.738,11.907 8.001,11.852 8.002 C
   11.796 8.003,11.262 8.462,10.667 9.022 C
   10.071 9.582,9.733 9.937,9.917 9.812 C
   10.100 9.686,10.287 9.535,10.333 9.475 C
   10.379 9.415,10.867 9.024,11.417 8.604 C
   11.967 8.185,12.492 7.784,12.583 7.712 C
   13.463 7.020,13.656 6.978,13.755 7.457 C
   13.839 7.863,14.118 8.952,14.257 9.417 C
   14.285 9.508,14.313 9.418,14.320 9.216 C
   14.327 9.014,14.263 8.677,14.177 8.466 C
   14.091 8.256,13.976 7.802,13.920 7.458 C
   13.794 6.687,13.613 6.681,12.685 7.418 M8.248 11.292 C
   6.709 12.817,4.573 15.701,3.832 17.250 C
   3.613 17.708,3.336 18.182,3.217 18.304 C
   3.098 18.425,3.000 18.625,3.000 18.748 C
   3.000 18.872,2.897 19.185,2.771 19.445 C
   2.025 20.979,1.571 22.152,1.298 23.250 C
   1.207 23.617,1.027 24.282,0.899 24.727 C
   0.771 25.173,0.667 25.796,0.667 26.112 C
   0.667 26.857,0.335 28.167,0.147 28.167 C
   0.066 28.167,0.000 28.360,0.000 28.597 C
   0.000 30.184,0.786 27.896,0.817 26.218 C
   0.826 25.742,0.904 25.309,0.990 25.256 C
   1.077 25.203,1.198 24.804,1.259 24.371 C
   1.320 23.938,1.512 23.255,1.685 22.854 C
   1.858 22.453,2.000 21.909,2.000 21.645 C
   2.000 21.382,2.072 21.167,2.160 21.167 C
   2.248 21.167,2.415 20.848,2.530 20.458 C
   2.646 20.069,2.960 19.337,3.229 18.833 C
   3.498 18.329,3.880 17.613,4.078 17.241 C
   4.624 16.218,5.711 14.600,5.952 14.450 C
   6.070 14.377,6.167 14.239,6.167 14.145 C
   6.167 13.892,7.258 12.576,8.534 11.292 C
   9.148 10.673,9.590 10.167,9.516 10.167 C
   9.442 10.167,8.872 10.673,8.248 11.292 M15.209 11.250 C
   15.211 11.617,15.245 11.747,15.285 11.540 C
   15.324 11.332,15.323 11.032,15.281 10.873 C
   15.239 10.714,15.207 10.883,15.209 11.250 M14.381 11.583 C
   14.382 12.042,14.415 12.209,14.453 11.956 C
   14.491 11.703,14.490 11.328,14.451 11.123 C
   14.411 10.918,14.380 11.125,14.381 11.583 M14.506 12.593 C
   14.516 12.873,14.988 13.596,14.989 13.333 C
   14.989 13.196,14.879 12.938,14.745 12.760 C
   14.610 12.582,14.503 12.507,14.506 12.593 M383.583 13.320 C
   383.262 13.445,383.247 13.644,383.167 18.683 L 383.083 23.917 381.583 23.958 C
   379.586 24.014,379.333 24.082,379.333 24.566 C
   379.333 25.191,379.662 25.317,381.426 25.369 L 383.083 25.417 383.170 41.667 C
   383.267 59.868,383.197 58.856,384.488 60.733 C
   386.652 63.878,392.516 62.558,393.434 58.718 C
   393.496 58.461,393.605 58.174,393.677 58.082 C
   393.801 57.921,394.228 56.296,394.497 54.953 C
   394.601 54.434,394.571 54.294,394.323 54.161 C
   393.505 53.723,393.038 54.140,392.823 55.500 C
   392.294 58.851,390.461 61.049,389.661 59.292 C
   389.258 58.405,389.146 26.147,389.545 25.615 C
   389.708 25.397,390.107 25.332,391.482 25.296 C
   392.919 25.258,393.246 25.200,393.398 24.958 C
   393.812 24.301,393.365 24.091,391.316 23.980 C
   390.271 23.924,389.396 23.867,389.371 23.855 C
   389.345 23.843,389.331 21.497,389.338 18.641 C
   389.349 14.539,389.306 13.419,389.134 13.310 C
   388.860 13.136,384.033 13.145,383.583 13.320 M389.127 18.569 C
   389.180 24.671,388.979 24.167,391.354 24.167 C
   393.155 24.167,393.167 24.169,393.167 24.566 C
   393.167 25.091,392.916 25.167,391.167 25.167 C
   390.357 25.167,389.575 25.212,389.430 25.268 C
   389.192 25.359,389.167 27.013,389.167 42.275 L 389.167 59.180 389.531 59.673 C
   390.169 60.535,391.471 60.020,392.105 58.655 C
   392.599 57.592,392.841 56.832,392.987 55.884 C
   393.190 54.557,393.418 54.131,393.886 54.198 C
   394.158 54.237,394.243 54.355,394.223 54.667 C
   394.175 55.419,393.745 57.410,393.557 57.750 C
   393.456 57.933,393.322 58.322,393.259 58.613 C
   393.196 58.905,393.038 59.233,392.906 59.342 C
   392.774 59.451,392.667 59.630,392.667 59.740 C
   392.667 59.849,392.310 60.298,391.875 60.736 C
   389.033 63.600,384.616 62.472,383.772 58.667 C
   383.670 58.206,383.585 51.684,383.551 41.765 C
   383.513 30.479,383.442 25.548,383.316 25.396 C
   383.194 25.249,382.582 25.163,381.443 25.131 L 379.750 25.083 379.697 24.625 L 379.645 24.167 381.372 24.167 C
   383.692 24.167,383.500 24.665,383.500 18.647 C
   383.500 14.709,383.548 13.508,383.708 13.442 C
   383.823 13.394,385.079 13.369,386.500 13.386 L 389.083 13.417 389.127 18.569 M15.007 15.171 C
   15.003 15.403,15.107 15.745,15.238 15.932 C
   15.369 16.119,15.508 16.530,15.548 16.845 C
   15.603 17.288,15.610 17.267,15.580 16.750 C
   15.532 15.924,15.019 14.510,15.007 15.171 M15.667 17.936 C
   15.667 18.028,15.777 18.249,15.911 18.426 C
   16.046 18.604,16.156 18.675,16.156 18.583 C
   16.156 18.492,16.046 18.271,15.911 18.093 C
   15.777 17.915,15.667 17.845,15.667 17.936 M17.505 23.613 C
   17.514 23.848,18.000 24.411,18.000 24.187 C
   18.000 24.071,17.887 23.864,17.750 23.726 C
   17.612 23.589,17.502 23.538,17.505 23.613 M228.714 23.893 C
   227.394 24.563,225.340 26.651,224.946 27.722 C
   224.654 28.516,224.414 27.837,224.333 25.987 L 224.250 24.083 219.917 24.036 C
   214.814 23.981,214.794 23.984,214.865 24.714 L 214.917 25.250 216.333 25.296 C
   217.421 25.332,217.798 25.407,217.958 25.620 C
   218.271 26.035,218.263 60.213,217.950 60.472 C
   217.831 60.571,217.100 60.674,216.325 60.701 C
   214.813 60.754,214.305 61.202,215.099 61.782 C
   215.560 62.119,225.878 62.097,226.159 61.759 C
   226.608 61.218,226.307 60.754,225.472 60.700 C
   224.242 60.620,224.319 61.617,224.376 46.394 L 224.429 32.583 224.853 31.500 C
   227.242 25.404,230.353 24.104,231.224 28.839 C
   231.489 30.278,231.510 31.746,231.466 45.500 L 231.417 60.583 230.417 60.667 C
   229.181 60.770,228.759 61.677,229.845 61.894 C
   230.925 62.110,239.083 62.015,239.401 61.782 C
   240.045 61.311,239.682 60.759,238.667 60.667 L 237.750 60.583 237.707 46.674 C
   237.660 31.104,237.576 32.325,238.868 29.695 C
   240.850 25.655,243.339 24.873,244.357 27.969 C
   244.721 29.078,244.724 29.163,244.789 44.418 C
   244.865 61.760,244.945 60.667,243.591 60.667 C
   242.621 60.667,242.141 61.207,242.654 61.721 C
   242.920 61.987,253.348 62.093,253.832 61.834 C
   254.865 61.281,254.131 60.667,252.436 60.667 C
   250.669 60.667,250.833 62.278,250.833 44.961 C
   250.833 31.734,250.799 29.575,250.583 29.058 C
   250.446 28.729,250.333 28.326,250.333 28.163 C
   250.333 26.975,248.201 24.144,247.130 23.909 C
   246.877 23.853,246.628 23.738,246.575 23.654 C
   246.523 23.569,246.241 23.503,245.948 23.507 L 245.417 23.515 246.083 23.768 C
   246.450 23.907,246.912 24.072,247.111 24.135 C
   247.870 24.377,248.982 25.603,249.707 27.000 C
   250.570 28.661,250.576 28.783,250.626 45.153 C
   250.658 55.984,250.725 60.453,250.856 60.611 C
   250.983 60.764,251.477 60.833,252.437 60.833 C
   253.799 60.833,253.833 60.843,253.833 61.232 C
   253.833 61.809,253.790 61.814,248.770 61.828 C
   243.440 61.842,242.793 61.781,242.866 61.269 C
   242.909 60.969,243.047 60.909,243.790 60.866 C
   245.100 60.791,245.017 61.961,244.961 44.375 L 244.910 28.583 244.525 27.667 C
   243.225 24.566,240.950 25.305,238.662 29.572 C
   238.298 30.251,238.000 30.914,238.000 31.045 C
   238.000 31.175,237.869 31.537,237.708 31.849 C
   237.326 32.593,237.162 60.372,237.539 60.611 C
   237.655 60.684,238.124 60.783,238.582 60.830 C
   239.414 60.917,239.825 61.363,239.345 61.660 C
   239.198 61.750,230.643 61.775,229.697 61.687 C
   229.103 61.632,229.588 60.916,230.250 60.872 C
   230.617 60.848,231.085 60.790,231.292 60.744 L 231.667 60.660 231.659 44.872 C
   231.653 31.558,231.614 28.979,231.412 28.417 C
   231.280 28.050,231.171 27.619,231.169 27.459 C
   231.163 26.755,230.015 25.833,229.144 25.833 C
   227.480 25.833,225.257 28.909,224.326 32.500 C
   224.241 32.826,224.173 39.169,224.171 46.872 L 224.167 60.660 224.542 60.744 C
   224.748 60.789,225.179 60.847,225.499 60.872 C
   226.090 60.917,226.417 61.409,226.020 61.655 C
   225.789 61.797,216.652 61.861,215.699 61.727 C
   215.088 61.641,214.990 61.574,215.033 61.271 C
   215.079 60.946,215.210 60.910,216.632 60.833 C
   217.743 60.773,218.222 60.681,218.330 60.505 C
   218.618 60.038,218.458 25.514,218.167 25.330 C
   218.029 25.243,217.429 25.170,216.833 25.167 C
   215.349 25.160,214.970 25.042,215.033 24.603 C
   215.083 24.254,215.141 24.249,219.620 24.206 L 224.157 24.161 224.203 26.287 C
   224.252 28.524,224.563 29.232,224.996 28.093 C
   225.267 27.380,225.841 26.645,227.086 25.417 C
   228.210 24.308,228.570 24.099,230.083 23.674 L 230.583 23.534 230.000 23.535 C
   229.679 23.535,229.100 23.696,228.714 23.893 M232.417 23.614 C
   232.600 23.655,232.986 23.797,233.275 23.928 C
   233.564 24.059,233.902 24.169,234.025 24.172 C
   234.817 24.192,236.348 26.404,236.956 28.406 C
   237.037 28.676,237.181 28.922,237.275 28.953 C
   237.436 29.007,238.833 27.134,238.833 26.865 C
   238.833 26.797,238.965 26.628,239.126 26.490 C
   239.286 26.353,239.568 26.054,239.751 25.826 C
   240.291 25.154,241.592 24.167,241.938 24.167 C
   242.115 24.167,242.411 24.061,242.596 23.931 C
   242.781 23.801,243.154 23.663,243.424 23.623 L 243.917 23.550 243.396 23.525 C
   242.538 23.484,239.500 25.160,239.500 25.675 C
   239.500 25.727,239.367 25.916,239.206 26.094 C
   239.044 26.271,238.612 26.863,238.247 27.408 C
   237.882 27.954,237.527 28.479,237.458 28.575 C
   237.300 28.796,237.006 28.379,237.003 27.928 C
   236.989 26.197,234.205 23.448,232.537 23.519 C
   232.199 23.534,232.168 23.558,232.417 23.614 M342.775 23.854 C
   339.616 25.102,337.033 29.156,336.308 34.000 C
   336.219 34.596,336.065 35.346,335.965 35.667 C
   335.699 36.521,335.586 47.170,335.827 48.613 C
   335.931 49.238,336.091 50.304,336.183 50.982 C
   336.274 51.659,336.458 52.475,336.591 52.794 C
   336.724 53.113,336.834 53.534,336.834 53.729 C
   336.835 53.924,336.947 54.298,337.084 54.561 C
   337.221 54.823,337.333 55.118,337.333 55.216 C
   337.333 55.445,338.111 57.180,338.553 57.936 C
   339.266 59.158,341.323 61.333,341.765 61.333 C
   341.834 61.333,342.009 61.433,342.154 61.555 C
   343.366 62.575,347.218 62.867,348.958 62.071 C
   351.798 60.772,353.363 58.381,354.187 54.083 C
   355.017 49.748,355.036 49.466,354.500 49.281 C
   353.649 48.987,353.169 49.375,353.153 50.371 C
   353.128 51.921,352.691 54.157,352.035 56.083 C
   351.729 56.984,351.430 57.621,350.758 58.809 C
   350.405 59.431,349.124 60.667,348.830 60.667 C
   348.684 60.667,348.418 60.777,348.240 60.911 C
   347.148 61.738,343.500 60.077,343.500 58.753 C
   343.500 58.651,343.417 58.484,343.315 58.382 C
   342.459 57.526,341.680 47.565,342.237 44.595 L 342.348 44.000 348.487 44.000 C
   353.723 44.000,354.656 43.963,354.832 43.752 C
   355.094 43.436,354.933 38.780,354.583 36.583 C
   354.452 35.758,354.273 34.596,354.186 34.000 C
   353.477 29.168,351.052 24.741,348.700 23.990 C
   348.361 23.882,348.012 23.727,347.925 23.647 C
   347.838 23.566,347.500 23.508,347.175 23.517 L 346.583 23.534 347.083 23.671 C
   349.981 24.464,352.172 26.893,353.017 30.250 C
   353.122 30.666,353.273 31.046,353.354 31.096 C
   353.434 31.146,353.500 31.356,353.500 31.563 C
   353.500 31.770,353.648 32.497,353.829 33.178 C
   354.011 33.859,354.161 34.804,354.163 35.277 C
   354.165 35.750,354.279 36.725,354.417 37.444 C
   354.676 38.801,354.773 43.649,354.542 43.687 C
   354.473 43.699,351.688 43.732,348.352 43.760 C
   344.806 43.790,342.222 43.878,342.129 43.971 C
   341.365 44.735,342.224 57.466,343.111 58.513 C
   343.233 58.658,343.333 58.853,343.333 58.946 C
   343.333 59.273,344.756 60.643,345.452 60.987 C
   347.230 61.865,349.782 60.932,350.915 58.989 C
   351.424 58.116,352.001 56.947,352.006 56.778 C
   352.009 56.671,352.156 56.283,352.333 55.917 C
   352.510 55.550,352.658 55.039,352.661 54.781 C
   352.664 54.523,352.746 54.164,352.842 53.983 C
   352.939 53.803,353.131 52.741,353.269 51.623 C
   353.524 49.554,353.655 49.262,354.250 49.441 C
   354.748 49.591,354.744 49.559,354.444 51.125 C
   354.290 51.927,354.165 52.867,354.166 53.214 C
   354.166 53.560,354.066 54.085,353.943 54.380 C
   353.820 54.675,353.667 55.217,353.603 55.583 C
   353.539 55.950,353.385 56.475,353.261 56.750 C
   353.137 57.025,352.867 57.625,352.660 58.083 C
   351.054 61.642,347.317 63.231,343.605 61.931 C
   342.202 61.440,340.000 60.002,340.000 59.578 C
   340.000 59.520,339.749 59.160,339.442 58.778 C
   338.492 57.594,337.284 54.890,336.998 53.303 C
   336.920 52.873,336.780 52.379,336.686 52.203 C
   336.593 52.028,336.434 51.217,336.334 50.401 C
   336.235 49.584,336.081 48.654,335.994 48.333 C
   335.784 47.561,335.787 38.455,335.998 37.343 C
   336.089 36.865,336.245 35.799,336.345 34.974 C
   336.446 34.149,336.634 33.220,336.764 32.909 C
   336.894 32.598,337.000 32.131,337.000 31.871 C
   337.000 31.610,337.110 31.251,337.245 31.074 C
   337.379 30.896,337.492 30.652,337.495 30.533 C
   337.500 30.302,338.049 28.975,338.370 28.417 C
   338.475 28.233,338.673 27.858,338.810 27.583 C
   339.134 26.930,341.410 24.667,341.743 24.667 C
   341.884 24.667,342.000 24.601,342.000 24.521 C
   342.000 24.376,343.873 23.737,344.667 23.611 C
   344.896 23.575,344.746 23.543,344.333 23.540 C
   343.921 23.537,343.219 23.678,342.775 23.854 M364.167 24.105 C
   362.526 24.990,360.342 27.525,360.331 28.556 C
   360.330 28.663,360.181 29.350,360.000 30.083 C
   358.743 35.173,360.691 39.824,366.564 45.750 C
   368.722 47.928,370.379 49.867,371.286 51.277 C
   374.643 56.492,372.010 62.586,367.163 60.819 C
   366.010 60.398,363.833 58.348,363.833 57.682 C
   363.833 57.582,363.758 57.500,363.667 57.500 C
   363.575 57.500,363.500 57.411,363.500 57.303 C
   363.500 57.194,363.354 56.800,363.176 56.428 C
   362.699 55.429,362.338 54.377,362.084 53.250 C
   361.961 52.700,361.780 51.950,361.684 51.583 C
   361.587 51.217,361.506 50.528,361.504 50.054 C
   361.499 48.878,361.000 48.421,360.097 48.768 C
   359.863 48.858,359.833 49.587,359.833 55.272 L 359.833 61.675 360.272 61.842 C
   360.847 62.061,361.500 61.662,361.500 61.091 C
   361.500 60.111,362.079 59.986,363.222 60.721 C
   364.743 61.699,364.898 61.779,366.017 62.157 C
   369.878 63.460,374.208 61.834,375.875 58.453 C
   377.744 54.665,376.990 47.631,374.396 44.654 C
   374.270 44.509,374.167 44.332,374.167 44.261 C
   374.167 44.190,373.998 43.925,373.792 43.672 C
   373.585 43.420,373.304 43.040,373.167 42.829 C
   373.029 42.618,372.804 42.325,372.667 42.179 C
   372.529 42.033,372.242 41.689,372.028 41.415 C
   371.576 40.834,370.151 39.350,368.643 37.890 C
   365.531 34.875,364.138 32.903,363.816 31.056 C
   363.535 29.442,363.812 26.865,364.306 26.503 C
   364.371 26.456,364.560 26.204,364.725 25.945 C
   366.458 23.223,371.040 25.230,372.653 29.417 C
   372.865 29.967,373.142 30.663,373.269 30.964 C
   373.396 31.265,373.501 31.678,373.503 31.881 C
   373.511 33.021,374.112 34.750,374.513 34.790 C
   374.900 34.828,374.902 34.824,374.560 34.677 C
   374.315 34.572,374.176 34.327,374.114 33.887 C
   373.950 32.726,373.500 30.784,373.351 30.599 C
   373.271 30.499,373.153 30.174,373.090 29.877 C
   373.026 29.580,372.794 29.018,372.573 28.627 C
   372.353 28.236,372.099 27.750,372.009 27.546 C
   371.805 27.084,369.873 25.167,369.610 25.166 C
   369.504 25.165,369.192 25.039,368.917 24.885 C
   366.389 23.469,363.369 25.931,363.339 29.432 C
   363.316 32.124,364.387 33.855,369.000 38.578 C
   372.264 41.920,372.010 41.648,372.588 42.423 C
   372.860 42.786,373.122 43.121,373.171 43.167 C
   373.466 43.439,374.742 45.561,375.176 46.500 C
   375.282 46.729,375.436 47.029,375.519 47.167 C
   375.699 47.468,375.695 47.455,376.139 48.917 C
   376.510 50.139,376.911 53.092,376.762 53.508 C
   376.715 53.641,376.605 54.468,376.518 55.346 C
   376.273 57.832,374.887 60.286,373.255 61.121 C
   372.977 61.263,372.712 61.430,372.667 61.492 C
   372.076 62.283,368.176 62.580,366.263 61.980 C
   365.070 61.605,363.500 60.786,362.870 60.208 C
   362.308 59.693,361.664 59.987,361.319 60.917 C
   361.124 61.442,360.975 61.595,360.617 61.636 L 360.163 61.689 360.206 55.303 C
   360.250 48.951,360.252 48.916,360.601 48.867 C
   361.066 48.800,361.279 49.371,361.421 51.059 C
   361.482 51.779,361.637 52.621,361.766 52.929 C
   361.895 53.237,362.000 53.649,362.000 53.844 C
   362.000 54.040,362.103 54.436,362.230 54.725 C
   362.356 55.014,362.580 55.588,362.728 56.001 C
   364.818 61.847,370.595 63.251,372.667 58.416 C
   373.076 57.463,373.080 54.724,372.675 53.667 C
   371.611 50.887,370.478 49.394,365.824 44.637 C
   364.867 43.659,363.949 42.684,363.786 42.471 C
   362.396 40.663,361.236 38.771,361.065 38.036 C
   361.008 37.787,360.862 37.425,360.742 37.232 C
   360.239 36.424,359.777 32.848,360.001 31.500 C
   360.444 28.839,360.561 28.412,361.119 27.444 C
   361.991 25.931,363.840 24.167,364.555 24.167 C
   364.667 24.167,364.911 24.061,365.096 23.931 C
   365.281 23.801,365.654 23.663,365.924 23.623 C
   366.384 23.555,366.378 23.549,365.833 23.535 C
   365.462 23.526,364.856 23.733,364.167 24.105 M368.667 23.673 C
   369.673 23.845,370.874 24.183,371.101 24.358 C
   372.614 25.530,373.827 25.562,374.227 24.440 C
   374.310 24.207,374.459 24.147,374.826 24.201 C
   375.096 24.241,375.278 24.212,375.231 24.137 C
   375.022 23.798,374.352 24.028,373.928 24.583 C
   373.683 24.904,373.404 25.167,373.308 25.167 C
   373.082 25.167,371.989 24.667,371.667 24.416 C
   371.177 24.036,369.371 23.500,368.650 23.522 L 367.917 23.545 368.667 23.673 M256.542 24.109 C
   256.276 24.218,256.271 24.871,256.533 25.133 C
   256.652 25.252,257.223 25.333,257.936 25.333 C
   258.598 25.333,259.258 25.379,259.403 25.434 C
   259.641 25.526,259.667 27.230,259.667 43.015 L 259.667 60.494 259.292 60.580 C
   259.085 60.627,258.354 60.685,257.667 60.708 C
   256.250 60.756,255.698 61.433,256.750 61.833 C
   257.506 62.121,268.582 62.053,268.826 61.759 C
   269.402 61.065,269.002 60.755,267.417 60.667 L 265.917 60.583 265.833 42.333 L 265.750 24.083 261.250 24.053 C
   258.775 24.037,256.656 24.062,256.542 24.109 M297.869 24.198 C
   297.246 24.820,297.805 25.333,299.105 25.333 C
   300.352 25.333,300.602 25.529,300.761 26.627 C
   300.824 27.061,300.942 27.545,301.024 27.702 C
   301.105 27.859,301.278 28.497,301.408 29.119 C
   301.678 30.411,301.931 31.422,302.254 32.500 C
   302.378 32.912,302.530 33.585,302.593 33.994 C
   302.656 34.403,302.768 34.853,302.841 34.994 C
   302.971 35.246,303.324 36.623,303.761 38.583 C
   304.044 39.858,304.307 40.866,304.630 41.917 C
   304.771 42.375,304.945 43.087,305.016 43.500 C
   305.140 44.220,305.409 45.299,305.907 47.083 C
   306.134 47.897,306.320 48.635,306.767 50.500 C
   307.049 51.675,307.288 52.629,307.667 54.083 C
   308.211 56.177,308.347 56.736,308.560 57.750 C
   308.676 58.300,308.860 58.914,308.969 59.114 C
   309.078 59.314,309.167 59.727,309.168 60.031 C
   309.168 60.335,309.281 60.798,309.418 61.061 C
   309.555 61.323,309.667 61.670,309.667 61.832 C
   309.667 62.967,310.761 62.585,310.997 61.367 C
   311.099 60.844,311.247 60.258,311.327 60.063 C
   311.407 59.869,311.526 59.344,311.592 58.897 C
   311.657 58.449,311.803 57.896,311.917 57.667 C
   312.030 57.438,312.177 56.875,312.242 56.417 C
   312.307 55.958,312.458 55.246,312.576 54.833 C
   312.894 53.730,313.160 52.710,313.422 51.583 C
   313.551 51.033,313.803 49.983,313.984 49.250 C
   314.164 48.517,314.397 47.542,314.500 47.083 C
   314.604 46.625,314.794 45.913,314.924 45.500 C
   315.053 45.087,315.209 44.413,315.269 44.000 C
   315.329 43.587,315.440 43.171,315.515 43.075 C
   315.591 42.979,315.700 42.566,315.759 42.158 C
   315.879 41.331,316.483 39.330,316.682 39.103 C
   316.752 39.023,316.859 39.248,316.919 39.603 C
   316.979 39.959,317.165 40.700,317.334 41.250 C
   317.502 41.800,317.678 42.475,317.725 42.750 C
   317.771 43.025,317.969 43.850,318.165 44.583 C
   318.361 45.317,318.596 46.254,318.688 46.667 C
   318.779 47.079,318.937 47.679,319.038 48.000 C
   319.139 48.321,319.318 48.996,319.435 49.500 C
   319.725 50.744,319.981 51.755,320.259 52.750 C
   320.713 54.372,321.019 55.592,321.159 56.336 C
   321.237 56.749,321.411 57.462,321.547 57.919 C
   321.964 59.329,322.184 60.211,322.371 61.223 C
   322.513 61.991,322.634 62.235,322.947 62.377 C
   323.541 62.648,323.735 62.210,324.513 58.833 C
   324.714 57.962,324.973 56.913,325.089 56.500 C
   325.206 56.087,325.354 55.413,325.420 55.000 C
   325.485 54.587,325.606 54.122,325.689 53.965 C
   325.771 53.808,325.945 53.170,326.076 52.548 C
   326.487 50.588,326.678 49.775,326.931 48.917 C
   327.066 48.458,327.247 47.746,327.333 47.333 C
   327.772 45.238,328.032 44.192,328.254 43.622 C
   328.390 43.276,328.500 42.765,328.500 42.486 C
   328.500 42.206,328.576 41.836,328.669 41.662 C
   328.762 41.488,328.945 40.837,329.075 40.215 C
   329.205 39.592,329.386 38.783,329.478 38.417 C
   329.932 36.600,330.032 36.176,330.227 35.250 C
   330.343 34.700,330.526 34.086,330.635 33.886 C
   330.744 33.686,330.833 33.328,330.833 33.091 C
   330.833 32.854,330.990 32.080,331.181 31.371 C
   332.448 26.683,332.733 26.187,334.500 25.592 C
   335.818 25.149,336.354 24.402,335.570 24.101 C
   335.226 23.969,326.441 23.969,326.097 24.101 C
   325.204 24.444,325.986 25.333,327.179 25.333 C
   328.071 25.333,328.169 25.376,328.896 26.072 L 329.667 26.811 329.667 28.394 C
   329.667 29.266,329.583 30.189,329.481 30.447 C
   329.312 30.875,329.041 31.962,328.410 34.750 C
   328.286 35.300,328.027 36.387,327.835 37.167 C
   327.643 37.946,327.380 39.128,327.251 39.794 C
   327.122 40.461,326.964 41.090,326.900 41.194 C
   326.836 41.297,326.687 41.840,326.569 42.399 C
   326.368 43.351,325.957 45.144,325.496 47.083 C
   325.247 48.134,325.049 48.353,324.912 47.732 C
   324.853 47.467,324.672 46.762,324.509 46.167 C
   324.106 44.697,323.571 42.535,323.169 40.750 C
   322.975 39.889,322.583 38.439,322.425 38.000 C
   322.376 37.862,322.363 38.012,322.396 38.333 C
   322.467 39.014,322.937 41.028,323.193 41.750 C
   323.290 42.025,323.429 42.625,323.500 43.083 C
   323.572 43.542,323.713 44.104,323.814 44.333 C
   323.915 44.563,324.065 45.163,324.148 45.667 C
   324.284 46.494,324.416 46.956,324.853 48.125 C
   325.066 48.696,325.301 48.576,325.502 47.792 C
   325.602 47.402,325.792 46.801,325.925 46.455 C
   326.058 46.109,326.166 45.659,326.164 45.455 C
   326.162 45.041,326.722 42.658,327.094 41.500 C
   327.227 41.087,327.336 40.563,327.336 40.333 C
   327.337 40.104,327.487 39.441,327.669 38.860 C
   327.851 38.279,328.000 37.562,328.000 37.267 C
   328.000 36.972,328.110 36.585,328.245 36.407 C
   328.379 36.229,328.492 35.846,328.495 35.557 C
   328.498 35.267,328.649 34.554,328.831 33.973 C
   329.013 33.392,329.163 32.720,329.165 32.479 C
   329.166 32.238,329.272 31.788,329.401 31.479 C
   330.736 28.283,329.725 25.167,327.353 25.167 C
   326.325 25.167,325.873 24.905,326.059 24.419 C
   326.231 23.971,335.440 24.048,335.583 24.499 C
   335.723 24.941,335.561 25.167,335.103 25.167 C
   333.996 25.167,332.022 26.797,332.006 27.725 C
   332.002 27.894,331.891 28.269,331.757 28.558 C
   331.509 29.095,331.160 30.403,330.750 32.333 C
   330.623 32.929,330.402 33.827,330.260 34.328 C
   330.117 34.829,330.000 35.406,330.000 35.610 C
   330.000 35.814,329.929 36.024,329.843 36.077 C
   329.757 36.131,329.638 36.529,329.580 36.962 C
   329.522 37.395,329.375 38.014,329.254 38.336 C
   329.133 38.659,328.980 39.354,328.914 39.880 C
   328.848 40.407,328.732 40.876,328.657 40.923 C
   328.581 40.970,328.471 41.362,328.412 41.796 C
   328.354 42.229,328.162 43.015,327.986 43.542 C
   327.810 44.070,327.667 44.717,327.667 44.980 C
   327.667 45.244,327.561 45.712,327.433 46.021 C
   327.304 46.330,327.144 46.958,327.078 47.417 C
   327.012 47.875,326.897 48.328,326.822 48.422 C
   326.747 48.517,326.611 49.057,326.520 49.622 C
   326.429 50.186,326.279 50.790,326.187 50.962 C
   326.095 51.135,325.966 51.677,325.901 52.168 C
   325.836 52.659,325.682 53.253,325.559 53.488 C
   325.436 53.724,325.335 54.113,325.334 54.353 C
   325.334 54.593,325.183 55.259,325.000 55.833 C
   324.817 56.407,324.667 57.114,324.667 57.405 C
   324.667 57.696,324.589 58.011,324.493 58.107 C
   324.398 58.202,324.239 58.799,324.141 59.432 C
   324.043 60.065,323.859 60.786,323.731 61.033 C
   323.604 61.281,323.500 61.675,323.500 61.908 C
   323.500 62.864,322.754 62.162,322.588 61.050 C
   322.522 60.610,322.363 59.997,322.234 59.688 C
   322.105 59.379,322.000 58.960,322.000 58.758 C
   322.000 58.555,321.860 57.908,321.689 57.320 C
   321.227 55.735,321.000 54.846,320.818 53.917 C
   320.728 53.458,320.584 52.933,320.497 52.750 C
   320.351 52.442,320.073 51.308,319.640 49.250 C
   319.544 48.792,319.364 48.177,319.240 47.884 C
   319.115 47.591,318.963 46.970,318.902 46.505 C
   318.840 46.040,318.729 45.622,318.654 45.576 C
   318.580 45.530,318.473 45.138,318.416 44.704 C
   318.359 44.271,318.209 43.617,318.082 43.250 C
   317.955 42.883,317.776 42.208,317.684 41.750 C
   317.525 40.961,317.075 39.195,316.901 38.679 C
   316.657 37.952,315.783 40.455,315.102 43.833 C
   315.000 44.337,314.863 44.832,314.798 44.933 C
   314.732 45.033,314.632 45.445,314.574 45.849 C
   314.517 46.253,314.326 47.015,314.151 47.542 C
   313.976 48.070,313.833 48.717,313.833 48.982 C
   313.833 49.246,313.721 49.677,313.584 49.939 C
   313.447 50.202,313.332 50.642,313.327 50.917 C
   313.322 51.192,313.206 51.717,313.068 52.083 C
   312.930 52.450,312.750 53.125,312.667 53.583 C
   312.509 54.457,312.142 56.008,311.760 57.417 C
   311.434 58.617,311.196 59.590,310.903 60.913 C
   310.669 61.976,310.476 62.324,310.120 62.331 C
   310.048 62.332,309.965 62.240,309.934 62.125 C
   309.404 60.132,309.008 58.520,308.745 57.285 C
   308.613 56.663,308.436 56.026,308.352 55.869 C
   308.268 55.712,308.144 55.210,308.075 54.753 C
   308.007 54.296,307.849 53.680,307.725 53.383 C
   307.601 53.087,307.500 52.659,307.500 52.433 C
   307.500 52.207,307.412 51.811,307.304 51.553 C
   307.195 51.294,307.015 50.633,306.902 50.083 C
   306.789 49.533,306.605 48.746,306.493 48.333 C
   305.531 44.786,305.298 43.861,304.602 40.853 C
   304.466 40.268,304.279 39.593,304.185 39.353 C
   304.090 39.113,303.940 38.542,303.850 38.083 C
   303.644 37.038,303.383 36.034,302.981 34.738 C
   302.808 34.182,302.667 33.523,302.667 33.275 C
   302.667 33.026,302.559 32.565,302.428 32.251 C
   302.296 31.937,302.136 31.328,302.072 30.899 C
   302.007 30.469,301.853 29.922,301.728 29.684 C
   301.604 29.445,301.501 28.988,301.500 28.667 C
   301.499 28.346,301.388 27.872,301.253 27.614 C
   301.119 27.356,300.960 26.776,300.900 26.326 C
   300.760 25.270,300.647 25.192,299.159 25.133 C
   297.958 25.085,297.917 25.069,297.917 24.667 L 297.917 24.250 303.803 24.206 L 309.689 24.162 309.636 24.623 C
   309.585 25.072,309.555 25.084,308.417 25.126 C
   306.340 25.201,306.095 25.605,306.916 27.600 C
   307.033 27.885,307.177 28.447,307.236 28.850 C
   307.295 29.253,307.449 29.958,307.579 30.417 C
   307.708 30.875,307.894 31.606,307.991 32.042 C
   308.397 33.868,308.538 34.460,308.756 35.250 C
   309.146 36.664,309.509 38.211,309.657 39.083 C
   309.734 39.542,309.880 40.104,309.981 40.333 C
   310.082 40.563,310.232 41.162,310.315 41.667 C
   310.398 42.171,310.541 42.808,310.634 43.083 C
   310.861 43.757,311.358 45.798,311.447 46.417 C
   311.585 47.384,311.670 47.646,311.929 47.905 C
   312.410 48.386,312.635 48.211,312.824 47.208 C
   312.923 46.681,313.116 45.913,313.253 45.500 C
   313.390 45.087,313.501 44.591,313.501 44.397 C
   313.500 44.202,313.650 43.574,313.833 43.000 C
   314.017 42.426,314.167 41.736,314.167 41.467 C
   314.167 41.198,314.259 40.814,314.371 40.614 C
   314.484 40.414,314.665 39.800,314.774 39.250 C
   315.081 37.705,315.336 36.631,315.527 36.083 C
   315.623 35.808,315.802 35.096,315.924 34.500 C
   316.046 33.904,316.225 33.098,316.323 32.708 C
   316.420 32.319,316.578 31.681,316.674 31.292 C
   316.770 30.902,316.998 29.983,317.181 29.250 C
   317.365 28.517,317.618 27.429,317.743 26.833 C
   318.359 23.917,318.873 23.550,319.252 25.756 C
   319.314 26.119,319.470 26.792,319.597 27.250 C
   319.910 28.373,320.627 31.272,320.998 32.917 C
   321.101 33.375,321.290 34.125,321.419 34.583 C
   321.547 35.042,321.696 35.697,321.750 36.040 C
   321.804 36.382,321.960 36.907,322.097 37.206 L 322.347 37.750 322.266 37.250 C
   322.191 36.789,321.873 35.429,321.253 32.917 C
   321.117 32.367,320.858 31.317,320.677 30.583 C
   320.192 28.617,319.996 27.844,319.748 26.917 C
   319.626 26.458,319.441 25.660,319.338 25.142 C
   319.154 24.223,318.807 23.861,318.380 24.145 C
   318.191 24.271,317.756 25.597,317.526 26.750 C
   317.434 27.208,317.279 27.902,317.180 28.292 C
   316.728 30.074,316.630 30.487,316.408 31.548 C
   316.278 32.170,316.096 32.821,316.003 32.995 C
   315.909 33.169,315.833 33.544,315.833 33.828 C
   315.833 34.112,315.726 34.601,315.595 34.916 C
   315.463 35.230,315.304 35.846,315.241 36.285 C
   315.177 36.724,315.022 37.336,314.896 37.645 C
   314.770 37.954,314.667 38.385,314.667 38.603 C
   314.667 38.821,314.527 39.430,314.356 39.958 C
   314.184 40.485,313.957 41.329,313.851 41.833 C
   313.745 42.337,313.587 43.087,313.500 43.500 C
   313.414 43.913,313.231 44.625,313.095 45.083 C
   312.959 45.542,312.788 46.248,312.715 46.653 C
   312.476 47.988,311.781 47.616,311.588 46.050 C
   311.522 45.518,311.409 45.008,311.337 44.917 C
   311.266 44.825,311.132 44.337,311.040 43.833 C
   310.848 42.780,310.401 40.926,310.077 39.833 C
   309.955 39.421,309.803 38.746,309.741 38.333 C
   309.679 37.921,309.564 37.462,309.486 37.313 C
   309.408 37.165,309.270 36.640,309.179 36.147 C
   309.005 35.202,308.633 33.644,308.249 32.250 C
   308.122 31.792,307.935 31.042,307.832 30.583 C
   307.284 28.134,307.181 27.702,307.015 27.168 C
   306.915 26.848,306.833 26.307,306.833 25.966 L 306.833 25.346 308.292 25.298 L 309.750 25.250 309.750 24.667 L 309.750 24.083 303.910 24.040 C
   299.508 24.007,298.021 24.046,297.869 24.198 M265.626 42.317 C
   265.656 55.008,265.722 60.449,265.849 60.602 C
   265.964 60.741,266.526 60.838,267.390 60.869 C
   268.714 60.915,268.750 60.927,268.750 61.331 L 268.750 61.745 263.096 61.779 C
   256.794 61.817,256.458 61.791,256.533 61.269 C
   256.579 60.947,256.715 60.909,258.113 60.832 C
   258.954 60.785,259.689 60.700,259.746 60.643 C
   259.881 60.508,259.884 25.551,259.749 25.333 C
   259.693 25.241,259.108 25.166,258.448 25.164 C
   256.968 25.162,256.500 25.044,256.500 24.675 C
   256.500 24.175,256.644 24.161,261.155 24.206 L 265.583 24.250 265.626 42.317 M282.417 24.642 C
   281.488 24.946,280.167 26.118,280.167 26.637 C
   280.167 26.723,280.062 26.879,279.934 26.986 C
   279.806 27.092,279.649 27.457,279.586 27.798 C
   279.522 28.138,279.403 28.498,279.321 28.598 C
   278.915 29.089,278.815 32.543,278.867 44.167 L 278.921 56.250 279.305 57.333 C
   280.848 61.682,283.226 62.607,285.792 59.857 C
   286.181 59.439,286.500 59.025,286.500 58.937 C
   286.500 58.848,286.601 58.658,286.725 58.513 C
   287.557 57.540,287.986 31.759,287.220 28.701 C
   286.553 26.033,284.212 24.055,282.417 24.642 M344.750 24.743 C
   343.974 25.145,342.849 26.491,342.729 27.161 C
   342.671 27.485,342.560 27.831,342.482 27.930 C
   342.000 28.541,341.739 42.172,342.200 42.633 C
   342.488 42.921,348.161 42.913,348.555 42.624 C
   349.223 42.136,348.789 27.342,348.083 26.500 C
   347.967 26.363,347.821 26.081,347.758 25.875 C
   347.460 24.896,345.719 24.241,344.750 24.743 M284.148 25.042 C
   287.217 26.539,287.333 27.197,287.330 43.083 C
   287.327 56.333,287.270 57.315,286.427 58.651 C
   283.888 62.672,281.016 61.895,279.398 56.750 C
   278.929 55.260,278.909 30.303,279.375 29.271 C
   279.535 28.916,279.667 28.477,279.667 28.294 C
   279.667 27.378,281.033 25.547,282.081 25.059 C
   283.113 24.579,283.198 24.578,284.148 25.042 M346.703 25.083 C
   348.285 26.159,348.532 27.543,348.613 35.794 L 348.677 42.338 348.244 42.502 C
   347.508 42.782,342.580 42.714,342.336 42.420 C
   341.897 41.891,342.215 29.309,342.704 27.863 C
   343.569 25.306,345.279 24.116,346.703 25.083 M21.000 30.576 C
   21.000 30.643,21.150 30.888,21.333 31.121 C
   21.517 31.354,21.667 31.490,21.667 31.424 C
   21.667 31.357,21.517 31.112,21.333 30.879 C
   21.150 30.646,21.000 30.510,21.000 30.576 M375.212 31.333 C
   375.212 31.746,375.245 31.915,375.284 31.708 C
   375.324 31.502,375.324 31.165,375.284 30.958 C
   375.245 30.752,375.212 30.921,375.212 31.333 M93.278 31.278 C
   93.217 31.339,93.167 37.862,93.167 45.774 L 93.167 60.159 93.542 60.397 C
   93.916 60.636,97.927 60.535,99.750 60.242 C
   103.226 59.683,106.338 56.562,107.550 52.417 C
   108.310 49.821,108.581 45.009,108.107 42.541 C
   107.971 41.831,107.812 40.916,107.756 40.508 C
   107.699 40.100,107.591 39.688,107.516 39.592 C
   107.441 39.495,107.335 39.176,107.279 38.881 C
   106.668 35.652,102.160 31.701,99.041 31.661 C
   98.789 31.657,98.133 31.546,97.583 31.413 C
   96.574 31.170,93.483 31.072,93.278 31.278 M135.339 31.489 C
   135.109 31.919,135.100 59.468,135.329 60.072 L 135.492 60.500 137.704 60.499 C
   139.948 60.498,142.841 60.168,143.213 59.870 C
   143.325 59.781,143.604 59.651,143.833 59.582 C
   145.670 59.028,149.333 54.910,149.333 53.400 C
   149.333 53.262,149.449 52.926,149.590 52.653 C
   149.732 52.379,149.922 51.652,150.013 51.036 C
   150.105 50.420,150.245 49.504,150.326 49.000 C
   150.723 46.507,150.313 41.566,149.510 39.167 C
   148.081 34.900,145.156 32.310,141.025 31.654 C
   140.507 31.571,139.746 31.431,139.333 31.343 C
   137.898 31.034,135.537 31.120,135.339 31.489 M96.917 31.586 C
   97.650 31.718,98.469 31.828,98.737 31.830 C
   100.238 31.840,103.234 33.367,104.721 34.880 C
   108.861 39.091,109.335 50.544,105.596 56.002 C
   103.421 59.175,101.474 60.073,96.750 60.079 L 93.417 60.083 93.374 45.917 C
   93.350 38.125,93.366 31.656,93.410 31.542 C
   93.518 31.259,95.224 31.280,96.917 31.586 M139.250 31.593 C
   144.348 32.326,146.953 34.033,148.743 37.812 C
   148.976 38.304,149.167 38.829,149.168 38.978 C
   149.168 39.128,149.279 39.462,149.415 39.722 C
   149.685 40.239,149.902 41.660,150.192 44.812 C
   150.537 48.549,149.781 52.378,148.090 55.462 C
   147.156 57.166,145.350 58.669,142.917 59.769 C
   142.031 60.169,135.714 60.343,135.480 59.974 C
   135.243 59.600,135.295 31.771,135.533 31.533 C
   135.807 31.260,137.069 31.280,139.250 31.593 M22.241 32.510 C
   22.567 33.005,22.833 33.480,22.833 33.566 C
   22.833 33.652,22.880 33.676,22.937 33.619 C
   23.062 33.493,22.293 32.145,21.918 31.834 C
   21.770 31.712,21.916 32.016,22.241 32.510 M58.998 32.338 C
   58.788 32.471,58.795 32.495,59.042 32.497 C
   59.291 32.500,59.338 32.686,59.366 33.792 L 59.400 35.083 59.457 33.873 C
   59.521 32.532,59.389 32.091,58.998 32.338 M375.141 34.083 C
   375.108 34.496,375.138 34.833,375.207 34.833 C
   375.277 34.833,375.333 34.496,375.333 34.083 C
   375.333 33.671,375.303 33.333,375.267 33.333 C
   375.230 33.333,375.173 33.671,375.141 34.083 M23.350 34.031 C
   23.457 34.232,23.608 34.475,23.685 34.573 C
   24.347 35.411,24.657 35.718,24.661 35.542 C
   24.664 35.427,24.604 35.333,24.528 35.333 C
   24.452 35.333,24.146 34.958,23.849 34.500 C
   23.301 33.654,22.994 33.365,23.350 34.031 M0.000 34.903 C
   0.000 35.124,0.079 35.332,0.175 35.364 C
   0.417 35.445,0.657 36.564,0.669 37.667 C
   0.674 38.171,0.786 38.771,0.917 39.000 C
   1.048 39.229,1.158 39.626,1.161 39.882 C
   1.164 40.137,1.317 40.641,1.500 41.000 C
   1.683 41.359,1.833 41.776,1.833 41.927 C
   1.833 42.535,3.654 46.178,4.968 48.196 C
   7.439 51.994,10.212 54.708,14.289 57.319 C
   15.328 57.984,18.344 59.500,18.640 59.505 C
   18.792 59.508,19.062 59.621,19.240 59.755 C
   19.418 59.890,19.719 60.000,19.909 60.000 C
   20.099 60.000,20.535 60.150,20.877 60.333 C
   21.219 60.517,21.728 60.667,22.008 60.667 C
   22.288 60.667,22.741 60.783,23.016 60.925 C
   23.291 61.067,24.168 61.253,24.966 61.338 C
   29.983 61.871,30.281 61.886,32.020 61.690 C
   32.972 61.583,34.400 61.427,35.194 61.343 C
   35.988 61.259,36.866 61.072,37.144 60.928 C
   37.422 60.784,37.900 60.667,38.205 60.667 C
   38.510 60.667,38.907 60.564,39.088 60.438 C
   39.269 60.312,39.679 60.153,40.000 60.083 C
   40.321 60.014,40.731 59.854,40.912 59.729 C
   41.093 59.603,41.372 59.500,41.533 59.500 C
   41.694 59.500,42.311 59.237,42.905 58.917 C
   43.500 58.596,44.061 58.333,44.152 58.333 C
   44.311 58.333,45.360 57.692,45.785 57.336 C
   45.895 57.243,46.027 57.167,46.078 57.167 C
   46.448 57.167,49.639 54.642,51.111 53.186 C
   54.169 50.160,54.358 49.676,52.401 49.877 C
   51.834 49.935,51.862 49.948,52.625 49.972 C
   53.106 49.987,53.500 50.069,53.500 50.154 C
   53.500 50.496,50.811 53.292,49.330 54.489 C
   48.828 54.895,48.342 55.308,48.250 55.407 C
   48.081 55.588,45.880 57.116,45.364 57.410 C
   45.210 57.498,44.880 57.704,44.632 57.868 C
   44.383 58.032,44.025 58.167,43.835 58.167 C
   43.645 58.167,43.446 58.279,43.394 58.417 C
   43.341 58.554,43.174 58.667,43.024 58.668 C
   42.873 58.668,42.525 58.818,42.250 59.000 C
   41.975 59.182,41.637 59.332,41.500 59.333 C
   41.362 59.334,41.049 59.440,40.803 59.569 C
   40.557 59.697,40.107 59.855,39.803 59.920 C
   39.499 59.985,39.063 60.134,38.833 60.251 C
   38.604 60.368,38.042 60.541,37.583 60.636 C
   37.125 60.731,36.412 60.885,36.000 60.978 C
   33.852 61.460,29.483 61.762,28.304 61.509 C
   27.834 61.409,26.768 61.283,25.933 61.231 C
   25.099 61.179,23.985 60.993,23.458 60.818 C
   22.930 60.643,22.293 60.498,22.041 60.495 C
   21.789 60.492,21.445 60.385,21.276 60.257 C
   21.107 60.129,20.657 59.972,20.276 59.908 C
   19.895 59.844,19.435 59.688,19.255 59.562 C
   19.074 59.436,18.786 59.333,18.614 59.333 C
   18.443 59.333,18.112 59.183,17.879 59.000 C
   17.646 58.817,17.324 58.667,17.165 58.667 C
   17.005 58.667,16.781 58.554,16.667 58.417 C
   16.553 58.279,16.366 58.167,16.252 58.167 C
   16.138 58.167,15.854 58.017,15.621 57.833 C
   15.388 57.650,15.115 57.500,15.014 57.500 C
   14.913 57.500,14.437 57.225,13.957 56.888 C
   13.476 56.552,12.858 56.121,12.583 55.930 C
   12.308 55.739,12.046 55.545,12.000 55.499 C
   11.954 55.453,11.617 55.192,11.250 54.921 C
   10.356 54.258,7.333 51.244,7.333 51.015 C
   7.333 50.915,7.260 50.833,7.171 50.833 C
   7.082 50.833,6.902 50.627,6.771 50.375 C
   6.640 50.123,6.357 49.722,6.142 49.485 C
   5.627 48.918,5.000 48.044,5.000 47.895 C
   5.000 47.830,4.898 47.658,4.773 47.513 C
   4.649 47.368,4.324 46.837,4.051 46.333 C
   3.277 44.901,3.313 44.976,2.286 42.667 C
   2.143 42.346,1.980 41.808,1.924 41.470 C
   1.868 41.133,1.746 40.832,1.652 40.801 C
   1.558 40.769,1.424 40.319,1.354 39.799 C
   1.283 39.280,1.147 38.756,1.052 38.636 C
   0.957 38.515,0.833 37.817,0.776 37.083 C
   0.628 35.164,-0.000 33.398,0.000 34.903 M25.134 36.264 C
   25.318 36.617,25.833 37.119,25.833 36.946 C
   25.833 36.893,25.635 36.640,25.393 36.384 C
   25.078 36.049,25.005 36.015,25.134 36.264 M59.008 37.819 C
   58.879 38.060,58.876 38.167,58.999 38.167 C
   59.201 38.167,59.421 37.699,59.284 37.562 C
   59.234 37.512,59.110 37.628,59.008 37.819 M27.826 39.044 C
   28.235 39.478,28.625 39.833,28.693 39.833 C
   28.838 39.833,28.290 39.260,27.581 38.669 C
   27.307 38.442,27.418 38.611,27.826 39.044 M58.712 39.333 C
   58.712 39.746,58.745 39.915,58.784 39.708 C
   58.824 39.502,58.824 39.165,58.784 38.958 C
   58.745 38.752,58.712 38.921,58.712 39.333 M29.750 40.744 C
   30.117 41.075,30.567 41.426,30.750 41.522 C
   31.063 41.688,30.771 41.397,29.882 40.656 C
   29.219 40.104,29.117 40.173,29.750 40.744 M57.863 41.848 C
   57.742 42.041,57.676 42.231,57.715 42.270 C
   57.830 42.386,58.203 41.860,58.140 41.671 C
   58.108 41.575,57.984 41.655,57.863 41.848 M33.661 43.577 C
   33.772 43.711,34.025 43.870,34.223 43.930 C
   34.421 43.990,34.733 44.162,34.917 44.311 C
   35.245 44.579,35.245 44.578,34.946 44.223 C
   34.779 44.025,34.460 43.818,34.238 43.762 C
   34.015 43.706,33.833 43.587,33.833 43.497 C
   33.833 43.407,33.749 43.333,33.646 43.333 C
   33.515 43.333,33.519 43.406,33.661 43.577 M56.846 44.470 C
   56.839 44.591,56.721 44.803,56.583 44.940 C
   56.446 45.078,56.339 45.316,56.346 45.470 C
   56.357 45.699,56.376 45.704,56.452 45.500 C
   56.502 45.362,56.654 45.123,56.789 44.968 C
   56.924 44.813,56.995 44.588,56.947 44.468 C
   56.876 44.290,56.857 44.290,56.846 44.470 M37.432 45.691 C
   37.480 45.769,37.694 45.833,37.906 45.833 C
   38.119 45.833,38.386 45.946,38.500 46.083 C
   38.614 46.221,38.830 46.327,38.979 46.320 C
   39.206 46.309,39.209 46.292,39.000 46.215 C
   38.862 46.165,38.637 46.030,38.500 45.917 C
   38.206 45.674,37.303 45.483,37.432 45.691 M39.810 46.672 C
   40.027 46.852,40.364 46.993,40.560 46.985 C
   40.814 46.974,40.761 46.925,40.375 46.814 C
   40.077 46.728,39.833 46.584,39.833 46.495 C
   39.833 46.406,39.740 46.336,39.625 46.339 C
   39.510 46.342,39.594 46.492,39.810 46.672 M41.333 47.250 C
   41.447 47.387,41.700 47.490,41.895 47.477 C
   42.235 47.456,42.234 47.452,41.875 47.382 C
   41.669 47.343,41.500 47.240,41.500 47.155 C
   41.500 47.070,41.416 47.000,41.313 47.000 C
   41.184 47.000,41.190 47.077,41.333 47.250 M43.794 47.285 C
   44.001 47.324,44.301 47.323,44.460 47.281 C
   44.620 47.239,44.450 47.207,44.083 47.209 C
   43.717 47.211,43.586 47.245,43.794 47.285 M42.576 47.622 C
   42.756 47.667,43.072 47.816,43.279 47.951 C
   43.487 48.087,43.752 48.159,43.870 48.112 C
   44.012 48.055,43.993 48.022,43.812 48.013 C
   43.663 48.006,43.447 47.887,43.333 47.750 C
   43.219 47.612,42.929 47.509,42.688 47.519 C
   42.348 47.534,42.323 47.557,42.576 47.622 M44.833 48.259 C
   44.833 48.459,46.028 48.833,46.667 48.833 C
   47.040 48.833,47.525 48.951,47.744 49.095 C
   47.981 49.250,48.369 49.328,48.697 49.288 L 49.250 49.220 48.643 49.193 C
   48.276 49.177,47.998 49.068,47.940 48.917 C
   47.870 48.735,47.582 48.666,46.880 48.666 C
   46.350 48.665,45.702 48.553,45.439 48.416 C
   44.916 48.143,44.833 48.121,44.833 48.259 M54.415 48.542 C
   54.291 48.823,54.063 48.942,53.500 49.017 L 52.750 49.117 53.446 49.142 C
   54.163 49.167,54.667 48.864,54.667 48.405 C
   54.667 48.073,54.605 48.106,54.415 48.542 " stroke="none" fill="#949494" fill-rule="evenodd"></path><path id="path4" d="M26.569 2.415 C
   25.186 2.536,23.702 2.755,23.271 2.901 C
   22.841 3.047,22.322 3.169,22.119 3.172 C
   21.916 3.175,21.450 3.323,21.083 3.500 C
   20.717 3.677,20.267 3.825,20.083 3.828 C
   19.900 3.831,19.225 4.095,18.583 4.414 C
   17.942 4.733,17.323 4.996,17.208 4.997 C
   16.968 5.000,16.921 5.931,17.154 6.075 C
   17.238 6.128,17.357 6.395,17.417 6.669 C
   17.477 6.943,17.597 7.167,17.684 7.167 C
   17.771 7.167,17.913 7.354,18.000 7.583 C
   18.087 7.812,18.228 8.000,18.313 8.000 C
   18.398 8.000,18.546 8.225,18.642 8.500 C
   18.738 8.775,18.897 9.000,18.995 9.000 C
   19.094 9.000,19.251 9.169,19.345 9.375 C
   19.613 9.967,19.923 10.472,20.223 10.804 C
   20.375 10.972,20.500 11.159,20.500 11.220 C
   20.500 11.281,20.713 11.537,20.973 11.790 C
   21.234 12.043,21.515 12.375,21.598 12.528 C
   22.110 13.467,27.699 19.113,29.250 20.258 C
   29.617 20.529,29.942 20.806,29.972 20.875 C
   30.003 20.944,30.106 21.000,30.203 21.000 C
   30.299 21.000,30.611 21.244,30.897 21.542 C
   31.183 21.841,31.623 22.177,31.875 22.289 C
   32.127 22.401,32.333 22.569,32.333 22.663 C
   32.333 22.757,32.458 22.833,32.611 22.833 C
   32.764 22.833,33.007 22.942,33.153 23.076 C
   33.298 23.209,33.585 23.471,33.792 23.659 C
   33.998 23.847,34.268 24.000,34.391 24.000 C
   34.515 24.000,34.789 24.150,35.000 24.333 C
   35.211 24.517,35.523 24.667,35.692 24.667 C
   35.861 24.667,36.000 24.741,36.000 24.831 C
   36.000 24.922,36.262 25.105,36.583 25.239 C
   36.904 25.373,37.167 25.562,37.167 25.658 C
   37.167 25.755,37.349 25.833,37.571 25.833 C
   37.794 25.833,38.085 25.942,38.218 26.075 C
   38.351 26.208,38.662 26.368,38.910 26.430 C
   39.158 26.492,39.396 26.637,39.440 26.752 C
   39.485 26.867,39.806 27.006,40.155 27.062 C
   40.504 27.118,40.884 27.277,40.999 27.415 C
   41.114 27.553,41.393 27.667,41.620 27.667 C
   41.846 27.667,42.231 27.771,42.474 27.898 C
   42.718 28.025,43.140 28.219,43.413 28.328 C
   43.853 28.505,43.876 28.546,43.615 28.692 C
   43.241 28.902,42.390 28.721,42.269 28.406 C
   42.218 28.273,41.959 28.167,41.684 28.167 C
   41.412 28.167,41.082 28.058,40.949 27.925 C
   40.816 27.792,40.492 27.632,40.228 27.569 C
   39.965 27.507,39.637 27.361,39.500 27.246 C
   39.362 27.131,39.013 26.987,38.722 26.925 C
   38.432 26.863,38.153 26.705,38.102 26.573 C
   38.052 26.441,37.863 26.333,37.684 26.333 C
   37.504 26.333,37.245 26.221,37.107 26.083 C
   36.970 25.946,36.739 25.833,36.594 25.833 C
   36.449 25.833,36.202 25.683,36.045 25.500 C
   35.888 25.317,35.624 25.167,35.458 25.167 C
   35.292 25.167,35.115 25.059,35.064 24.927 C
   35.014 24.795,34.748 24.638,34.475 24.578 C
   34.201 24.518,33.937 24.363,33.888 24.234 C
   33.838 24.105,33.734 24.000,33.656 24.000 C
   33.434 24.000,32.265 23.168,32.117 22.904 C
   32.043 22.774,31.837 22.667,31.658 22.667 C
   31.480 22.667,31.333 22.562,31.333 22.435 C
   31.333 22.307,31.165 22.159,30.958 22.106 C
   30.752 22.053,30.288 21.744,29.928 21.421 C
   29.568 21.098,29.211 20.833,29.136 20.833 C
   29.061 20.833,29.000 20.721,29.000 20.583 C
   29.000 20.446,28.913 20.333,28.806 20.333 C
   28.699 20.333,28.493 20.223,28.347 20.088 C
   28.202 19.953,27.596 19.411,27.000 18.883 C
   26.404 18.356,25.481 17.473,24.949 16.920 C
   22.758 14.649,22.333 14.193,22.333 14.111 C
   22.333 14.064,21.958 13.646,21.500 13.183 C
   21.042 12.719,20.667 12.270,20.667 12.184 C
   20.667 12.018,20.110 11.312,19.625 10.863 C
   19.465 10.714,19.333 10.459,19.333 10.296 C
   19.333 10.133,19.271 10.000,19.195 10.000 C
   19.119 10.000,18.803 9.606,18.493 9.125 C
   18.183 8.644,17.838 8.156,17.726 8.041 C
   17.614 7.926,17.474 7.682,17.416 7.499 C
   17.358 7.316,17.241 7.167,17.155 7.167 C
   17.070 7.167,17.000 7.033,17.000 6.870 C
   17.000 6.707,16.869 6.456,16.708 6.312 C
   16.548 6.169,16.339 5.918,16.245 5.755 C
   15.983 5.303,14.630 5.952,14.331 6.672 C
   14.174 7.051,14.079 7.131,13.973 6.970 C
   13.804 6.712,13.984 8.123,14.168 8.500 C
   14.235 8.637,14.343 9.575,14.407 10.583 C
   14.471 11.592,14.619 12.567,14.736 12.750 C
   14.854 12.933,14.989 13.574,15.036 14.174 C
   15.084 14.774,15.245 15.502,15.395 15.791 C
   15.544 16.080,15.667 16.644,15.667 17.043 C
   15.667 17.443,15.777 17.915,15.911 18.093 C
   16.046 18.271,16.158 18.663,16.161 18.965 C
   16.164 19.267,16.317 19.807,16.500 20.167 C
   16.683 20.526,16.833 20.980,16.833 21.176 C
   16.833 21.372,16.944 21.731,17.079 21.974 C
   17.213 22.218,17.404 22.735,17.502 23.125 C
   17.599 23.515,17.752 23.833,17.840 23.833 C
   17.928 23.833,18.000 23.991,18.000 24.183 C
   18.000 24.375,18.107 24.731,18.239 24.974 C
   18.635 25.709,19.333 27.302,19.333 27.472 C
   19.333 27.559,19.446 27.674,19.583 27.727 C
   19.721 27.780,19.833 28.015,19.833 28.250 C
   19.833 28.485,19.941 28.719,20.073 28.769 C
   20.205 28.820,20.361 29.079,20.420 29.346 C
   20.478 29.613,20.616 29.888,20.725 29.957 C
   20.834 30.027,21.017 30.271,21.130 30.500 C
   21.244 30.729,21.467 31.109,21.627 31.343 C
   21.786 31.578,22.159 32.141,22.456 32.593 C
   22.752 33.046,23.158 33.604,23.358 33.833 C
   23.559 34.063,23.867 34.494,24.044 34.792 C
   24.221 35.090,24.434 35.333,24.516 35.333 C
   24.599 35.333,24.667 35.418,24.667 35.522 C
   24.667 35.626,24.929 35.958,25.250 36.261 C
   25.571 36.563,25.833 36.848,25.833 36.892 C
   25.833 37.201,27.228 38.340,27.362 38.141 C
   27.480 37.966,27.503 37.977,27.465 38.191 C
   27.418 38.459,28.795 39.899,29.753 40.583 C
   30.010 40.767,30.489 41.159,30.818 41.454 C
   31.147 41.750,31.513 41.994,31.630 41.996 C
   31.748 41.998,31.884 42.106,31.934 42.235 C
   31.983 42.364,32.244 42.560,32.512 42.672 C
   32.780 42.783,33.000 42.935,33.000 43.009 C
   33.000 43.083,33.165 43.197,33.367 43.261 C
   33.569 43.325,33.837 43.480,33.962 43.605 C
   34.088 43.731,34.286 43.833,34.403 43.833 C
   34.519 43.833,34.751 43.983,34.917 44.167 C
   35.083 44.350,35.342 44.500,35.492 44.500 C
   35.643 44.500,35.838 44.571,35.925 44.658 C
   36.219 44.952,37.682 45.667,37.988 45.667 C
   38.156 45.667,38.382 45.774,38.491 45.906 C
   38.600 46.038,38.924 46.197,39.210 46.260 C
   39.497 46.323,39.828 46.470,39.946 46.588 C
   40.064 46.706,40.462 46.854,40.830 46.916 C
   41.199 46.978,41.500 47.093,41.500 47.171 C
   41.500 47.249,41.856 47.364,42.292 47.427 C
   42.727 47.490,43.205 47.647,43.354 47.778 C
   43.503 47.908,43.916 48.056,44.271 48.106 C
   44.626 48.157,45.153 48.304,45.442 48.433 C
   45.731 48.561,46.389 48.667,46.905 48.667 C
   47.558 48.667,47.871 48.738,47.934 48.901 C
   47.993 49.055,48.433 49.176,49.220 49.253 C
   49.878 49.318,50.567 49.452,50.750 49.550 C
   51.142 49.759,53.673 49.979,53.559 49.793 C
   53.516 49.724,53.129 49.655,52.698 49.642 C
   52.206 49.626,52.102 49.595,52.417 49.559 C
   53.836 49.395,54.626 49.141,54.816 48.785 C
   55.162 48.139,55.424 47.709,55.615 47.476 C
   55.808 47.242,57.356 44.166,57.964 42.810 C
   58.155 42.384,58.362 41.765,58.425 41.433 C
   58.487 41.101,58.600 40.791,58.676 40.744 C
   58.752 40.697,58.866 40.304,58.929 39.871 C
   58.992 39.438,59.153 38.867,59.287 38.603 C
   59.617 37.954,59.867 32.944,59.602 32.294 L 59.417 31.838 56.876 31.781 C
   55.478 31.749,54.284 31.673,54.223 31.612 C
   53.945 31.334,54.534 31.248,56.889 31.223 L 59.417 31.197 59.417 28.640 C
   59.417 26.587,59.359 25.943,59.125 25.372 C
   58.965 24.980,58.833 24.457,58.833 24.208 C
   58.833 23.766,58.367 22.383,57.901 21.445 C
   57.772 21.185,57.667 20.847,57.667 20.692 C
   57.667 20.538,57.517 20.132,57.333 19.790 C
   57.150 19.448,57.000 19.063,57.000 18.935 C
   57.000 18.807,56.897 18.663,56.771 18.615 C
   56.646 18.566,56.495 18.336,56.436 18.103 C
   56.378 17.869,56.106 17.346,55.832 16.940 C
   55.558 16.534,55.333 16.130,55.333 16.043 C
   55.333 15.956,55.183 15.749,55.000 15.583 C
   54.817 15.417,54.667 15.216,54.667 15.136 C
   54.667 14.484,50.814 10.375,48.467 8.524 C
   46.187 6.725,46.301 6.801,44.417 5.833 C
   43.867 5.551,43.304 5.254,43.167 5.173 C
   42.603 4.842,41.478 4.333,41.312 4.333 C
   41.214 4.333,40.897 4.224,40.609 4.091 C
   39.646 3.646,38.593 3.307,37.675 3.145 C
   37.175 3.057,36.671 2.906,36.555 2.810 C
   36.366 2.653,35.126 2.544,30.500 2.277 C
   29.721 2.232,27.952 2.294,26.569 2.415 M16.167 6.265 C
   16.167 6.492,16.279 6.720,16.417 6.773 C
   16.554 6.826,16.667 7.046,16.667 7.262 C
   16.667 7.484,16.811 7.732,17.000 7.833 C
   17.183 7.931,17.333 8.124,17.333 8.261 C
   17.333 8.398,17.441 8.552,17.573 8.602 C
   17.705 8.653,17.862 8.918,17.922 9.192 C
   17.982 9.465,18.137 9.730,18.266 9.779 C
   18.395 9.829,18.500 9.927,18.500 9.997 C
   18.500 10.067,18.762 10.411,19.083 10.762 C
   19.404 11.112,19.667 11.531,19.667 11.693 C
   19.667 11.856,19.817 12.069,20.000 12.167 C
   20.183 12.265,20.333 12.455,20.333 12.589 C
   20.333 12.723,20.437 12.833,20.564 12.833 C
   20.691 12.833,20.834 12.983,20.882 13.167 C
   20.930 13.350,21.088 13.500,21.234 13.500 C
   21.380 13.500,21.500 13.580,21.500 13.678 C
   21.500 13.933,22.388 15.013,23.875 16.564 C
   24.585 17.305,25.167 17.969,25.167 18.039 C
   25.167 18.109,25.248 18.167,25.346 18.167 C
   25.445 18.167,25.804 18.448,26.144 18.792 C
   27.068 19.726,28.173 20.667,28.349 20.667 C
   28.435 20.667,28.870 21.034,29.316 21.483 C
   29.762 21.931,30.279 22.347,30.465 22.406 C
   30.651 22.464,30.845 22.622,30.896 22.756 C
   30.947 22.890,31.138 23.000,31.320 23.000 C
   31.501 23.000,31.710 23.094,31.783 23.208 C
   31.990 23.532,32.957 24.167,33.243 24.167 C
   33.384 24.167,33.500 24.281,33.500 24.421 C
   33.500 24.561,33.672 24.740,33.882 24.820 C
   34.092 24.900,34.364 25.086,34.486 25.233 C
   34.608 25.380,34.840 25.500,35.003 25.500 C
   35.165 25.500,35.341 25.613,35.394 25.750 C
   35.449 25.893,35.697 26.000,35.977 26.000 C
   36.248 26.000,36.505 26.107,36.557 26.241 C
   36.608 26.373,36.916 26.532,37.241 26.593 C
   37.567 26.654,37.833 26.766,37.833 26.843 C
   37.833 27.037,39.456 27.833,39.852 27.833 C
   40.031 27.833,40.220 27.944,40.271 28.079 C
   40.323 28.214,40.638 28.375,40.970 28.438 C
   41.303 28.500,41.676 28.652,41.799 28.776 C
   41.923 28.899,42.196 29.000,42.406 29.000 C
   42.616 29.000,42.979 29.150,43.212 29.333 C
   43.464 29.531,43.888 29.667,44.258 29.667 C
   44.636 29.667,44.956 29.771,45.075 29.934 C
   45.188 30.088,45.618 30.239,46.093 30.291 C
   46.546 30.340,47.065 30.483,47.245 30.607 C
   47.426 30.731,47.939 30.833,48.386 30.833 C
   48.945 30.833,49.329 30.937,49.621 31.167 C
   49.962 31.435,50.297 31.500,51.335 31.500 C
   52.243 31.500,52.684 31.570,52.821 31.735 C
   52.969 31.914,53.628 31.984,55.566 32.027 C
   57.362 32.067,58.194 32.148,58.381 32.303 C
   58.575 32.465,58.732 32.477,58.973 32.348 C
   59.480 32.076,59.535 32.463,59.404 35.389 C
   59.315 37.374,59.230 38.050,59.059 38.116 C
   58.909 38.173,58.833 38.494,58.833 39.075 C
   58.833 39.737,58.753 40.021,58.500 40.250 C
   58.267 40.461,58.167 40.763,58.167 41.255 C
   58.167 41.649,58.066 42.043,57.937 42.149 C
   57.811 42.254,57.662 42.630,57.605 42.985 C
   57.548 43.340,57.389 43.674,57.251 43.727 C
   57.107 43.782,57.000 44.030,57.000 44.308 C
   57.000 44.574,56.892 44.882,56.761 44.991 C
   56.629 45.100,56.475 45.403,56.417 45.664 C
   56.360 45.925,56.205 46.180,56.073 46.231 C
   55.941 46.281,55.833 46.426,55.833 46.552 C
   55.833 46.678,55.683 46.917,55.500 47.083 C
   55.317 47.249,55.167 47.488,55.167 47.614 C
   55.167 47.741,55.054 47.887,54.917 47.940 C
   54.779 47.992,54.667 48.172,54.667 48.339 C
   54.667 49.173,52.460 49.468,51.657 48.741 C
   51.463 48.566,50.955 48.500,49.798 48.500 C
   48.346 48.500,48.198 48.471,48.118 48.167 C
   48.043 47.878,47.891 47.833,46.992 47.833 C
   46.230 47.833,45.901 47.762,45.757 47.565 C
   45.611 47.366,45.298 47.300,44.540 47.311 C
   43.663 47.323,43.505 47.279,43.415 46.996 C
   43.333 46.735,43.162 46.667,42.590 46.667 C
   42.103 46.667,41.838 46.586,41.773 46.417 C
   41.716 46.268,41.466 46.167,41.157 46.167 C
   40.870 46.167,40.445 46.017,40.212 45.833 C
   39.979 45.650,39.579 45.500,39.322 45.500 C
   39.002 45.500,38.823 45.395,38.750 45.167 C
   38.671 44.919,38.498 44.833,38.072 44.833 C
   37.683 44.833,37.500 44.756,37.500 44.591 C
   37.500 44.446,37.266 44.305,36.917 44.239 C
   36.596 44.179,36.333 44.063,36.333 43.981 C
   36.333 43.900,36.213 43.833,36.065 43.833 C
   35.747 43.833,34.167 42.839,34.167 42.639 C
   34.167 42.562,33.979 42.500,33.750 42.500 C
   33.499 42.500,33.333 42.405,33.333 42.261 C
   33.333 42.130,33.190 41.977,33.015 41.922 C
   32.841 41.866,32.655 41.711,32.604 41.577 C
   32.553 41.443,32.407 41.333,32.281 41.333 C
   32.154 41.333,31.878 41.183,31.667 41.000 C
   31.455 40.817,31.181 40.667,31.058 40.667 C
   30.934 40.667,30.833 40.554,30.833 40.417 C
   30.833 40.279,30.718 40.167,30.578 40.167 C
   30.438 40.167,30.277 40.035,30.220 39.875 C
   30.120 39.592,29.660 39.139,28.865 38.540 C
   28.653 38.381,28.321 38.081,28.126 37.875 C
   27.932 37.669,27.674 37.500,27.553 37.500 C
   27.432 37.500,27.333 37.409,27.333 37.298 C
   27.333 37.186,27.185 36.942,27.005 36.756 C
   25.615 35.322,25.333 35.015,25.333 34.933 C
   25.333 34.852,24.911 34.371,23.916 33.319 C
   23.779 33.174,23.667 32.933,23.667 32.784 C
   23.667 32.634,23.517 32.431,23.333 32.333 C
   23.150 32.235,23.000 32.008,23.000 31.827 C
   23.000 31.647,22.893 31.500,22.763 31.500 C
   22.633 31.500,22.478 31.281,22.420 31.014 C
   22.361 30.747,22.205 30.486,22.073 30.436 C
   21.941 30.385,21.833 30.208,21.833 30.042 C
   21.833 29.876,21.683 29.612,21.500 29.455 C
   21.317 29.298,21.167 29.065,21.167 28.936 C
   21.167 28.808,21.054 28.659,20.917 28.606 C
   20.779 28.554,20.666 28.377,20.666 28.214 C
   20.665 28.050,20.515 27.692,20.333 27.417 C
   20.151 27.142,20.002 26.746,20.001 26.536 C
   20.000 26.327,19.887 26.113,19.750 26.060 C
   19.608 26.006,19.500 25.758,19.500 25.488 C
   19.500 25.182,19.381 24.948,19.167 24.833 C
   18.983 24.735,18.833 24.507,18.833 24.327 C
   18.833 24.146,18.683 23.719,18.500 23.377 C
   18.317 23.035,18.167 22.545,18.167 22.289 C
   18.167 22.028,18.056 21.781,17.917 21.727 C
   17.747 21.662,17.667 21.396,17.667 20.899 C
   17.667 20.385,17.566 20.066,17.327 19.827 C
   17.141 19.641,17.030 19.420,17.081 19.337 C
   17.243 19.076,16.967 17.833,16.748 17.833 C
   16.605 17.833,16.516 17.455,16.471 16.651 C
   16.420 15.757,16.335 15.431,16.119 15.310 C
   15.895 15.185,15.833 14.917,15.833 14.074 C
   15.833 13.307,15.736 12.820,15.494 12.374 C
   15.204 11.841,15.170 11.574,15.263 10.544 C
   15.389 9.153,15.247 7.937,14.920 7.610 C
   14.401 7.091,14.541 6.221,15.165 6.084 C
   15.439 6.024,15.706 5.904,15.760 5.818 C
   15.921 5.557,16.167 5.827,16.167 6.265 M14.873 11.298 C
   14.710 12.246,14.709 12.243,14.705 10.667 C
   14.702 9.303,14.724 9.171,14.869 9.714 C
   14.979 10.132,14.981 10.668,14.873 11.298 M15.451 14.708 C
   15.411 14.915,15.379 14.746,15.379 14.333 C
   15.379 13.921,15.411 13.752,15.451 13.958 C
   15.491 14.165,15.491 14.502,15.451 14.708 M16.164 17.115 C
   16.166 17.316,16.092 17.527,16.000 17.583 C
   15.899 17.646,15.834 17.405,15.836 16.968 C
   15.838 16.443,15.882 16.317,16.000 16.500 C
   16.089 16.637,16.163 16.914,16.164 17.115 M16.615 19.458 C
   16.573 19.619,16.539 19.487,16.539 19.167 C
   16.539 18.846,16.573 18.715,16.615 18.875 C
   16.657 19.035,16.657 19.298,16.615 19.458 M17.333 21.083 C
   17.333 21.313,17.258 21.500,17.167 21.500 C
   17.075 21.500,17.000 21.313,17.000 21.083 C
   17.000 20.854,17.075 20.667,17.167 20.667 C
   17.258 20.667,17.333 20.854,17.333 21.083 M17.782 23.125 C
   17.740 23.285,17.705 23.154,17.705 22.833 C
   17.705 22.512,17.740 22.381,17.782 22.542 C
   17.823 22.702,17.823 22.965,17.782 23.125 M18.500 24.367 C
   18.500 24.636,18.451 24.684,18.315 24.548 C
   18.213 24.446,18.179 24.281,18.241 24.181 C
   18.413 23.902,18.500 23.964,18.500 24.367 M19.048 25.833 C
   18.928 26.292,18.833 26.251,18.833 25.740 C
   18.833 25.506,18.901 25.356,18.984 25.407 C
   19.067 25.458,19.096 25.650,19.048 25.833 M0.208 27.593 C
   -0.033 27.855,-0.066 28.167,0.147 28.167 C
   0.275 28.167,0.546 27.485,0.452 27.399 C
   0.432 27.381,0.323 27.469,0.208 27.593 M45.463 29.135 C
   45.516 29.298,45.352 29.341,44.822 29.302 C
   44.421 29.273,44.078 29.154,44.037 29.031 C
   43.984 28.869,44.148 28.826,44.678 28.865 C
   45.079 28.894,45.422 29.012,45.463 29.135 M47.711 29.611 C
   48.023 29.803,47.579 30.000,46.833 30.000 C
   46.019 30.000,45.715 29.827,45.927 29.484 C
   46.032 29.313,47.384 29.410,47.711 29.611 M50.202 30.302 C
   50.355 30.455,50.125 30.500,49.200 30.500 C
   48.205 30.500,48.000 30.454,48.000 30.230 C
   48.000 29.904,49.866 29.966,50.202 30.302 M53.458 30.772 C
   54.246 30.983,53.779 31.167,52.458 31.164 C
   50.962 31.162,50.286 31.045,50.428 30.814 C
   50.531 30.649,52.877 30.616,53.458 30.772 M-0.000 35.595 C
   -0.000 35.739,0.112 35.970,0.250 36.107 C
   0.529 36.387,0.604 36.131,0.399 35.597 C
   0.268 35.257,0.000 35.256,-0.000 35.595 M0.539 37.000 C
   0.539 37.321,0.573 37.452,0.615 37.292 C
   0.657 37.131,0.657 36.869,0.615 36.708 C
   0.573 36.548,0.539 36.679,0.539 37.000 M41.167 46.603 C
   41.167 46.638,40.975 46.667,40.740 46.667 C
   40.506 46.667,40.356 46.599,40.407 46.516 C
   40.492 46.377,41.167 46.455,41.167 46.603 M42.667 47.167 C
   42.667 47.258,42.559 47.333,42.427 47.333 C
   42.294 47.333,42.140 47.258,42.083 47.167 C
   42.027 47.075,42.135 47.000,42.323 47.000 C
   42.512 47.000,42.667 47.075,42.667 47.167 M44.711 47.784 C
   44.506 47.824,44.131 47.825,43.877 47.786 C
   43.624 47.748,43.792 47.716,44.250 47.714 C
   44.708 47.713,44.916 47.744,44.711 47.784 M47.000 48.333 C
   47.183 48.451,47.057 48.496,46.532 48.497 C
   46.095 48.499,45.854 48.435,45.917 48.333 C
   46.047 48.122,46.673 48.122,47.000 48.333 M50.083 48.988 C
   49.900 49.061,49.413 49.065,49.000 48.998 C
   48.276 48.879,48.288 48.875,49.333 48.865 C
   50.032 48.858,50.298 48.902,50.083 48.988 " stroke="none" fill="#8C
  94be" fill-rule="evenodd"></path></g></svg>
`]
