export const posticon = ['160 160', `
<?xml version="1.0" encoding="iso-8859-1"?>
<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
<g>
	<g>
		<path d="M90,276.001c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10s10-4.48,10-10C100,280.48,95.52,276.001,90,276.001z"/>
	</g>
</g>
<g>
	<g>
		<path d="M508.484,118.389l-55.14-46.96c-0.367-0.313-0.757-0.599-1.166-0.855c-4.763-2.992-10.268-4.573-15.918-4.573H306v-26
			c0-22.056-17.944-40-40-40c-22.056,0-40,17.944-40,40v26h-10c-16.542,0-30,13.458-30,30v60c0,16.542,13.458,30,30,30h10v40H75.74
			c-5.65,0-11.155,1.581-15.917,4.571c-0.409,0.257-0.799,0.542-1.167,0.855l-55.14,46.96C1.285,280.288,0,283.069,0,286.001
			c0,2.931,1.285,5.713,3.516,7.613l55.14,46.96c0.368,0.313,0.757,0.599,1.167,0.855c4.762,2.991,10.267,4.572,15.917,4.572H226
			v106h-30c-16.542,0-30,13.458-30,30v20c0,5.522,4.477,10,10,10h180c5.522,0,10-4.478,10-10v-20c0-16.542-13.458-30-30-30h-30
			V344.28c11.639-4.128,20-15.243,20-28.28v-60c0-13.036-8.361-24.152-20-28.28v-41.72h130.26c5.65,0,11.155-1.581,15.918-4.571
			c0.409-0.257,0.799-0.542,1.166-0.855l55.14-46.96c2.231-1.9,3.517-4.683,3.517-7.613
			C512.001,123.072,510.716,120.289,508.484,118.389z M246,40.001c0-11.028,8.972-20,20-20s20,8.972,20,20v26h-40V40.001z
			 M336,472.001c5.514,0,10,4.486,10,10v10H186v-10c0-5.514,4.486-10,10-10H336z M246,452.001v-106h40v106H246z M306,316.001
			c0,5.514-4.486,10-10,10H75.74c-1.681,0-3.316-0.415-4.766-1.206l-45.551-38.794l45.552-38.794
			c1.45-0.791,3.085-1.206,4.766-1.206H296c5.514,0,10,4.486,10,10V316.001z M246,226.001v-40h40v40H246z M441.026,164.795
			c-1.45,0.791-3.086,1.206-4.767,1.206H216c-5.514,0-10-4.486-10-10v-60c0-5.514,4.486-10,10-10h220.26
			c1.681,0,3.316,0.415,4.767,1.206l45.55,38.794L441.026,164.795z"/>
	</g>
</g>
<g>
	<g>
		<path d="M382,116.001H236c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h146c5.522,0,10-4.478,10-10
			C392,120.478,387.522,116.001,382,116.001z"/>
	</g>
</g>
<g>
	<g>
		<path d="M422,116.001c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10S427.52,116.001,422,116.001z"/>
	</g>
</g>
<g>
	<g>
		<path d="M276,276.001H130c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h146c5.522,0,10-4.478,10-10
			C286,280.478,281.522,276.001,276,276.001z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

`]
